.relative_container {
  position: relative;
}

.property_payment_width {
  width: 100%;
}

.input_password {
  width: 255px;
  height: 38px;
}

.text_grey_color {
  color: #808080;
}

.spin_payment_logo {
  position: relative;
}

.termAndContionTextAnd {
  margin-left: 5px;
  margin-right: 5px;
  cursor: initial;
}

.unresolvedTicketScrollContainer {
  overflow-y: auto;
  max-height: 63px;
}

.message_logo_container {
  position: relative;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-text-icon>.anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #212121;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #212121;
}

.lazyText {
  color: transparent;
}

.conversation-photo-history {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.new_conversation_image {
  cursor: pointer;
}

.phone_label_container {
  display: flex;
  align-items: center;
}

.civil_id_container {
  cursor: pointer;
}

.lease_file {
  cursor: pointer;
  text-decoration: underline;
  margin: 0px 24px;
}

.date_margin {
  margin: 0px 13px;
}

.disabled_month_circle {
  cursor: not-allowed;
  opacity: 0.5;
  filter: grayscale(1);
}

.pb-10 {
  padding-bottom: 10px;
}

.forget_back_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 15px;
}

.reset_psw_card {
  width: 445px;
  height: 423px;
  margin-left: auto;
  margin-right: auto;
  top: 10%;
  margin-top: calc((100vh - 521px)/3);
  z-index: 10;
}

.claims_date {
  font-size: 16px;
  color: #B3B3B3;
  font-family: "SF-Pro-Display-Medium";
  margin-top: -5px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-10 {
  margin-top: 10px;
}

.relative {
  position: relative;
}

.claims_check.ant-checkbox-wrapper {
  display: flex;
}

.claims_check.ant-checkbox-wrapper span {
  border-radius: 4px;
}

.claims_check .ant-checkbox {
  margin-top: 5px;
}

.claims_check .ant-checkbox,
.claims_check .ant-checkbox:hover,
.claims_check .ant-checkbox:focus {
  border-radius: 4px;
  height: 16px;
  width: 16px;
  border-color: #CCCCCC;
}

.claims_check .ant-checkbox-checked .ant-checkbox-inner {
  background: #27aad8;
  border-color: #27aad8;
}

.view_checked .ant-checkbox-checked .ant-checkbox-inner {
  background: #B3B3B3;
  border-color: #B3B3B3;
}

.claims_check .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
}

.claims_doc_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.claims_doc_container p {
  cursor: pointer;
}

.claims_btn_container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.claims_amount {
  height: 40px;
  width: 200px;
}

.claims_amount .ant-input {
  height: 32px;
}

.alertPopUpStyle .ant-modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.alert_doubleBtn_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}

.pl-38 {
  padding-left: 38px;
}

.pr-38 {
  padding-right: 38px;
}

.overflow-auto {
  overflow: auto;
}

.grey_color {
  color: #808080;
}

.civil_id_images {
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
}

.input_search_filter {
  display: flex;
  height: 40px;
  width: 100%;
}

.flex {
  display: flex;
}

.claim_modal .ant-modal-body {
  padding: 43px 0px 29px;
  max-height: 749px;
}

.claims_modal_content {
  max-height: 400px;
  overflow: auto;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-23 {
  margin-top: 23px;
}

.mb-0 {
  margin-bottom: 0;
}

.flex_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.relative {
  position: relative;
}

.spin_select {
  height: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.relative {
  position: relative;
}

.spin_select {
  height: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.claims_pagination .ant-pagination-item,
.claims_pagination .ant-pagination-item:focus,
.claims_pagination .ant-pagination-item:hover {
  border-radius: 4px;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  color: #212121;
}

.claims_pagination .ant-pagination-item a,
.claims_pagination .ant-pagination-item a:hover,
.claims_pagination .ant-pagination-item a:focus {
  color: #212121;
}

.claims_pagination .ant-pagination-item-active a,
.claims_pagination .ant-pagination-item-active a:hover,
.claims_pagination .ant-pagination-item-active a:focus {
  color: white;
}

.center_txt {
  text-align: center;
}

.claims_2doc_container {
  display: flex;
  flex-direction: column;
}

.claims_2doc_container p {
  margin-bottom: 7px;
}

.mb-10 {
  margin-bottom: 10px;
}

.claim_modal_spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 445px;
}

.suffix_date {
  height: 39px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  border: 1px solid #cccc;
  z-index: 0;
  border-bottom: 0;
}

.view_checked .ant-checkbox-checked::after,
.claims_check:hover .ant-checkbox input[type='checkbox'] {
  border-color: #B3B3B3;
  border-radius: 4px;
}

.claims_check .ant-checkbox-checked::after {
  border-color: #27aad8;
  border-radius: 4px;
}

.mt-35 {
  margin-top: 35px;
}

.ant-select-arrow .anticon {
  margin-top: 1px;
}

.ltr {
  direction: ltr;
}

.h-43 {
  height: 43px;
}

.mt-n5 {
  margin-top: -5px;
}

.tow_factor_switch.ant-switch {
  width: 52px;
  height: 24px;
  transform: scaleX(-1);
}

.tow_factor_switch.ant-switch::after {
  height: 20px;
  width: 20px;
  top: 0.5px;
}

.h-25 {
  height: 25px;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.beta_logo_image {
  height: 16px;
  width: 74px;
}

.tenantive_logo_image {
  width: 143px;
  height: 40px;
}

.navbar_logo_container {
  display: flex;
  flex-direction: column;
}

.civil_id_profile_name_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.civil_id_profile_dragger {
  width: 180px;
}

.create_password_card {
  width: 445px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc((100vh - 521px)/3);
  z-index: 10;
  padding-bottom: 25px;
}

@media (max-width: 567px) {
  .create_password_card {
    width: 360px;
  }

  .civil_id_profile_dragger {
    width: 100%;
  }

  .civil_id_profile_name_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .civil_id_profil_container {
    display: flex;
    justify-content: flex-end;
  }

  .tenantive_logo_image {
    width: 114px;
    height: 32px;
  }

  .beta_logo_image {
    height: 12px;
    width: 56px;
  }

  .pay_in_advance_body_modal {
    overflow: auto;
    height: 400px;
  }

  .receipt_modal_body_subcontainer {
    overflow: auto;
    height: 143px;
  }

  .reset_psw_card {
    width: 100%;
  }

  .claims_amount {
    width: 100%;
  }

  .claims_modal_content {
    max-height: 290px;
  }

  .claim_modal .ant-modal-body {
    max-height: 600px;
  }
}

.email_verif_logo {
  padding: 50px;
}

@media (max-width: 361px) {
  .claims_modal_content {
    max-height: 245px;
  }

  .claim_modal .ant-modal-body {
    max-height: 545px;
  }

  .claim_pl_resp {
    padding-left: 10px;
  }

  .claim_pr_resp {
    padding-right: 10px;
  }
}

.maintenance_modal_body_container {
  overflow: auto;
  height: 480px;
}

.mt-45 {
  margin-top: 45px;
}

.basicMaintenance_container {
  max-width: 642px;
  text-align: center;
  margin: 85px auto 0;
}

.basicMaintenance_container img {
  width: 80%;
}

.basicMaintenance_pic {
  margin-bottom: 48px;
}

.claims_uploaded_document {
  height: 82px;
  border-radius: 15px;
}

.reponsiveMessageList.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  top: 52px;
}

.mt-20 {
  margin-top: 20px;
}

.renewTextArea {
  max-width: 440px;
}

.maintenance_empty_image {
  object-fit: cover;
  width: 100%;
}

.pl-3 {
  padding-left: 3px;
}
.pr-3 {
  padding-right: 3px;
}

.underline {
  text-decoration: underline;
}

.tenant_number_error {
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Medium";
  display: flex;
  justify-content: center;
  padding: 0px 40px;
}

.phone_number_error {
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Medium";
  padding: 0px 40px;
}

.global-lang-icon {
  width: 19px;
  height: 19px;
}

.bt-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.lang_logo {
  background-color: #36d1e0;
}

.above-the-page {
  z-index: 1;
}

.forget-pasword {
  display: flex;
  justify-content: center;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.unresolved_maintenance{
  height: 140px !important;
  background-size: cover;
  width: 100% !important;
}
.edit_maintenance{
  height: 140px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-repeat: 'no-repeat';
  background-size: 'cover';
  background-position: 'center';
}
.wrap_img{
  height: 145px;
}