@font-face {
  font-family: "tajwal-medium";
  src: url(./fonts/Tajawal-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-black";
  src: url(./fonts/Tajawal-Black.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-bold";
  src: url(./fonts/Tajawal-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-extrabold";
  src: url(./fonts/Tajawal-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-extralight";
  src: url(./fonts/Tajawal-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-light";
  src: url(./fonts/Tajawal-Light.ttf) format("truetype");
}

@font-face {
  font-family: "tajwal-regular";
  src: url(./fonts/Tajawal-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Tajawal";
  src: url(./fonts/Tajawal-Medium.ttf) format("truetype");
}

@import "~antd/dist/antd.css";

.ar_bigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
}

.ar_bigContainerLoginPage {
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  direction: rtl;
}

.emptyHalf {
  background-color: #36d1e0;
}

.notEmptyHalf {
  background-color: white;
}

.fullHeight {
  height: 100%;
}

.loginBigTitle {
  font-family: "tajwal-bold";
  color: white;
  font-size: 28px;
  overflow: hidden;
  width: calc(100% - 50px);
  text-align: left;
  margin-left: 150px;
  margin-top: -50px;
  top: 50%;
  position: absolute;
  left: 0%;
}

.loginLogo {
  font-family: "tajwal-bold";
  color: white;
  font-size: 56px;
  border: 10px solid white;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 150px;
  padding-left: 20px;
  line-height: 1.2;
}

.inpuText {
  color: #212121;
}

.inputTextLabel {
  color: #666666;
}

.inputIcon {
  margin-top: 0px;
}

.errorInput {
  border-color: #c62828;
}

.validInput {
  border-color: #388e3c;
}

.errorLabel {
  color: #c62828;
  font-size: 12px;
  font-weight: 600;
}

.ant-menu-horizontal>.ant-menu-item-selected .tenantive-navbar-link {
  border-bottom-color: white;
  color: #36D1E0;
}

.tenantive-navbar-link {
  color: #b3b3b3;
}

.ant-menu-horizontal>.ant-menu-item-selected {
  border-bottom-color: white;
  color: #212121;
}

.ant-menu-horizontal>.ant-menu-item-selected .tenantive-navbar-link:hover {
  border-bottom-color: white;
  color: #212121;
}

.ant-menu-horizontal>.ant-menu-item-active:hover,
.ant-menu-horizontal>.ant-menu-item:hover .tenantive-navbar-link {
  border-bottom-color: white;
}

.ant-menu-horizontal>.ant-menu-submenu-selected {
  border-color: white;
  color: #b3b3b3;
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  border-color: white;
}

.ant-menu-item-disabled {
  cursor: text;
}

.ar_containerDashboard {
  width: 100%;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .tenantive-navbar-link:hover {
    color: #36D1E0;
  }
}

@media (min-width: 768px) {
  #ar_rentalsstatus-child {
    margin-right: 30px;
  }

  .ar_containerDashboard {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .ar_containerDashboard {
    max-width: 90%;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: #cccccc;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #333333;
  border-left: 0px;
  border-top: 0px;
}

.ar_pinOn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 26px;
  margin-left: -13px;
  margin-top: -10px;
}

.ar_accounting-btn-list-e-first {
  color: white;
  background-color: #27aad8;
  width: 160px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  border: 1px solid #27aad8;
  margin-left: -4px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ar_accounting-btn-list-e-last {
  color: white;
  background-color: #27aad8;
  width: 160px;
  text-align: center;
  height: 40px;
  border: 1px solid #27aad8;
  margin-left: -4px;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#ar_date-btn-transfers {
  width: 47px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

#ar_date-btn-transfers:hover {
  width: 47px;
  background-color: #d9d9d9;
  border-color: #cccccc;
}

.ar_accountingDashboard-color {
  padding-right: 20px;
  display: flex;
}

.ar_accounting-btn-list {
  color: #212121;
  width: 160px;
  text-align: center;
  height: 40px;
  background-color: white;
  border: 1px solid #d9d9d9;
  margin-left: -3px;
  cursor: pointer;
  font-family: "tajwal-bold";
}

.ar_accounting-btn-list-last {
  color: #212121;
  width: 160px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  background-color: white;
  border: 1px solid #d9d9d9;
  margin-left: -3px;
  cursor: pointer;
  font-family: "tajwal-regular";
}

.ar_accounting-btn-list-first {
  color: #212121;
  width: 160px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  background-color: white;
  border: 1px solid #d9d9d9;
  margin-left: -3px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "tajwal-bold";
}

#ar_br-btn-list-last {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ar_accounting-btn-list-e {
  color: white;
  background-color: #27aad8;
  width: 160px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  border: 1px solid #27aad8;
  margin-left: -4px;
  cursor: pointer;
  font-family: "tajwal-bold";
}

.ar_accounting-btn-list-e-last {
  color: white;
  background-color: #27aad8;
  width: 160px;
  text-align: center;
  height: 40px;
  border: 1px solid #27aad8;
  margin-right: -4px;
  cursor: pointer;
  font-family: "tajwal-bold";
}

.ar_accounting-btn-container {
  margin-bottom: 30px;
  margin-top: 45px;
}

.ar_accounting-big-container {
  min-height: 100vh;
  background-color: #f7f7f7;
  direction: rtl;
}

/*--*/

.ar_accountingDashboard-accounting-line-dashborad {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ar_accountingDashboard-Rectangle-681 {
  width: 330px;
  height: 355px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 10px;
  margin-top: 40px;
}

.ar_accountingDashboard-Rectangle-6812 {
  width: 330px;
  height: 355px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 10px;
  margin-top: 40px;
}

.ar_accountingDashboard-labelCard {
  width: 100px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  padding-right: 10px;
  font-family: "Tajawal";
}

.ar_accountingDashboard-value {
  width: 47px;
  height: 21px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  padding-right: 60px;
  font-family: "Tajawal";
}

.ar_accountingDashboard-Ellipse-181 {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: #e6e6e6;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.ar_accountingDashboard-Rectangle-451 {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  margin: 0 0 0;
  margin-left: 10px;
  background-color: #e6e6e6;
  font-family: "Tajawal";
}

.ar_accountingDashboard-flechHaut {
  margin-right: 10px;
}

.ar_accountingDashboard-flechBas {
  padding-right: 88px;
}

.ar_accountingDashboard-expirein {
  width: 100px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3;
  margin-top: 10px;
  margin-right: 20px;
  font-family: "Tajawal";
}

.ar_accountingDashboard-contract {
  width: 170px;
  height: 29px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #212121;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: "Tajawal";
}

.ar_accountingDashboard-number {
  width: 29px;
  height: 58px;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_accountingDashboard-of {
  width: 22px;
  height: 29px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3;
}

.ar_accountingDashboard-teen {
  width: 35px;
  height: 38px;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.56;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
}

.ar_accountingDashboard-Expires-in-30-days {
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  display: flex;
  margin-top: 10px;
  font-family: "Tajawal";
}

.ar_accountingDashboard-expire {
  text-align: center;
  margin: auto;
  padding-top: 30px;
}

.ar_accountingDashboard-days {
  font-weight: bold;
  color: #212121;
}

.ar_accountingDashboard-cercle {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Tajawal";
}

.ar_accountingDashboard-t30-days {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
  padding-top: 10px;
  padding-right: 10px;
}

.ar_accountingDashboard-bottom {
  padding-bottom: 0px !important;
}

.ar_accountingDashboard-canva {
  margin-top: 50px;
}

.ar_accountingDashboard-canva2 {
  margin-top: 12px;
}

.ar_transfers-collections-card {
  text-align: center;
  background-color: #dbe6f4;
  padding-top: 20px;
  padding-bottom: 6px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.ar_transfers-transferred-card {
  text-align: center;
  background-color: #dff0e0;
  padding-top: 20px;
  padding-bottom: 6px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.ar_tab-line-qw {
  width: 50px;
  height: 14px;
  font-family: "Tajawal";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
}

.ar_transfers-table {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
}

.ar_transfers-table td,
.ar_transfers-table th {
  padding: 10px;
}

.ar_transfers-table tr:nth-child(even) {
  font-weight: 500;
  background-color: white;
}

.ar_transfers-table tr:nth-child(odd) {
  font-weight: 500;
  background-color: #efefef;
}

.ar_transfers-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #d9d9d9;
  color: #808080;
  font-family: 'tajwal-Regular';
  font-size: 14px;
}

.ar_transfers-table th:first-child {
  border-top-right-radius: 4px;
}

.ar_transfers-table th:last-child {
  border-top-left-radius: 4px;
}

.ar_ant-input-search-enter-button+.ar_ant-input-group-addon .ar_ant-input-search-button,
.ar_ant-input-search-enter-button input+.ar_ant-input-group-addon .ar_ant-input-search-button {
  background-color: #e6e6e6;
  color: #666666;
  border-color: #cccccc;
  height: 40px;
}

.ar_collection-card-txt {
  color: #4e80c5;
  font-family: "Tajawal";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.ar_transferred-card-txt {
  color: #5fb563;
  font-family: "Tajawal";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  height: 40px;
}

.ar_transfers-picker-container {
  display: flex;
}

.ar_accounting_transfer_bigContainer {
  width: 65%;
}

.ar_transfers-date-picker {
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#ar_export-btn-transfers {
  width: 100%;
  background-color: #666666;
  border: solid 1px #666666;
  font-family: "Tajawal";
  border-radius: 4px;
  height: 40px;
  display: flex;
  color: white;
  font-weight: 700;
  justify-content: space-around;
  --antd-wave-shadow-color: none;
}

.ar_row-transfers-btn {
  margin-top: 20px;
}

#ar_export-btn-transfers:hover {
  color: white;
  background-color: #666666;
  border: solid 1px #666666;
}

#ar_br-btn-list {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#ar_br-btn-list-first {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/***********************************/

.ar_card1 {
  height: 480px;
  background-color: #ffffff;
  margin-bottom: 40px;
  padding-bottom: auto;
}

.ar_card2 {
  width: 100%;
  height: 480px;
  background-color: #ffffff;
  padding-bottom: 40px;
}

.ar_vaccantUnitView-basicInfo {
  height: 29px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  font-family: "Tajawal";
}

.ar_vaccantUnitView-basicInfo2 {
  height: 29px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
}

.ar_vaccantUnitView-edit {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #27aad8;
  cursor: pointer;
}

.ar_vaccantUnitView-ligne {
  width: 500px;
  height: 1px;
  background-color: #252121;
}

.ar_vaccantUnitView-unit {
  height: 21px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.ar_vaccantUnitView-type {
  width: 83px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 20px;
}

.ar_vaccantUnitView-floor {
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-top: 40px;
}

.ar_vaccantUnitView-value {
  width: 10px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.ar_vaccantUnitView-label {
  width: 75px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.ar_vaccantUnitView-label2 {
  width: 69px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  top: 3px;
}

.ar_vaccantUnitView-addTenant-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(78, 173, 83, 0.15);
  border-color: rgba(78, 173, 83, 0.15);
  box-shadow: none;
  border-width: 0px !important;
}

.ar_vaccantUnitView-addTenant-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #5fb563;
}

.ar_vaccantUnitView-invitTenant-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: #d3eef7;
  border-color: #d3eef7;
  box-shadow: none;
  border-width: 0px !important;
}

.ar_vaccantUnitView-invitTenant-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #27aad8;
}

.ar_vaccantUnitView-description {
  width: 290px;
  height: 19px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b3;
}

.ar_vaccantUnitView-listUnit-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(59, 115, 191, 0.15);
  border-color: rgba(59, 115, 191, 0.15);
  box-shadow: none;
  border-width: 0px !important;
}

.ar_vaccantUnitView-listUnit-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4e80c5;
}

.ar_accounting-big-container {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.ar_test1 {
  display: none;
}

.ar_test2 {
  display: block;
}

.ar_vaccantUnitView-deleteUnit {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: right;
  color: #666666;
  padding-top: 10px;
  margin-left: 165px;
  padding-right: 11.6% !important;
}

.ar_vaccantUnitView-label1 {
  width: 101px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.ar_vaccantUnitView-value1 {
  width: 105px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  padding-right: 20px;
}

.ar_vaccantUnitView-btnCancel {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #e6e6e6;
  box-shadow: none;
  border-width: 0px !important;
}

.ar_vaccantUnitView-cancel {
  width: 118px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
}

.ar_vaccantUnitView-similarUnitCercle {
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: #dbdbdb;
  justify-content: center;
  display: flex;
  padding-top: 5px;
  padding-left: 0px !important;
}

.ar_contentTextStyle {
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-right: 4px;
}

.ar_styleButtonDelete {
  background-color: #cb3d3d;
  border-color: #cb3d3d;
  color: white;
  margin-right: 100px;
  width: 100px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1en_92;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.ar_styleButtonDelete:hover {
  background-color: #cb3d3d;
  color: white;
  border-color: #cb3d3d;
}

.ar_styleButtonCancel:hover {
  background-color: #e6e6e6;
  color: #212121;
  border-color: #e6e6e6;
}

.ar_styleButtonCancelInvitation {
  background-color: #27aad8;
  color: white;
  margin-right: 100px;
  width: 140px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.ar_styleButtonCancel {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  width: 100px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
}

.ar_buttonModalStyle {
  text-align: center;
  align-items: center;
}

.ar_styleIconDeletedSimliarUnit {
  padding-bottom: 20px;
  margin-right: 12px;
}

.ar_test3 {
  padding-right: 165px;
}

.ar_vaccantUnitView-caractere1 {
  margin-left: 10px;
}

/*********************contract edit*****************/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.ar_editContractBigContainer {
  margin-top: 2%;
  margin-bottom: 0px;
  direction: rtl;
  margin-right: 10%;
}

.ar_editContractTextUnit {
  color: #808080;
  font-size: 18px;
}

.ar_editContractTextGreyContract {
  color: #808080;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.ar_editContractContractInfoContainer {
  margin-top: 15px;
}

.ar_editContractDivider_Contract {
  width: 100%;
  height: 1px;
  margin-top: 0px;
  background-color: #cccccc;
}

.ar_editContractTextGreyDate {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.ar_editContractDatePicker {
  display: flex;
  width: 100%;
  height: 48px;
  position: relative;
  padding-top: 10px;
  border-left: 0;
}

.ar_editContractInput {
  height: 40px;
}

.ar_editContractIndimg {
  height: 40px;
}

.ar_editContractInd {
  height: 40px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100% !important;
  border-left: 0;
}

.ar_editContractMonthlyContainer {
  margin-top: 1%;
  margin-bottom: 3%;
}

.ar_editContractBtnSubmitContainer {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ar_editContractBtnSubmitContainer:hover {
  border-color: #27aad8;
  background-color: #27aad8;
}

.ar_editContractBtnCancelContainer {
  width: 100%;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  height: 40px;
  border-radius: 4px;
}

.ar_editContractBtnCancelContainer:hover {
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.ar_editContractValidateText {
  color: #cb3d3d;
  font-weight: 400;
  margin-top: 2%;
  padding-top: 40px;
}

/*************forgot password***************/

.ar_phoneVerification-container {
  background-color: #36d1e0 !important;
  height: 100vh;
  direction: rtl;
  min-width: 100vw;
}

/* .ar_phoneVerification-logo {
  margin-right: 8%;
  margin-top: 2%;
} */

.ar_phoneVerification-resendPassword {
  height: 26px;
  font-family: 'tajwal-bold' !important;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.ar_phoneVerification-title {
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 24px;
  height: 44px;
  font-family: "tajwal-medium";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #808080;
}

.ar_phoneVerificationBackPage {
  font-family: "Tajawal";
  font-size: 14px;
  color: #27aad8;
}

.ar_phoneVerification-inputPhoneNumber {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 60px;
}

.ar_forgotPassword-newPassword {
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-bottom: 30px;
}

.ar_forgotPasswordMessage {
  height: 46px;
  font-family: "Tajawal";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #808080;
  margin-top: 30px;
}

.ar_forgotPasswordThankyou {
  height: 28px;
  font-family: "Tajawal";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-top: 14px;
  padding-bottom: 15px;
}

.resetPasswordInputPassword {
  height: 26px;
}

.ar_forgotPasswordCode {
  text-align: center;
  padding-right: 10%;
}

.ar_forgotPasswordResend {
  width: 100px;
  height: 14px;
  font-family: "Tajawal";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #27aad8;
}

/********************login page ************/

.ar_right {
  background-color: #36d1e0;
  min-height: 100vh;
  direction: rtl;
  padding-top: 20%;
  width: 50%;
}

.ar_languge {
  position: absolute;
  margin-right: 10%;
  margin-top: 1%;
}

.ar_cardRegister {
  /* text-align: center;
    margin: auto;
    margin-top:10%;
    max-width: 600px;
    min-width: 500px; */
  text-align: center;
  margin: auto;
  /* margin-top: 100vw - 482px; */
  /* position  : absolute; */
  max-width: 600px;
  min-width: 500px;
  border-radius: 15px;
}

.ar_signupTxt {
  color: gray;
  font-size: 16px;
}

.ar_title {
  direction: rtl;
  font-size: 24px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 60px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "tajwal-bold";
  align-self: center;
  display: block;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 80px;
}

.ar_divLogin {
  padding-left: 20%;
  padding-right: 20%;
}

.loginFormButton {
  width: 100%;
  height: 40px;
  background-color: #27aad8;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginFormButtonDisabled {
  width: 100%;
  height: 40px;
  border-radius: 10;
  background-color: #d6f6f9;
  border-style: none;
  border-radius: 5px;
  cursor: not-allowed;
}

.ar_link {
  color: #27aad8;
  font-weight: 600;
  height: 17px;
  font-family: "Tajawal";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.ar_shadow {
  cursor: pointer;
  border: 2px solid #cccccc;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  padding: 5px;
  align-content: center;
  direction: ltr;
  font-family: "Tajawal";
  display: flex;
}

.ar_googleImg {
  width: 10%;
  height: 80%;
}

.ar_labelForm {
  display: flex;
  flex: start;
  font-family: "Tajawal";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #666666;
}

.ar_trialTxt {
  font-size: 30px;
  overflow: auto;
  height: 39px;
  font-family: "Tajawal";
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  height: 62px;
}

.ar_tContainer {
  border-style: solid;
  color: #ffffff;
  direction: ltr;
  width: 100%;
  height: 80px;
  border-width: 12px;
  font-family: "Source Sans Pro";
  margin-right: -10px;
}

.ar_tTxt {
  color: #ffffff;
  font-weight: 900;
  padding-right: 10px;
  font-size: 48px;
  font-family: "Source Sans Pro";
  line-height: 1;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ar_bigContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ar_right {
    display: none;
  }
}

/*-------Tenant------------*/

.ar_addTenant-uploader .ant-upload.ant-upload-select-picture-card>.ant-upload {
  border: none;
  width: 100%;
  height: 250px;
  direction: rtl;
}

.ar_addTenant-uploader .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  margin-left: 0px;
}

.ant-upload.ant-upload-drag.ar_addTenant_drag {
  border-radius: 15px;
  background: none;
  height: 250px;
}

.ar_addTenant_select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 0px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: transparent;
}

.ant-select.ar_addTenant_select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  height: 40px;
}

.ar_addTenant_container {
  margin-right: 12%;
  margin-top: 40px;
  direction: rtl;
}

.ar_addTenant_theTitle {
  font-family: "tajwal-bold";
  font-size: 24px;
}

.ar_addTenant_Subtitle {
  font-family: "tajwal-bold";
  font-size: 18px;
  color: #808080;
  margin-top: 25px;
}

.ar_addTenant_hr {
  border: 1px solid #cccccc;
  background-color: #cccccc;
}

.ar_addTenant_detailsContainer {
  margin-top: 20px;
}

.ar_addTenant_optional {
  color: #b3b3b3;
}

.ar_addTenant_unitNum {
  font-family: "tajwal-bold";
  font-size: 18px;
  color: #808080;
}

.ar_addTenant_label {
  font-family: "tajwal-regular";
  font-size: 14px;
  margin-bottom: 5px;
}

#ar_addTenant_btn {
  height: 40px;
  width: 250px;
  color: #5fb563;
  font-size: 16px;
  font-family: "tajwal-bold";
  background-color: #e4f3e5;
  border-color: #e4f3e5;
  border-radius: 4px;
  margin: 35px 0 0 0;
}

#ar_inviteTenant_btn {
  height: 40px;
  width: 250px;
  color: #27aad8;
  font-size: 16px;
  font-family: "tajwal-bold";
  background-color: #d3eef7;
  border-color: #d3eef7;
  border-radius: 4px;
  margin: 35px 0 0 0;
}

.ar_inviteTenant_or {
  font-size: 18px;
  font-family: "tajwal-bold";
  color: #b3b3b3;
  text-align: center;
  padding-top: 6px;
}

.ar_inviteTenant_unitInfo {
  margin-top: 25px;
}

.ar_editTenant_photo {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
}

#ar_editTenant_saveBtn {
  width: 135px;
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
  border-radius: 4px;
  height: 40px;
  font-family: "tajwal-bold";
  font-size: 14px;
  margin-left: 25px;
  margin-bottom: 30px;
}

#ar_editTenant_cancelBtn {
  width: 135px;
  color: #212121;
  font-family: "tajwal-bold";
  font-size: 14px;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 30px;
}

.ar_editTenant_dragText {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-around;
}

.ar_editTenant_btn_file_names {
  text-align: center;
  position: absolute;
  top: 5px;
  margin: auto;
  width: 100%;
  left: 0px;
  height: 125px;
}

.ar_add_Floor {
  margin-left: 35px;
}

#ar_date-btn-transfers {
  width: 47px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

#ar_date-btn-transfers:hover {
  width: 47px;
  background-color: #d9d9d9;
  border-color: #cccccc;
}

.ar_transfers-date-picker {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/**--Property---**/

.ar_addProp_bigContainer {
  border-left: 1px solid #cccccc;
  direction: rtl;
  min-height: 100vh;
  margin-right: 10%;
}

.ar_addProp_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.ar_addProp_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 250px;
  left: 300px;
  overflow: visible;
}

.ar_addProp_vl {
  border-left: 1px solid #cccccc;
  height: 100vh;
}

.ar_addProp_logo {
  margin-left: 10%;
  margin-top: 5%;
}

.ar_addProp_text {
  font-family: "tajwal-bold";
  font-size: 24px;
  margin-left: 10%;
  margin-top: 10px;
}

.ar_addProp_search {
  width: 50%;
  margin-left: 10%;
  margin-top: 5px;
  height: 40px;
  padding: 5px 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.ar_addProp_pin {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.ar_addProp_textPin {
  font-family: "tajwal-regular";
  margin-left: 10%;
  margin-top: 2%;
  color: #b3b3b3;
  font-size: 15px;
}

.ar_addProp_map {
  margin-left: 10%;
  margin-top: 2%;
  height: 100%;
  width: 50%;
}

.ar_addProp_btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  width: 250px;
  height: 40px;
  margin-left: 10%;
  margin-top: 10%;
  background-color: #27aad8;
  border-color: #27aad8;
  border-radius: 4px;
}

.ar_addProp_skip {
  cursor: pointer;
  color: #666666;
  font-size: 16;
  font-family: "tajwal-bold";
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.ar_addProp_link {
  margin-left: 10%;
  padding-top: 10px;
  font-family: "tajwal-bold";
  font-size: 18px;
  color: #27aad8;
  font-weight: 600;
}

.ar_addProp_link:hover {
  color: #27aad8;
}

.ar_addProp_avatar-uploader.ant-upload-picture-card-wrapper {
  width: 27%;
}

.ar_addProp_animProp_container {
  direction: rtl;
}

.ar_addProp_description {
  margin-left: 10%;
  padding-top: 5px;
  font-family: "tajwal-bold";
  font-size: 18px;
  font-weight: 500;
  color: #808080;
}

.ar_addProp_line {
  border-left: 1px solid #cccccc;
  margin-top: -5px;
  margin-right: 0%;
  width: 40%;
}

.ar_addProp_Input {
  width: 30%;
  margin-left: 10%;
  padding-top: 10px;
}

.ar_addProp_animTxt {
  color: #cccccc;
  font-size: 32px;
  font-family: "tajwal-bold";
}

.ar_addProp_Label {
  font-size: 14px;
  font-family: "tajwal-regular";
  margin-left: 10%;
  padding-top: 10px;
}

/*----notif----*/

.ar_bank-note-txt {
  color: #4ead53;
  font-weight: bold;
  line-height: 2;
  margin-right: 35px;
  margin-top: 5px;
}

.ar_bank-check-text label {
  border-radius: 50%;
}

.ar_bank-check-text {
  margin-left: 10px;
  position: relative;
}

.ar_bank-check-text label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.ar_bank-check-text label:after {
  border: 2px solid #27aad8;
  background-color: #27aad8;
  border-radius: 50%;
  content: "";
  height: 12px;
  left: 15%;
  opacity: 0;
  position: absolute;
  top: 15%;
  transform: rotate(-45deg);
  width: 12px;
}

.ar_bank-check-text input[type="checkbox"] {
  visibility: hidden;
}

.ar_bank-check-text input[type="checkbox"]:checked+label {
  border: 1px solid #cccccc;
}

.ar_bank-check-text input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.ar_bank-added-icon {
  margin-right: 20px;
  width: 35px;
  margin-top: 5px;
}

.ar_bank-added-notification {
  border-right: 7px solid #4ead53;
  z-index: 100;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  box-shadow: none;
  width: 430px;
  left: 25px;
  height: 90px;
}

/*---welcome---*/

.ar_welcome_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin-top: -50%;
  margin-right: 50%;
}

.ar_welcome_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 50%;
  left: 50%;
  overflow: visible;
}

.ar_welcome_vl {
  border-left: 1px solid #cccccc;
  height: 100vh;
}

.ar_welcome_logo {
  margin-left: 10%;
  margin-top: 5%;
}

.ar_welcome_thanks {
  font-family: "tajwal-bold";
  font-size: 40px;
  margin-left: 10%;
  margin-top: 5%;
}

.ar_welcome_description {
  font-family: "tajwal-regular";
  font-size: 24px;
  margin-left: 10%;
  margin-top: 2%;
}

.ar_welcome_line {
  border-left: 1px solid #cccccc;
  margin-top: 10%;
  margin-right: 0%;
  width: 70%;
}

.ar_welcome_steps {
  font-family: "tajwal-bold";
  margin-top: 10%;
  margin-left: 10%;
}

.ar_welcome_checkbox {
  height: 20px;
  width: 20px;
  display: inline;
}

.ar_welcome_step {
  font-family: "tajwal-regular";
  font-size: 18px;
  color: #808080;
  padding-right: 5px;
  display: inline;
}

.ar_welcome_stepsBlock {
  width: 60%;
  margin-top: 3%;
  margin-left: 10%;
}

.ar_welcome_btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  width: 30%;
  height: 40px;
  margin-left: 10%;
  margin-top: 5%;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ar_addProp_containerElement {
  position: relative;
  height: 400px;
  overflow: hidden;
  margin-left: 10%;
  margin-right: 10%;
}

.ar_welcome_thanks_container {
  direction: rtl;
  margin-right: 10%;
}

.ar_welcome_step_container {
  border-left: solid 1px #cccccc;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 140px;
  border-radius: 5px;
  border: solid 1px rgba(202, 199, 199, 0.692);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: rgba(202, 199, 199, 0.692);
}

.ant-upload.ant-upload-drag {
  border-radius: 15px;
  background: none;
  height: 150px;
}

.ant-upload-text {
  color: #27aad8;
}

.ant-upload-list-item-image {
  border-radius: 5px;
  margin-right: 200px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  width: 120px;
  height: 100px;
}

.ant-upload-list-item {
  display: flex;
  flex-direction: column;
}

#templateBtn {
  padding: 8%;
  margin-bottom: 10px;
  width: 400px;
  height: 100%;
  border-radius: 15px;
  border: solid 1px #cccccc;
}

#donwload-bulk-txt {
  margin-top: 20px;
  font-family: "tajwal-regular";
  color: #27aad8;
  font-size: 14px;
}

#dnd-txt {
  font-family: "tajwal-regular";
  color: #b3b3b3;
  font-size: 14px;
  margin-bottom: 20px;
}

#drag-bulk {
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 415px) {
  #templateBtn {
    width: 310px;
  }
}

/*-----bulk-----*/

.ar_bulk_logo {
  margin-left: 5%;
  margin-top: 2%;
}

.ar_bulk_content {
  display: flex;
  justify-content: center;
}

.ar_bulk_bulkCard {
  border-radius: 8px;
  text-align: center;
  padding: 1% 10%;
  margin-top: 1%;
}

.ar_bulk_bulkTxt {
  color: gray;
  font-family: "tajwal-regular";
  font-size: 18px;
}

.ar_bulk_uploadtext {
  color: rgb(54, 200, 216);
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_bulk_dragBulk {
  border-radius: 5px;
}

.ar_bulk_templateBtn {
  padding: 10%;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

.ar_bulk_pdf {
  font-size: 50px;
}

.ar_bulk_bulkLink {
  font-family: "tajwal-bold";
  font-size: 16pxs;
  display: flex;
  align-self: start;
  color: #27aad8;
}

.ar_bulk_navigBulk {
  margin-top: 25px;
}

.ar_bulk_btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  display: block;
  width: 100%;
  background-color: #27aad8;
  border-color: #27aad8;
  height: 40px;
}

.ar_bulk_btn:hover {
  background-color: #27aad8;
}

.ar_bulk_container {
  background-color: white;
  margin-right: 5%;
  border-radius: 10px;
  padding: 30px;
  direction: rtl;
}

/*----congrats-----*/

.ar_tenantive-logo {
  margin-right: 15%;
  margin-top: 4%;
}

.ar_congrats-title {
  font-family: "tajwal-bold";
  font-size: 32px;
  margin-right: 15%;
  margin-top: 10%;
}

.ar_congratsUnit-title {
  font-family: "tajwal-bold";
  font-size: 32px;
  margin-right: 15%;
  margin-top: 10%;
}

.ar_congratsUnit_list {
  margin-right: 15%;
  margin-top: 50px;
  display: flex;
}

.ar_congrats-sous-title {
  font-family: "tajwal-regular";
  font-size: 24px;
  margin-right: 15%;
  color: #808080;
}

.ar_congrats-title-container {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 8%;
}

.ar_steps-container_subTitle {
  font-family: "tajwal-bold";
  font-size: 18px;
}

.ar_congrats-steps-title {
  font-family: "tajwal-bold";
  font-size: 24px;
  margin-right: 15%;
  margin-top: 10%;
}

.ar_congrats-first-container {
  border-left: solid 1px #cccccc;
  height: 100vh;
}

.ar_steps-container {
  margin-right: 15%;
  display: flex;
  justify-content: space-between;
  padding-left: 30%;
}

.ar_steps-container div {
  display: flex;
  flex-direction: column;
}

.ar_steps-container span {
  font-family: "tajwal-regular";
  font-size: 18px;
  color: #808080;
  margin-top: 4px;
}

.ar_congrats-check {
  color: #4ead53;
}

.ar_congrats-btns {
  display: flex;
  flex-direction: column;
  margin-right: 15%;
  margin-top: 5%;
  width: 200px;
}

.ar_congrats-addOther-btn {
  width: 100%;
  color: #27aad8;
  height: 40px;
  border: 2px solid #27aad8;
  margin-top: 10px;
  font-family: "tajwal-bold";
  font-size: 16px;
  border-radius: 4px;
}

.ar_congrats-addOther-btn:hover {
  color: #27aad8;
  border: 2px solid #27aad8;
}

.ar_multipleProp_containerTitle {
  font-family: "tajwal-bold";
  font-size: 32px;
}

.ar_congrats-finish-btn {
  width: 100%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  font-family: "tajwal-bold";
  font-size: 16px;
  border-radius: 4px;
}

.ar_congratsUnit_link {
  color: #27aad8;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.ar_congratsUnit_link:hover {
  color: #27aad8;
}

.ar_congrats-finish-btn:hover {
  background-color: #27aad8;
}

@media only screen and (max-width: 515px) {
  .ar_steps-container {
    padding-left: 10%;
  }
}

.ar_congrats-list-container {
  overflow: scroll;
  height: 100vh;
}

.ar_congrats_list {
  display: flex;
  margin-right: 45px;
}

.ar_congrats_list-span {
  display: flex;
  flex-wrap: wrap;
}

.ar_congratsUnit_list span {
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-left: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 90px;
  font-family: "tajwal-regular";
  font-size: 16px;
}

.ar_congrats_list span {
  font-family: "tajwal-regular";
  font-size: 16px;
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-left: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 90px;
}

.ar_congrats_list-title {
  font-family: "tajwal-bold";
  font-size: 24px;
  padding-right: 5%;
  margin-top: 5%;
  margin-bottom: 25px;
}

.ar_congrats_img-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ar_congrats-property-name {
  margin-top: 0;
  font-family: "tajwal-bold";
  font-size: 18px;
}

#ar_addMoreUnit {
  border: solid 1px #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 50%;
  color: #27aad8;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 14px;
  font-family: "tajwal-regular";
  margin-bottom: 90px;
  cursor: pointer;
}

.ar_congratsUnit-big-container {
  direction: rtl;
}

.ar_cong-more-unit-logo {
  width: 25px;
  height: 25px;
}

.ar_congratsUnit_propName {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
}

.ar_multipleProp_container {
  min-height: 100vh;
  background-color: #f7f7f7;
  direction: rtl;
}

.ar_multipleProp_list {
  margin-right: 15%;
  margin-top: 50px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 3%;
  width: 100%;
  min-height: 485px;
}

.ar_multipleProp_list span {
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-left: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 14px;
  font-family: "tajwal-regular";
  margin-bottom: 90px;
}

.ar_multipleProp_btns_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.ar_multipleProps_btns_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  min-height: 485px;
  margin-top: 50px;
  border-radius: 5px;
  margin-right: 15%;
}

.ar_congrats-big-container {
  direction: rtl;
}

.ar_congrats_span_txt {
  margin-top: -20px;
}

/*----add Unit -----*/

.ar_addUnit_logo {
  margin-right: 20%;
  margin-top: 5%;
}

.ar_addUnit_card {
  border: none;
  padding: 24px;
}

.ar_addUnit_infoDiv {
  display: flex;
  font-family: "tajwal-regular";
  font-size: 14px;
  justify-content: space-between;
}

.ar_addUnit_unitNumberDiv {
  display: flex;
  flex-direction: column;
}

.ar_addUnit_infoTitle {
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .ar_addUnit_cardContainer {
    padding: 0%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 2000px) {
  .ar_addUnit_cardContainer {
    padding: 0% 15% 0% 15%;
  }
}

@media only screen and (min-width: 765px) and (max-width: 991px) {
  .ar_addUnit_cardContainer {
    padding: 0% 15% 0% 25%;
  }

  .ar_addUnit_middle {
    text-align: center;
    padding-right: 35%;
    top: -100px;
  }

  .ar_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .ar_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 764px) {
  .ar_addUnit_cardContainer {
    padding: 0% 25% 0% 15%;
  }

  .ar_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .ar_addUnit_middle {
    text-align: center;
    padding-right: 35%;
    top: -100px;
  }

  .ar_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .ar_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .ar_addUnit_middle {
    text-align: center;
    padding-right: 30%;
    top: -100px;
  }

  .ar_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .ar_addUnit_or {
    display: none;
  }
}

.ar_addUnit_add {
  margin: 0 5px;
  border: none;
  width: 50px;
  padding-right: 10px;
  align-self: center;
}

.ar_addUnit_or {
  color: #b3b3b3;
  position: absolute;
  left: -17px;
  border: solid 1px #b3b3b3;
  border-radius: 50%;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  font-family: "tajwal-bold";
  font-size: 18px;
  z-index: 1;
}

.ar_addUnit_bigContainer {
  overflow: hidden;
}

.ar_addUnit_cardContainer {
  margin-top: 15%;
  border-left: solid 1px #cccccc;
  height: 100%;
}

.ar_addUnit_infoTitleBasic {
  font-family: "tajwal-bold";
  font-size: 24px;
  margin-right: 8%;
}

.ar_addUnit_middle {
  text-align: center;
  margin-top: 18%;
}

.ar_addUnit_middle h1 {
  color: #808080;
  font-weight: bolder;
}

.ar_addUnit_rigth {
  text-align: center;
}

.ar_addUnit_middleTitle {
  font-family: "tajwal-regular";
  font-size: 24px;
}

.ar_bulk_bulkTitle {
  font-family: "tajwal-bold";
  font-size: 24px;
}

.ar_addUnit_bulkBtn {
  font-family: "tajwal-regular";
  font-size: 14px;
  height: 40px;
  background-color: #e6e6e6;
  color: #212121;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
  border: none;
}

.ar_addUnit_moreUnit {
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_addUnit_manySimilar {
  font-family: "tajwal-bold";
  font-size: 24px;
}

.ar_addUnit_noMore {
  display: none;
}

.ar_addUnit_btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  margin-top: 85%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  display: block;
  width: 90%;
}

.ar_addUnit_bulkLink {
  font-family: "tajwal-regular";
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  align-self: start;
  color: #27aad8;
}

.ar_addUnit_btn:hover {
  border-color: #27aad8;
  background-color: #27aad8;
}

.ar_addUnit_btnMore {
  display: block;
  width: 50%;
  border-color: #27aad8;
  color: #27aad8;
  height: 40px;
}

.ar_addUnit_btnNext {
  font-family: "tajwal-bold";
  font-size: 16px;
  margin-top: 12%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  display: block;
  width: 50%;
}

.ar_addUnit_bulkLink {
  font-family: "tajwal-bold";
  font-size: 16px;
  display: flex;
  align-self: start;
  color: #27aad8;
}

.ar_addUnit_typeView {
  font-family: "tajwal-bold";
  font-size: 16px;
  color: #212121;
  font-weight: 450;
}

.ar_addUnit_titleView {
  font-family: "tajwal-bold";
  font-size: 24px;
  color: #212121;
}

.ar_addUnit_titleTxtView {
  font-family: "tajwal-regular";
  font-size: 18px;
  color: #808080;
}

.ar_addUnit_furnishedView {
  font-family: "tajwal-regular";
  font-size: 16px;
  color: #808080;
}

.ar_addUnit_infoView {
  font-size: 16px;
  color: #212121;
  font-family: "tajwal-bold";
}

.ar_addUnit_amenetiesView {
  font-size: 16px;
  color: #808080;
  margin-left: 15px;
}

.ar_addUnit_limitView {
  border: 1px solid #cccccc;
  width: 70%;
  margin-top: 4%;
  position: relative;
  right: -17%;
}

.ar_addUnit_moreInfo {
  font-family: "tajwal-regular";
  font-size: 14px;
  display: flex;
  margin-bottom: 25px;
}

.ar_amenetie_infoDiv {
  font-family: "tajwal-regular";
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.ar_amenetie_logo {
  margin-right: 12%;
  margin-top: 5%;
}

.ar_amenetie_bulkLink {
  font-family: "tajwal-bold";
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  align-self: start;
  margin-top: 20px;
  color: #27aad8;
}

.ar_amenetie_navigBulk {
  margin-top: 25px;
}

.ar_amenetie_bulkCard {
  border: none;
  text-align: center;
  width: 40%;
  margin-right: 13%;
  margin-top: 35px;
}

.ar_amenetie_title {
  font-family: "tajwal-bold";
  font-size: 24px;
  padding-right: 12.5%;
  padding-top: 4%;
  margin: 0;
}

.ar_amenetie_bigContainer {
  height: 100vh;
  border-left: solid 1px #cccccc;
}

.ar_amenetie_btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  display: block;
  width: 90%;
  background-color: #27aad8;
  border-color: #27aad8;
  margin-top: 58%;
  height: 40px;
}

.ar_amenetie_btn:hover {
  background-color: #27aad8;
  border-color: #27aad8;
}

.ar_anim_container {
  direction: rtl;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.box {
  width: 500px;
  height: 500px;
  background: #5ab05e;
  position: absolute;
  /*animation: mymoveUnit 2s;*/
  padding-top: 10%;
  top: 500px;
}

.box-steps-1 {
  animation: mymoveUnit 1s;
  top: 250px;
}

.box-steps-2 {
  animation: mymoveUnit2 1s;
  top: 0px;
}

.box-steps-3 {
  animation: mymoveUnit3 1s;
  top: 0px;
}

.Unitimage {
  position: relative;
  top: -1px;
}

.unit_backgroundAnimation {
  width: 500px;
  height: 497px;
  background-color: #cccccc;
  overflow: hidden;
  position: absolute;
  left: 55%;
  margin-left: -250px;
}

.next-btn {
  position: absolute;
  top: 550px;
}

@keyframes mymoveUnit {
  from {
    top: 500px;
  }

  to {
    top: 250px;
  }
}

@keyframes mymoveUnit2 {
  from {
    top: 250px;
  }

  to {
    top: 0px;
  }
}

@keyframes mymoveUnit3 {
  from {
    top: 0px;
  }

  to {
    top: 0px;
  }
}

.ar_addProp_anim_img {
  margin-left: 40px;
}

/*----dashboard----*/

@import url("https://fonts.ar_dashbord_googleapis.ar_dashbord_com/css?family=Source+Sans+Pro&display=swap");

.hr-property-card {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #f0f0f0;
}

.ant-card-body {
  padding: 0px;
}

.ar_dashbord_bigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
  direction: rtl;
}

.ar_dashbord_editProp {
  cursor: pointer;
  display: block;
  float: left;
  color: #27aad8;
  font-size: 16px;
  font-weight: 500;
}

.ar_dashbord_site {
  margin: 20px;
  direction: rtl;
}

.ar_dashbord_textWithBorder {
  border-style: solid;
  border-color: #0000ff;
  border-width: 4px;
}

.ar_dashbord_titleBankAccount {
  font-family: "tajwal-bold";
  font-size: 16px;
  color: #212121;
}

.ar_dashbord_textNumberUnit {
  font-size: 16px;
  color: #212121;
  font-family: "tajwal-bold";
  padding-left: 10px;
}

.ar_dashbord_textGreen {
  font-size: 16px;
  color: #5fb563;
  font-family: "tajwal-regular";
}

.ar_dashboard_spinContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.ar_dashboard_spinContainerHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 57vh;
}

.ar_dashboard_spinText {
  font-size: 16px;
  font-family: "tajwal-regular";
  color: #27aad8;
  margin-top: 5px;
}

.ar_dashbord_textGreen2 {
  font-size: 16px;
  color: #5fb563;
  font-family: "tajwal-regular";
}

.ar_dashbord_textGreyLabel {
  font-family: "tajwal-regular";
  font-size: 16px;
  color: #808080;
}

.ar_dashbord_Total {
  font-size: 18px;
  color: #212121;
  margin-right: 13px;
  font-family: "tajwal-bold";
}

.ar_dashbord_textGrey {
  font-family: "tajwal-regular";
  font-size: 14px;
  color: #808080;
  margin-right: 20px;
}

.ar_dashbord_adress {
  font-size: 15px;
  color: #808080;
  padding-right: 20px;
}

.ar_dashbord_connectBankTextGrey {
  font-family: "tajwal-regular";
  font-size: 14px;
  color: #b3b3b3;
  padding-bottom: 20px;
}

.ar_dashbord_titleText {
  font-size: 20px;
  font-family: "tajwal-bold";
  color: #212121;
  margin-right: 20px;
  padding-top: 20px;
}

.ar_dashbord_textRed {
  font-size: 16px;
  color: #cb3d3d;
  font-family: "tajwal-regular";
}

.ar_dashbord_directionRight {
  text-align: end;
  padding-left: 20px;
}

.ar_dashbord_centerImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3eef7;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ar_dashbord_BtnConnect {
  background-color: "#27AAD8";
  border-color: "#27AAD8";
  padding-right: 30;
}

.ar_dashbord_cardColPadding {
  margin-right: 20px;
  margin-top: 20;
  margin-bottom: 20;
}

.ar_dashbord_col-md-6 {
  display: inline;
}

.ar_containerNavBar {
  direction: rtl;
  font-family: "tajwal-bold";
  font-size: 18px;
}

.ar_dashbord_msg_setting {
  float: left;
}

.ar_dashbord_containerBankAccount {
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.ar_dashbord_Btn {
  font-family: "tajwal-bold";
  font-size: 16px;
  background-color: #27aad8;
  border-color: #27aad8;
  padding-right: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 45px;
  margin: 35px;
}

.ar_dashbord_Btn:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-right: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 45px;
  margin: 35px;
}

.ar_dashbord_BtnBank {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-right: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 35px;
}

.ar_dashbord_BtnBank:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-right: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 35px;
}

#dashbord-btn-prop {
  margin-right: 18px;
  font-size: 16px;
  font-family: "tajwal-regular";
  border: solid 2px #cccccc;
  background-color: transparent;
  padding: 15px 20px 10px;
  border-radius: 5px;
  width: 180px;
  height: 40px;
  text-align: center;
  line-height: 0;
  margin-bottom: 20px;
}

#dashbord-btn-prop-link {
  color: #666666;
}

#connectBankStyle {
  color: white;
}

.ar_btn-dash-bank {
  font-family: "tajwal-bold";
  font-size: 12px;
  background-color: #27aad8;
  border-color: #27aad8;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  width: 50%;
  height: 35px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
}

.ar_containerDashboard {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ar_dashbord_location {
  margin-right: 20px;
}

@media (min-width: 768px) {
  .ar_containerDashboard {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .ar_containerDashboard {
    max-width: 90%;
  }
}

/*----Edit unit ---*/

.ar_editUnit_bigContainer {
  margin-right: 10%;
  margin-top: 40px;
}

.ar_editUnit_BasicInfo_Container {
  margin-bottom: 35px;
}

.ar_editUnit_BasicInfo_form {
  padding: 25px 80px;
  border: solid 1px #cccccc;
  border-radius: 4px;
  height: 100%;
}

.ar_editUnit_btns {
  margin-right: 10%;
  margin-top: 50px;
}

.ar_editUnit_hr {
  margin-right: 55px;
  border: 1px solid #cccccc;
  width: 90%;
}

.ar_editUnit_btn_container {
  margin-right: 55px;
  margin-top: 15px;
}

.ar_editUnit_label {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}

.ant-switch-checked {
  background-color: #27aad8;
}

.ar_editUnit_theAll {
  direction: rtl;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .ar_editUnit_BasicInfo_form {
    padding: 25px 40px;
  }
}

@media only screen and (min-width: 765px) and (max-width: 860px) {
  .ar_editUnit_BasicInfo_form {
    padding: 25px 40px;
  }
}

.ar_tenantProfile_editBtn {
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.ar_tenantProfile_editBtn img {
  width: 100%;
  max-height: 120px;
  object-fit: cover;
}

.ar_tenantProfile_editBtn:hover {
  border: none;
  height: 100%;
  width: 100%;
}

.ar_tenantProfile_editBtnContainer {
  display: flex;
}

.ar_tenantProfile_editInput {
  width: 190px;
  border: 1px solid #cccccc;
  height: 40px;
  border-left: none;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ar_tenantProfile_upload {
  position: relative;
}

.ar_tenantProfile_upload .ant-upload-list-item-info {
  position: absolute;
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  top: -40px;
  right: -210px;
}

.ar_tenantProfile_upload .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}

.ar_tenantProfile_upload .ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  display: none;
}

/*@font-face {
    font-family: 'SF-pro-bold';
    src: url(./fonts/FontsFree-Net-SFProDisplay-Bold.ttf) format('truetype');
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .ar_cardContainer {
    width: 100%;
  }

  .ar_bigContainer {
    display: flex;
    justify-content: center;
  }

  .ar_right {
    display: none;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .ar_cardContainer {
    width: 100%;
  }

  .ar_bigContainer {
    display: flex;
    justify-content: center;
  }

  .ar_right {
    display: none;
  }
}

.ar_cardContainer {
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: white;
}

.ar_signupTxt {
  font-family: "Tajawal";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3;
  text-align: center;
}

/****************SignUp*******************/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.ar_cardRegister {
  text-align: center;
  margin: auto;
  max-width: 600px;
  min-width: 500px;
  border-radius: 15px;
}

.ar_languge {
  position: absolute;
  margin-left: 10%;
  margin-top: 1%;
}

.ar_divLogin {
  padding-left: 20%;
  padding-right: 20%;
}

.ar_loginFormButton {
  width: 100%;
  height: 40px;
  background-color: #27aad8;
  border-radius: 5px;
  border-style: none;
}

.ar_or {
  color: #b3b3b3;
  font-size: 18;
}

.ar_shadow {
  cursor: pointer;
  border: 2px solid #cccccc;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  padding: 5px;
}

.ar_right {
  background-color: #36d1e0;
  height: 100vh;
  direction: ltr;
  padding-top: 20%;
  width: 50%;
}

.ar_image {
  width: 50%;
  height: 50%;
}

.ar_googleImg {
  width: 20%;
  height: 80%;
}

.ar_link {
  color: #27aad8;
}

.ar_labelForm {
  display: flex;
  flex: start;
}

.ar_trialTxt {
  color: #ffffff;
  font-size: 30px;
  direction: rtl;
  margin-left: -10px;
}

.ar_tContainer {
  border-style: solid;
  color: #ffffff;
  direction: ltr;
  width: 100%;
  height: 70px;
  border-width: 8px;
  font-family: "Source Sans Pro";
  margin-left: -10px;
}

.ar_tTxt {
  color: #ffffff;
  font-weight: 900;
  padding-right: 10px;
  font-size: 48px;
  font-family: "Source Sans Pro";
  line-height: 1;
}

.ar_cardContainer {
  display: flex;
  justify-content: center;
  width: 50%;
}

@media (min-width: 1581px) {
  .ar_cardRegister {
    top: 5%;
  }
}

@media (min-width: 1281px) and (max-width: 1580px) {
  .ar_cardRegister {
    top: 5%;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .ar_cardRegister {
    top: 5%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .ar_bigContainer {
    display: flex;
    justify-content: center;
  }

  .ar_cardContainer {
    width: 100%;
    margin: 0;
  }

  .ar_cardRegister {
    margin-top: 15%;
  }

  .ar_right {
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .ar_cardContainer {
    width: 100%;
  }

  .ar_bigContainer {
    display: flex;
    justify-content: center;
  }

  .ar_cardRegister {
    top: 5%;
  }

  .ar_right {
    display: none;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ar_cardContainer {
    width: 100%;
  }

  .ar_bigContainer {
    display: flex;
    justify-content: center;
  }

  .ar_right {
    display: none;
  }
}

.ar_bigContainer {
  display: flex;
  overflow: hidden;
}

.ar_SignUpArabicTerm {
  height: 28px;
  font-family: "Tajawal";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

/**********************WelcomePage***************************/

.ar_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  left: 125;
}

.ar_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 50%;
  right: 50%;
  overflow: visible;
}

.ar_vl {
  border-right: 1px solid #cccccc;
  height: 100vh;
}

.ar_logo {
  margin-right: 10%;
  margin-top: 5%;
}

.ar_thanks {
  margin-right: 10%;
  margin-top: 5%;
  height: 37px;
  font-family: "Tajawal";
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_description {
  margin-right: 10%;
  margin-top: 2%;
  height: 25px;
  font-family: "Tajawal";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
}

.ar_line {
  border-right: 1px solid #cccccc;
  margin-top: 10%;
  margin-right: 10%;
  width: 70%;
}

.ar_steps {
  margin-top: 5%;
  margin-right: 10%;
  width: 95px;
  height: 25px;
  font-family: "Tajawal";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_checkbox {
  height: 20px;
  width: 20px;
  display: inline;
}

.ar_step {
  padding-right: 5px;
  display: inline;
}

.ar_stepsBlock {
  width: 60%;
  margin-top: 3%;
  margin-right: 10%;
  height: 19px;
  font-family: "Tajawal";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #808080;
}

.ar_btn {
  width: 30%;
  height: 40px;
  margin-right: 10%;
  margin-top: 5%;
  background-color: #27aad8;
  font-family: "Tajawal";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
}

.ar_welcomeValue {
  height: 19px;
  font-family: "Tajawal";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
}

.errorLabel {
  color: #c62828;
  font-size: 12px;
  font-weight: 600;
}

.ar_bbank_style {
  margin-right: 15px;
}

.ar_editINfo-style {
  font-size: 18px;
  color: #808080;
  margin-left: 10px;
}

.ar_editbank-tyle {
  font-size: 18px;
  color: #808080;
  margin-left: 10px;
  display: flex;
}

.ar_bankk_style {
  margin-right: 2px;
}

.ar_directioncomponent {
  direction: rtl;
}

.ar_setting-big-div {
  height: 100%;
}

.ar_row-setting-navbar {
  border-bottom: solid 1px #cccccc;
  padding-top: 20px;
  padding-bottom: 13px;
  background-color: white;
  font-family: "Tajawal";
  position: sticky;
  top: 0;
  z-index: 1;
}

.ar_row-setting-navbar-shadow {
  border-bottom: solid 1px #cccccc;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  font-family: "Tajawal";
  position: sticky;
  top: 0;
  z-index: 1;
}

.ar_setting-menu-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}

.ar_setting-menu-text span {
  font-weight: bolder;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ar_setting-big-container {
  direction: rtl;
  background-color: #f7f7f7;
  font-family: "tajwal-Regular";
  min-height: 100vh;
}

.ar_setting-menu.ant-menu {
  background-color: #f7f7f7;
  border-left: none;
  color: #b3b3b3;
  height: 100%;
  border-left: 0;
}

.ar_setting-menu.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 6px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}

.ant-menu-vertical>.ant-menu-item-selected {
  color: #212121;
}

.ant-menu-vertical>.ant-menu-item-selected .ar_vertival-nav-link {
  color: #212121;
}

.ar_vertival-nav-link {
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
}

.ar_vertival-nav-link:hover {
  text-decoration: none;
  color: #b3b3b3;
}

.ant-menu-vertical>.ant-menu-item:hover {
  color: #b3b3b3;
}

.ar_ant-menu-vertical>.ant-menu-item-selected:hover {
  color: #212121;
}

.ar_setting-menu-logout {
  /* padding-right: 20px; */
  margin-top: 8%;
}

.ant-menu-vertical>.ant-menu-item {
  height: 50px;
}

.ar_card-container {
  background-color: white;
  margin-right: 5%;
  margin-left: 27px;
  border-radius: 10px;
  padding: 30px;
}

.ar_bank-info-container {
  display: flex;
  flex-direction: column;
  direction: "rtl";
}

@media (max-width: 265px) {
  .ar_card-container {
    padding: 0px;
  }

  .ar_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .ar_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 365px) {
  .ar_card-container {
    padding: 0px;
  }

  .ar_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .ar_txxxxxxxtt {
    font-size: 14px;
  }

  .ar_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 600px) {
  .ar_card-container {
    padding: 0px;
  }

  .ar_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .ar_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 768px) {
  .ar_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .ar_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
  }
}

@media (max-width: 992px) {
  .ar_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }
}

@media (max-width: 1025px) {
  .ar_txxxxxxxt {
    font-size: 15px;
  }

  .ar_subcription-card-list {
    text-align: -webkit-center;
  }
}

.ar_txxxxxxxt {
  margin: 0px;
  display: flex;
}

.ar_bank-check-me {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.ar_bank-radio-text {
  color: #808080;
  font-size: 18px;
  margin-bottom: 3%;
  display: flex;
  line-height: 1;
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border: none;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  margin: 10px 0px 5px 0px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 50%;
  height: 50%;
  margin-top: 10px;
}

.ar_tenantive-btn-top {
  margin-top: 220px;
  width: 250px;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.ar_tenantive-btn-edit {
  width: 100%;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.ar_tenantive-btn {
  width: 250px;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.ar_tenantive-navbar-link:hover {
  text-decoration: none;
}

.ar_navbar-menu {
  border-bottom: none;
}

.ar_bank-iam-drag {
  margin-left: 5%;
}

.ar_bank-iam-drag-label {
  color: #666666;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.ant-upload-hint {
  font-size: 12px;
}

.ar_bank-setup-link {
  color: #27aad8;
}

.ar_bank-setup-link:hover {
  color: #27aad8;
  text-decoration: none;
}

.ant-input {
  border-radius: 5px;
}

.ant-select-multiple .ant-select-selector {
  border-radius: 5px;
}

.ant-input-number {
  border-radius: 5px;
}

.ar_edit-bank-card {
  background-color: #f4fbfd;
  border-right: 4px solid #27aad8;
  height: 115px;
  position: relative;
  left: 0;
  padding: 15px;
  margin: 15px;
  width: 360px;
}

.ar_bank-name-edit {
  display: flex;
  justify-content: space-between;
}

.ar_edit-span-btn {
  color: #27aad8;
  cursor: pointer;
  font-weight: bold;
}

.ar_edit-span-btn:hover {
  color: #27aad8;
}

.ar_no-edit-bank-card {
  display: none;
}

.ar_edit-span-btn2 {
  color: #b3b3b3;
  cursor: pointer;
  font-weight: bold;
}

.ar_cancel-edit-bank {
  color: #666666;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.ar_cancel-edit-bank:hover {
  color: #666666;
}

.ar_bank-added-notification {
  border-right: 7px solid #4ead53;
  z-index: 100;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  box-shadow: none;
  width: 430px;
  left: 25px;
  height: 90px;
  direction: rtl;
}

.ar_save-edit-bank-info {
  color: white;
}

.ar_save-edit-bank-info:hover {
  color: white;
}

.ar_bank-note-txt {
  color: #4ead53;
  font-weight: bold;
  line-height: 2;
  margin-top: 5px;
  direction: rtl;
}

.ar_bank-added-icon {
  width: 35px;
  margin-top: 5px;
  direction: rtl;
}

.ar_subscription-big-container {
  padding: 0px 4%;
}

.ar_subscription-card {
  display: flex;
  flex-direction: column;
  margin: 30px 30px 30px 0px;
  border-radius: 6px;
  width: 240px;
  text-align: center;
  background-color: white;
  padding: 20px 0px;
}

.ar_subscription-card-bande {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  padding: 14px 0px 0px 0;
  background-image: linear-gradient(to left, #34cbdf, #27aad8);
}

.ar_card-subscription-img {
  width: 8%;
  margin-left: 3px;
}

.ar_card-subscription-txt {
  color: #808080;
  margin-bottom: 20px;
  font-weight: 450;
  font-size: 18px;
}

.ar_subscription-card-choices {
  display: flex;
  flex-direction: column;
  text-align: start;
  color: #808080;
  padding: 20px 0px 6px 25px;
}

#ar_subscription-card-btn {
  width: 55%;
  align-self: center;
  border-radius: 4px;
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
  font-size: 16px;
  height: 40px;
  margin-top: 25px;
}

.ar_subcription-card-list {
  display: flex;
  flex-wrap: wrap;
}

.ar_card-subscription-txt1 {
  color: #808080;
  margin-bottom: 37px;
  font-weight: 450;
  font-size: 18px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
  border-radius: 5px;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  min-height: 40px;
}

.ant-radio-button-wrapper:hover {
  color: #212121;
}

.ant-radio-button-wrapper {
  color: #212121;
  width: 25%;
  text-align: center;
  height: 35px;
  height: 40px;
  padding-top: 4px;
}

@media (max-width: 415px) {
  .ant-radio-button-wrapper {
    width: 40%;
  }
}

.ant-radio-group {
  width: 100%;
  margin-bottom: 30px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #27aad8;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #27aad8;
}

.ar_dragBulk {
  border-radius: 5px;
}

.ar_uploadtext {
  color: rgb(54, 200, 216);
}

.ar_none {
  display: none;
  direction: rtl;
}

.ar_display {
  display: none;
  direction: rtl;
}

.ar_inviteUser_container {
  background-color: white;
  width: 100%;
  direction: rtl;
  padding: 20px;
  border-radius: 10px;
  color: #212121;
}

.ar_user-container {
  background-color: white;
  min-width: 558px;
  direction: rtl;
  padding: 10px;
  margin-bottom: 20px;
  min-height: 150px;
  font-family: "tajwal-Regular";
  border-left: 5px solid #5fb563;
}

.ar_sub_tittle {
  direction: rtl;
  width: fit-content;
  font-size: 18px;
  line-height: 19px;
  font-weight: normal;
  font-style: normal;
}

.ar_viewchart-col>* {
  direction: rtl;
  margin-bottom: 10px;
}

.ar_col>* {
  direction: rtl;
  margin-bottom: 20px;
}

.ar_label {
  direction: rtl;
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  font-weight: normal;
}

.ar_optional_label {
  direction: rtl;
  width: fit-content;
  color: #b3b3b3;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  margin-right: 85px;
  position: absolute;
  left: 0;
}

.ar_cancel_button,
.ar_invite_button {
  width: 100%;
  direction: rtl;
  border-radius: 5px;
}

.ar_cancel_button {
  direction: rtl;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.ar_cancel_button:hover {
  direction: rtl;
  background-color: #e6e6d6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.ar_user-name {
  direction: rtl;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  margin-bottom: 20px;
}

.ar_user-details {
  direction: rtl;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
}

.ar_editButton {
  direction: rtl;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: right;
  color: #27aad8;
}

.ar_setting-user-editUser-divider {
  margin-top: 10px;
  direction: rtl;
  height: 2px;
}

.ar_editUser-label {
  direction: rtl;
  margin: 0%;
  padding: 0%;
  display: flex;
}

.ar_EditUser_select {
  direction: rtl;
  width: 100%;
}

.ar_EditUser_checkbox {
  direction: rtl;
  width: 100%;
  display: flex;
  margin: auto;
}

.ar_User-container {
  direction: rtl;
  padding: 30px;
  margin-right: 5%;
}

.ar_User-invitebutton {
  direction: rtl;
  margin-bottom: 30px;
}

.ar_User-invite-buttonn {
  direction: rtl;
  width: 40%;
  border-radius: 5px;
}

.ar_container_profiletop {
  background-color: white;
  margin-right: 5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px;
  margin-left: 27px;
}

.ar_container_profilebottom {
  background-color: white;
  margin-right: 5%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 32px 40px 40px 40px;
  margin-left: 27px; 
}

.ar_titles {
  font-size: 14px;
  color: #666666;
  line-height: 19px;
  margin-bottom: 8px;
  font-family: "tajwal-medium";
}

.ar_phone_number_label {
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
  font-family: "tajwal-medium";
}

@media only screen and (max-width: 567px) {
  .ar_phone_number_label {
    margin-top: 0px;
  }
}

.ar_info {
  color: #212121;
  font-size: 18px;
}

.ar_infoname {
  font-family: "tajwal-bold";
  color: #212121;
  font-size: 18px;
}

.ar_infonameResponsive {
  display: none;
}

.ar_data {
  color: #808080;
  font-size: 16px;
}

.ar_infoEmail {
  color: #808080;
  font-size: 16px;
  font-family: "tajwal-regular";
}

.ar_infoEmailResponsive {
  display: none;
}

.ar_ChangePass {
  color: #808080;
  font-size: 16px;
}

.ar_dataa {
  color: #000000;
  font-size: 16px;
  padding-left: 80px;
  font-family: "tajwal-bold";
}

.ar_saveeditButton {
  font-size: 16px;
  color: #27aad8;
  line-height: 23px;
  font-family: "tajwal-bold";
  padding: 0px;
  margin-top: 10px;
}

.ar_saveeditButton:hover {
  color: #27aad8;
}

.ar_saveeditButton:focus {
  color: #27aad8;
}

.ar_cnxTenantEnterPassword {
  margin-right: 9%;
  height: 11px;
  font-family: "tajwal-regular";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 27px;
}

.ar_changePassordButton {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
  font-size: 14px;
  width: 140px;
  margin: 24px 0 8px 0;
  font-family: "tajwal-bold";
  height: 48px;
  box-shadow: none;
  text-shadow: none;
  border-radius: 4px;
}

.ar_updatePasswordButton {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "tajwal-medium";
  color: white;
}

.ar_updatePasswordButton:focus {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "tajwal-medium";
  color: white;
}

.ar_updatePasswordButton:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  color: white;
}

.ar_cancelButton {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #e6e6e6;
  font-family: "tajwal-bold";
  color: #4d4d4d;
}

.ar_divider_profile {
  width: 110%;
  height: 2px;
  margin-right: -30px;
  color: #b3b3b3;
}

@media (min-width: 992px) {
  .ar_inputField {
    margin-top: 100px;
  }
}

@media (max-width: 992px) {
  .ar_inputField {
    margin-top: 10px;
  }

  .ar_profileInfo {
    display: inline-block;
  }

  .ar_divider_profile {
    width: 114%;
  }
}

@media (max-width: 567px) {
  .ar_divider_profile {
    width: 130%;
  }
}

.ar_setting_profile_editPassword {
  margin-top: 12px;
}

.ar_setting_profile_editPassword_erreurMessage {
  color: #cb3d3d;
}

.ar_setting_profile_editPassword_uploadcancelbutton {
  margin-top: 30px;
}

.ar_setting_profile_editPassword_alert {
  font-size: 12;
}

.ar_setting-profile-editProfile-image {
  text-align: center;
}

.ar_setting-profile-editProfile-avatar {
  display: inline-block;
}

.ar_setting-profile-editProfile-upload {
  display: inline-block;
  width: 100%;
}

.ar_setting-profile-editPassword-input {
  width: 30%;
}

.ar_setting-profileInfo-info {
  margin-top: 10px;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?xbtzsm");
  src: url("./fonts/icomoon.eot?xbtzsm#iefix") format("embedded-opentype"), url("./fonts/icomoon.ttf?xbtzsm") format("truetype"), url("./fonts/icomoon.woff?xbtzsm") format("woff"), url("./fonts/icomoon.svg?xbtzsm#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ar_VacantUnitView_select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  height: 40px;
  border-radius: 4px;
}

.ar_vacantUnitView_TableIcon-style {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  display: inline;
}

.ar_vacantUnitView-datepicker {
  display: flex;
}

.ar_vacantUnitView-dateicon {
  width: 30%;
  display: flex;
  height: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #d9d9d9;
  background-color: #e6e6e6;
}

.ar_invitation-respinsive-style {
  background-color: white;
  border-radius: 5px;
  display: flex;
  padding-top: 5px;
  text-align: center;
}

.ar_vacantUnitView-invitation-style {
  display: flex;
  margin-left: 10px;
}

.icon-chartViewIcon:before {
  content: "\e900";
  color: #999999;
}

.icon-tableViewIcon:before {
  content: "\e901";
  color: #999999;
}

.icon-chartViewIcon:hover {
  content: "\e900";
  cursor: pointer;
}

.icon-tableViewIcon:hover {
  content: "\e901";
  cursor: pointer;
}

.icon-tableView::before {
  content: "\e901";
  color: #000000;
}

.icon-chartView:before {
  content: "\e900";
  color: #000000;
}

.ar_VacantUnitView-BigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
  direction: rtl;
  font-family: "tajwal-Regular";
}

.ar_VacantUnitView-navbar-container {
  width: 100%;
  margin: 0;
  background-color: white;
}

.ar_VacantUnitView-info {
  padding-top: 100px;
  padding-left: 27px;
}

.ar_VacantUnitView-info>* {
  margin-bottom: 20px;
}

.ar_VacantUnitView-info-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_VacantUnitView-info-property {
  background-color: white;
  width: 100%;
}

.ar_propertyInfoView-collection-info {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #459d5a;
}

.ar_propertyInfoView-collection {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_VacantUnitView-bars {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  text-align: center;
  background-color: white;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 5px;
}

.ar_VacantUnitView-bars:hover {
  cursor: pointer;
}

.ar_VacantUnitView-bars-vacantafter {
  background-color: #f5d8d8;
  color: #cb3d3d !important;
}

.ar_VacantUnitView-bars-rentedafter {
  background-color: #dff0e0;
  color: #5fb563 !important;
}

.ar_VacantUnitView-bars-listedafter {
  color: #3b73bf !important;
  background-color: #d3eef7;
}

.ar_test.ar_ant-input-group-addon {
  background-color: #e6e6e6;
}

.ar_VacantUnitView-Invitation {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_VacantUnitView-chart {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
}

.ar_VacantUnitView-SimularUnits {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_VacantUnitView-type {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #b3b3b3;
}

.ar_VacantUnitView-divider {
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid #cccccc;
  padding-top: 23px;
}

.ar_VacantUnitView-SimilarUnit-Number {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin: 0;
  width: fit-content;
  height: 100%;
  background-color: #f7f7f7;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.ar_VacantUnitView-SimilarUnit-text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  padding-right: 5px;
  width: 70%;
  margin-top: 3px;
}

.ar_VacantUnitView-vacant {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  width: 100%;
}

.ar_VacantUnitView-number {
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #cb3d3d;
  padding-left: 4px;
}

.ar_VacantUnitView-days {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3;
  padding-left: 4px;
}

.ar_VacantUnitView-view-button {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.ar_VacantUnitView-view-button:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.ar_VacantUnitView-charts>* {
  margin-bottom: 20px;
}

.ar_VacantUnitView-invitationIcon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  display: block;
}

.ar_VacantUnitView-table-Tilte {
  background-color: #e6e6e6;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #4b4b4b;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 20px;
  padding-left: 20px;
}

.ar_VacantUnitVie-Table-Info {
  background-color: white;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 20px;
  padding-left: 20px;
}

.ar_VacantUnitView-TableView-SimularUnits {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  font-size: 18px;
}

.ar_VacantUnitView-TableView-Number {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_RentedUnitView-name {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  width: 100%;
}

.ar_hideUnit {
  display: none;
}

.ar_propertyInfoView-collection-info_due {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #cb3d3d;
}

.ar_unresolved_images .ant-col:nth-child(odd) {
  padding-left: 5px;
}

.ar_unresolved_images .ant-col:nth-child(even) {
  padding-right: 5px;
}

.ar_ticket_delete_photo {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  cursor: pointer;
}

@media (max-width: 1116px) {
  .ar_VacantUnitView-info {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
  }

  .ar_invitationonly-responsive {
    margin-top: 40px;
  }

  .ar_VacantUnitView-table-Tilte {
    background-color: #e6e6e6;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: #4b4b4b;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .ar_VacantUnitView-TableView-Number {
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: #212121;
  }

  .ar_VacantUnitView-TableView-SimularUnits {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: #808080;
    font-size: 8px;
  }

  .ar_VacantUnitView-SimilarUnit-Number {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    margin: 0;
    width: fit-content;
    height: 100%;
    background-color: #f7f7f7;
    padding: 5px;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: center;
    color: #212121;
  }

  .ar_VacantUnitView-number {
    font-size: 8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: #cb3d3d;
  }
}

.ar_PropertyInfoView-img {
  width: 100%;
  height: 100%;
}

.ar_PropertyInfoView-container {
  width: 100%;
  padding-top: 40px;
  padding-right: 10%;
  padding-bottom: 40px;
}

.ar_propertyInfoView-collection-style {
  margin-left: 40px;
}

.ar_propertyInfoView-collection-info-style {
  width: 50%;
  margin-left: 70px;
}

.ar_propertyInfoView-textNumberUnit {
  font-size: 15px;
  color: #212121;
  font-weight: bold;
  padding-left: 10px;
}

.ar_propertyInfoView-textGreen {
  font-size: 15px;
  color: #5fb563;
  font-weight: bold;
}

.ar_propertyInfoView-directionRight {
  text-align: start;
  padding-left: 20px;
}

.ar_propertyInfoView-textRed {
  font-size: 15px;
  color: #cb3d3d;
  font-weight: bold;
}

.ar_propertyInfoView-colorBar-row {
  padding-left: 20;
  padding-right: 20;
  margin-top: 30px;
}

.ar_propertyInfoView-realcolorBar {
  padding-left: 20;
  padding-right: 20;
}

.ar_rentedUnitChartView-amountdue {
  width: 100%;
  margin-right: 3px;
}

.ar_rentedUnitChartView-view-button {
  padding-right: 15px;
  padding-top: 20px;
}

.ar_vacantUnitView-view-button-style {
  width: 70%;
  padding-right: 20px;
}

.ar_similarUnitChartView-similarimg {
  width: 50%;
  padding-left: 8px;
  display: inline;
}

.ar_vacantUnitView-SimilarUnits-style {
  width: 50%;
  display: inline;
}

.ar_vacantUnitView-divider-style {
  padding-top: 16px;
}

.ar_VacantUnitView-unit-style {
  padding-top: 15px;
}

.ar_vacantUnitView-viewbutton-style {
  padding-right: 20px;
}

.ar_unitChartView-viewbutton-style {
  padding-right: 20px;
  padding-top: 18px;
}

.ar_vacantSimilarUnitTableView-viewbuton {
  width: 50%;
}

.ar_VacantUnitView-info-stylee {
  padding-top: 88px;
}

.ar_vacantUnitView-vacantinfo {
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #212121;
}

.ar_vacantUnitView-sarch {
  padding-top: 53px;
  padding-bottom: 20px;
}

.ar_vacantUnitView-info-tablestyle {
  padding-top: 53px;
  padding-bottom: 20px;
}

@font-face {
  font-family: "Tajawal Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Tajawal Regular"), url("./fonts/Tajwal/Tajawal-Regular.woff") format("woff");
}

@font-face {
  font-family: "Tajawal Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Tajawal Bold"), url("./fonts/Tajwal/Tajawal-Bold.woff") format("woff");
}

/********************Accounting************************************/

/********************Accounting Rentals****************/

.ar_Container-Render {
  background: #f7f7f7;
  font-family: "Tajawal Bold";
}

.ar_Render-header div div {
  text-align: center;
  height: 94px;
  padding-top: 1%;
  font-family: "Tajawal Bold";
  font-size: 17px;
  padding-top: 15px;
}

.ar_Render-header div:nth-child(1) {
  background: #808080;
  color: white;
}

.ar_Render-header div:nth-child(2) {
  background: #dff0e0;
  color: #5fb563e0;
}

.ar_AccRenderStatus {
  font-family: "Tajawal Bold";
  font-size: 24px;
}

.ar_Render-header div:nth-child(3) {
  background: #f5d8d8;
  color: #cb3d3d;
}

.ar_Render-input {
  margin-bottom: -4% !important;
  margin-top: 2% !important;
  font-family: "Tajawal Bold" !important;
}

.ar_Render-input .Render-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.ar_export {
  background: #666666 !important;
  width: 120px;
}

/***************Input*****/

.ar_mymodula .ant-form-horizontal .ant-form-item {
  width: 395px !important;
}

#ar_metho-pay-select {
  width: 106px;
  height: 34px;
}

.ar_Rental-inputPaym {
  width: 100%;
  position: relative;
}

.ar_Rental-input-img {
  top: 38px;
  left: 13px;
  position: absolute;
}

.ar_advancedPay_amountpayed {
  font-size: 18px;
  line-height: 19px;
  color: #5fb563;
}

/************Date Input**********************/

.ar_input-rental {
  width: 162px;
  height: 38px;
  position: relative;
  margin-bottom: 71px;
  margin-right: 17px;
}

.ar_ind3 {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.ar_input-rental img {
  position: absolute;
  right: 0;
}

/**********input rentel Right**/

.ar_rental-inpit-right {
  position: absolute;
  margin-bottom: -4% !important;
  height: 40px;
  overflow: hidden !important;
  left: 0;
  font-family: "Tajawal Regular";
}

.ar_rental-inpit-right div {
  position: relative;
  display: flex;
  width: 123px;
}

.ar_rental-inpit-right div .ant-select-selection-item {
  padding-right: 18px;
}

.ar_rental-inpit-right div img {
  position: absolute;
  left: 11px;
  top: 7px;
}

.ar_input-filter .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 37px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ar_rental-inpit-right .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 3%;
  background-color: #666666;
  color: white;
  height: 38px;
  border-radius: 5px;
}

.ar_rental-inpit-right .ant-select-show-arrow {
  position: absolute;
  color: white;
  width: 134px;
  height: 100%;
}

.ar_rental-inpit-right #ar_ico-select {
  position: absolute;
  top: 20%;
  right: 62%;
}

@media all and (max-device-width: 575px) {
  .ar_mymodula .ant-form-horizontal .ant-form-item {
    max-width: 80% !important;
  }
}

@media all and (max-width: 575px) {
  .ar_btn-submit-rental {
    width: 111%;
    margin-left: -36%;
  }

  .ar_btn-cancel {
    margin-left: 9%;
  }

  .ar_date-pay {
    width: 66% !important;
  }

  .ar_ant-upload-textt {
    width: 245px;
  }
}

/**************************************/

.ar_input-filter {
  width: 100%;
  height: 38px;
  position: relative;
  margin-bottom: 4%;
}

.ar_input-filter .ant-select-show-arrow,
.ar_input-filter .ant-select-show-arrow .ant-select-selector {
  position: absolute;
  height: 100%;
  width: 100%;
}

/*************************************************************/

.ar_input-filter {
  width: 100%;
  height: 38px;
  position: relative;
}

.ar_input-filter .ar_ind {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.ar_Rental-filter1 {
  margin-top: 26px;
  direction: rtl;
  margin-bottom: 63px;
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 14px;
}

.ar_ind3 div {
  padding: 2px 20px !important;
}

.ar_classNameOfColumn {
  vertical-align: top;
  color: #8c8b8b9c !important;
  text-align: right !important;
  font-family: "Tajawal Regular";
  font-size: 14px;
  font-weight: 700 !important;
}

.ar_classNameOfStatus {
  border: none;
  background: white !important;
}

.ar_table-acounting-rental .ant-table-container table>thead>tr:first-child th {
  background-color: #d9d9d9;
}

.ar_table-acounting-rental .ant-table-tbody>tr>td {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.ar_mymodula2-child-rent_amount-metho {
  display: flex;
  justify-content: space-between;
  padding-left: 9%;
  padding-right: 9%;
}

.ar_payment-method {
  display: flex;
  justify-content: space-between;
  width: 104px;
}

.ar_payment-method img {
  height: 22px;
}

.ar_rent_amount {
  font-weight: bolder;
  font-size: 15px;
}

.ar_input-filter img {
  position: absolute;
  left: 0;
}

.ar_mymodula .ant-form-item-label>label::after {
  content: "";
}

.ar_acount-rentl-img-select {
  position: absolute !important;
  top: 15% !important;
  left: 7px !important;
}

.ar_acount-invoices-img-select {
  position: absolute !important;
  top: 15% !important;
  left: 7px !important;
}

.ar_table-acounting-rental {
  margin-top: -40px;
  direction: rtl !important;
}

/*****************Rentals payment****************/

/**************Modular**********/

.ar_none {
  display: none;
}

.ar_show-more {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(47, 47, 47, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  left: -33px;
}

.ar_card-containerTitel {
  font-size: 18px;
  margin-right: 5%;
}

.ar_mymodula {
  top: 27px;
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 3px;
  padding-top: 2%;
  padding-right: 45px;
  max-width: 367px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

@media (min-width: 1367px) {
  .ar_mymodula {
    top: 7%;
  }
}

/**********************************************************/

.ar_close-mymodula {
  color: #ffffff;
  background: #020202;
  position: absolute;
  top: -13px;
  font-size: 19px;
  right: -13.5px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/***Form date input*/

.ar_mymodula .ant-form-horizontal .ant-form-item {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 12px !important;
}

.ar_show-more .ar_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-label>label {
  font-family: "Tajawal Regular";
  margin-bottom: 4%;
}

.ar_mymodula .ant-form-horizontal .ant-row .clearfix .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
  width: 81%;
  height: 109px;
  margin-left: 59px;
  margin-top: 5%;
}

.ar_mymodula .ant-form-horizontal .ant-form-item {
  margin-bottom: 8px !important;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
  border: none;
}

.ar_date-rentl-pay {
  width: 222px;
  height: 38px;
}

.ar_cancel {
  font-size: 13px;
  cursor: pointer;
}

.ar_submit {
  font-size: 15px;
  font-weight: 700;
}

.ar_or {
  color: #212121;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px;
}

.ar_drag {
  color: #b3b3b3;
}

.ar_Rental-inputPaym,
.ar_date-rentl-pay {
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 14px;
}

.ar_Rental-inputPaym1 {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px;
  color: #212121;
}

/************Statue Data*****/

.ar_Over {
  color: #cb3d3d !important;
}

.ar_mymodula Form .ant-input-search-icon {
  font-family: "Roboto Slab", serif !important;
}

.ar_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
  width: 393px;
}

.ar_btn-submit-rental {
  position: relative;
  font-family: "Roboto", sans-serif;
  width: 98%;
  height: 50px;
}

.ar_HrentalDelete2 {
  text-align: right;
  font-size: 16px;
  padding-left: 170px;
  background-color: #f3f3f3cc;
  padding-top: 40px;
  cursor: pointer;
  position: absolute;
  bottom: -485px;
  direction: rtl;
  right: 0;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !;
  width: 100%;
  padding-right: 174px;
  padding-bottom: 50px;
  color: #666666;
}

.ar_btn-cancel {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px;
  width: 100%;
  height: 50px;
  text-align: center;
  margin-left: -10%;
  margin-top: 6%;
  cursor: pointer;
  color: #666666;
}

#ar_btn-s-modl {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 13px;
}

#ar_btn-s-modl span {
  font-size: 17px;
}

.ar_container-btn-action-rental {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #dde3e9;
}

.ar_untViewP {
  position: relative;
}

.ar_btn-action-rental,
.ar_btn-action-rental:hover,
.ar_btn-action-rental:focus {
  width: 130px;
  height: 40px;
  font-family: "tajwal-bold";
  font-size: 14px;
  color: #1d456d;
  border-radius: 4px;
  background-color: #dde3e9;
  border: none;
}

.ar_icon-input {
  cursor: pointer;
}

.ar_date-pay {
  display: flex;
  width: 100%;
  margin-bottom: 4%;
}

/************Receipt View*************************/

.ar_mymodula2 {
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 3px;
  padding-top: 2%;
  padding-left: 0;
  max-width: 459px;
  width: 100%;
  height: 419px;
  background: #ffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.ar_mymodula2-child {
  display: flex;
  justify-content: space-between;
  padding-left: 41px;
  padding-right: 41px;
  position: relative;
}

.ar_mymodula2-child-rent_amount-metho {
  border: #cccccc solid 1px;
  height: 113px;
  display: flex;
  margin-left: 9%;
  margin-right: 9%;
  padding-top: 5%;
}

.ar_mymodula2-child img {
  position: absolute;
  left: 7%;
}

.ar_mymodula2-child-amount-metho div div {
  display: flex;
}

.ar_mymodula2-child-amount-metho div div img {
  width: 30px;
  height: 19px;
  margin-left: 10px;
}

.ar_btn-export,
.ar_btn-print {
  position: relative;
  width: 130px;
  height: 40px;
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;
  margin-top: 5%;
  right: 9%;
}

.ar_btn-export p,
.ar_btn-export img {
  position: absolute;
  left: 0;
}

.ar_btn-print p {
  z-index: 2;
  margin-left: 21%;
  margin-top: 7%;
  font-family: "Roboto Condensed", sans-serif;
}

.ar_btn-print p,
.ar_btn-print img {
  position: absolute;
  right: 0;
}

.ar_btn-export p {
  margin-top: 6%;
  margin-left: 25%;
  font-weight: 700;
  color: white;
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
}

.ar_btn-print p {
  margin-right: 37%;
  font-weight: 700;
  color: rgb(22, 22, 22);
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
}

.ar_amount {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 20px;
}

.ar_mymodula2-child div p {
  font-weight: 500;
}

.ar_mymodula2-child-amount-metho div p {
  font-family: "Source Sans Pro", sans-serif;
}

.ar_cash {
  font-size: 15px;
}

.ar_pdf {
  font-weight: 500 !important;
  font-size: 15px !important;
}

.ar_amm-pid {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  margin-bottom: 9%;
  font-size: 19px;
}

.ar_mymodula2-child div p span {
  color: #000;
  font-size: 17px;
}

.ar_rec-inv {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  margin-left: 41px;
}

.ar_mymodula2-child div h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.ar_rec-in-child p {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  margin-right: 8%;
}

.ar_rec-in-child .ar_rec-inv {
  margin-left: 2%;
}

/******************** Unit Rented View ****************/

.ar_Hrental-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fbfbfb;
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 18px;
  color: #808080;
}

.ar_Hrental-info>div {
  background-color: #ffffff;
  width: 49%;
  margin-bottom: 9px;
  padding-right: 12%;
  padding-top: 2%;
  padding-bottom: 7%;
  position: relative;
}

/*********************************************************************/

.ar_Hrental-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px #f7f7f7 solid;
  width: 323px;
}

.ar_Hrental-title:last-child {
  border-bottom: none;
}

.ar_Hrental-title h3 {
  font-size: 18px;
  font-family: "Tajawal Regular";
  font-weight: normal;
  color: #808080;
  margin-top: 2%;
}

.ar_Hrental-title p {
  margin-top: 2%;
  cursor: pointer;
  color: #27aad8;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 16px;
}

.ar_Hunit-name {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 3%;
}

.ar_HUnit-type {
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  margin-top: 1%;
}

.ar_Hfloor {
  font-size: 14px;
  color: #808080;
  font-weight: 600;
  margin-top: 2%;
}

.ar_Hroom-rental {
  display: flex;
  flex-flow: row;
  width: 50%;
  margin-top: 3%;
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #b3b3b3;
}

.ar_Hroom-rental-p {
  padding-left: 16px;
}

.ar_Hroom-rental-p2 {
  padding: 0px 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .ar_Hroom-rental {
    width: 70%;
  }
}

@media only screen and (max-width: 576px) {
  .ar_Hroom-rental-p {
    padding-left: 8px;
  }

  .ar_Hroom-rental-p2 {
    padding: 0px 8px;
  }
}

.ar_Hrental p {
  font-size: 15px;
  color: #808080;
  font-weight: 600;
}

.ar_Hroom-rental2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 41%;
  position: absolute;
}

.ar_Hroom-rental2 p {
  width: 50%;
  color: #808080;
  font-weight: 600;
  font-size: 16px;
  font-family: "Tajawal Regular";
  font-weight: normal;
}

.ar_Hroom-rental2 p img {
  margin-left: 5%;
  width: 21px;
}

.ar_Hroom-rental p {
  font-size: 16px !important;
}

.ar_Hroom-rental p span {
  font-family: "tajwal-medium" !important;
  font-weight: normal !important;
  font-size: 14px;
}

/*********************************************************************/

.ar_Hrental-list-unit .ar_bn-list-rent {
  cursor: pointer;
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(59, 115, 191, 0.15);
  text-align: center;
  padding-top: 2%;
  margin-left: 6%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  margin-top: 150px;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 16px;
  color: #4e80c5;
}

.ar_Hrental-list-unit p {
  margin-top: 5%;
  width: 341px;
  height: 19px;
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3ba;
}

/*********************************************************************/

.ar_Hrental-info-child:first-child {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 254px;
  margin-top: 33px;
}

.ar_Hrental-info-child:nth-child(1) h3 {
  margin-top: 21px;
  font-size: 20ppx;
  font-weight: 600;
  font-size: 19px;
  margin-left: -102px !important;
}

.ar_Hrental-info-child2 {
  display: flex;
  justify-content: space-between;
  width: 391px;
  margin-top: 4%;
  margin-left: 2%;
  font-weight: 600;
  color: #a2a2a2;
  font-size: 16px;
}

/************************************/

.ar_Hrental-info-ss-left p {
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 18px;
  color: #808080;
}

.ar_Hrental-info-ss-right p {
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  height: 22px;
}

.ar_Hrental-info-pdf {
  text-decoration: underline;
}

/**********************************************************/

.ar_Hrental-info-child3 {
  display: flex;
  justify-content: space-between;
  width: 283px;
}

.ar_Hrental-info-child3 .ar_btn-f-block {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border: solid 2px #cccccc;
  text-align: center;
  padding-top: 3%;
  color: #666666;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.ar_g-period {
  position: relative;
  left: -82px;
}

.ar_g-period-title {
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}

.ar_block_period_plus_sous img {
  width: 24px;
  height: 24px;
  z-index: 99;
  cursor: pointer;
}

.ar_block_period_plus_sous {
  display: flex;
  margin-top: 10%;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 126px;
}

/***************************************************************/

.ar_Hrental-amout {
  font-size: 17px;
  color: #212121;
  margin-top: 54px;
  font-family: "Tajawal Regular";
  font-weight: normal;
}

.ar_Hrental-amout span {
  color: #5fb563c7;
  font-weight: 600;
  font-size: 19px;
}

/******************************************/

.ar_Hrental-btn-Amountdue {
  width: 147px;
  height: 50px;
  background-color: #f8f8f8;
  margin-bottom: 10%;
  text-align: center;
  padding-top: 1%;
}

.ar_amout-h-nbr {
  font-size: 24px;
  font-weight: bold;
  color: #cb3d3d;
}

.ar_btn-f-Renew-contract {
  display: flex;
  justify-content: space-between;
  width: 323px;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 14px;
  color: #4d4d4d !important;
}

.ar_btn-f-Renew {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  text-align: center;
  padding-top: 2%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.ar_btn-f-Contract {
  cursor: pointer;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border: solid 2px #cccccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  text-align: center;
  padding-top: 2%;
}

/***************Transation*************************/

.ar_Hrental-Transation {
  position: absolute;
  left: 149px;
  /* margin-left: 168px; */
  width: 77%;
}

.ar_Hrental-Transation-first-child {
  background-color: #ffffff;
  margin-bottom: 9px;
  padding-top: 2%;
}

.ar_Hrental-title2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ar_Hrental-title2 h3 {
  font-family: "Tajawal Regular";
  font-weight: normal;
  font-size: 24px;
  color: #808080;
  margin-top: 2%;
}

.ar_btn-unit-rental-receip {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #dde3e9;
  text-align: center;
  padding-top: 3%;
  font-weight: 700;
  font-size: 15px;
  color: #1d456dad;
}

.ar_Hrental-right-export {
  cursor: pointer;
  position: relative;
  height: 40px !important;
  border-radius: 5px !important;
  padding-left: 2%;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !;
  font-size: 16px;
  text-align: center;
  display: flex;
}

.ar_Hrental-right-export img {
  top: 8px;
  left: 35px;
  position: absolute;
  z-index: 999;
}

.ar_Hexport-select-unit-rent .ant-select-selector {
  background-color: #666666 !important;
  width: 120px !important;
  height: 40px !important;
  border: none !important;
  color: #ffffff;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  padding-left: 26px !important;
  font-size: 15px !important;
}

.ar_btn-unit-rental-receip {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #dde3e9;
}

.ar_Hrental-info-child:first-child img {
  width: 86px;
  height: 86px;
  overflow: hidden;
  border-radius: 47px;
}

.ar_mybardaate>div {
  width: 323px;
  background-image: linear-gradient(to right, rgba(198, 40, 40, 0.9) 35%, rgba(78, 173, 83, 0.9) 86%);
  border-radius: 5px;
}

.ar_mybardaate_child {
  display: flex;
  justify-content: space-between;
  width: 323px;
  color: #b3b3b3;
  margin-top: 2%;
  flex-direction: row-reverse;
}

.ar_mybardaate_container {
  margin-top: 10%;
}

.ar_bord {
  border-bottom: 2px #f7f7f7 solid;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 20px;
  left: 0;
}

.ar_Hrental-Transation {
  display: none;
}

.ar_Hrental-right-export>div>div {
  padding-top: 5px !important;
}

.ar_RentedViw_table-transaction {
  width: 100%;
}

.ar_Hrental_uniRented {
  direction: rtl;
}

.ar_Hrental-info:nth-child(2)>div:nth-child(1) {
  order: 2;
}

.ar_Hrental-info:nth-child(2)>div:nth-child(1) {
  order: 1;
}

.ar_Rental-filter1_container {
  display: flex;
  justify-content: space-evenly;
}

@media (min-width: 768px) {
  .ar_rental-input-con {
    position: relative;
  }

  .ar_rental-input-col:nth-child(2) {
    position: absolute;
    left: 0;
  }

  .ar_rental-input-col:nth-child(1) {
    right: 0;
  }
}

@media (max-width: 768px) {
  .ar_rental-inpit-right {
    right: 0;
    margin-bottom: 22px;
    margin-top: 13px;
  }

  .ar_Rental-filter1 {
    margin-top: 23px;
    margin-bottom: 77px;
  }
}

.ar_totalContainerPayment {
  margin-bottom: 5%;
  height: 72px;
  padding: 19px;
  width: 310px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
}

.ar_editProfileCancelButton {
  color: #808080;
  margin-right: 10px;
}

.ar_editProfileCancelButton:hover {
  color: #808080;
}

.ar_editProfileCancelButton:focus {
  color: #808080;
}

.ar_displaySaveCancel {
  display: none
}

.ar_editProfileSaveCol {
  display: block;
  position: absolute;
  left: 55px;
  top: 25px;
}

.ar_viewContract_btn_accept {
  margin-top: 34px;
  color: white;
  background-color: #27aad8;
  height: 40px;
  width: 130px;
  border-radius: 4px;
  border: 1px solid #27aad8;
  font-size: 14px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_viewContract_btn_reject {
  margin-top: 34px;
  color: #212121;
  background-color: #e6e6e6;
  height: 40px;
  width: 130px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_viewContract_btn_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 567px) {
  .ar_rental-inpit-right .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 1%;
  }

  .ar_input-filter {
    margin-bottom: 27px;
  }

  .ar_ProfilEdit {
    display: none;
  }

  .phoneNumberCol {
    order: 0
  }

  .civilIdCol {
    order: 3;
    margin: 0px;
    padding-top: 5px;
  }

  .phoneNumberInputCol {
    order: 1
  }

  .civilIdInputCol {
    order: 4;
  }

  .ar_ProfilEdit {
    display: none;
  }

  .ar_totalContainerPayment {
    margin-bottom: 15%;
  }

  .ar_saveeditButton {
    font-size: 16px;
    color: #27aad8;
    line-height: 23px;
    font-family: "tajwal-bold";
    padding: 0px;
    display: flex;
    align-items: flex-start;
  }

  .ar_editProfileCancelButton {
    color: #808080;
    margin-top: -5px;
    margin-right: 0;
  }

  .ar_editProfileSaveCol {
    display: none;
  }

  .ar_displaySaveCancel {
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
  }

  .ar_editProfileSaveCol {
    display: none;
  }

  .ar_EditPhoneContainer {
    display: flex;
    flex-direction: column;
    order: 2 !important
  }

  .ar_connection_tenant_card {
    width: 100vw !important;
  }

  .ar_viewContract_btn_container {
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }

  .ar_viewContract_btn_accept {
    margin-left: 30px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {

  /* For portrait layouts only */
  .ar_rental-input-col:nth-child(2) {
    position: relative;
  }

  .ar_rental-inpit-right {
    margin-top: 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
}

.ar_ind2 {
  height: 38px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ar_ant-upload-textt {
  width: 245px;
  font-family: "Tajawal Regular" !important;
  font-weight: normal;
  font-size: 14px;
  color: #27aad8 !important;
}

.ar_mymodulaRentedView {
  z-index: 999999;
  width: 315px;
  height: 149px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
}

.ar_btnEndContP {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  cursor: pointer;
}

.ar_btnendModulCi {
  margin-left: 5px;
  width: 108px;
  height: 32px;
  border-radius: 4px;
  background-color: #27aad8;
  color: #ffffff;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.ar_btnendModuldel {
  width: 108px;
  height: 32px;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #cb3d3d;
}

.ar_btnCancelContract {
  margin-right: 5px;
  width: 108px;
  height: 32px;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #4d4d4d;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.ar_delun {
  color: #666666;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  font-size: 16px;
}

.ar_addProp_Map {
  position: relative;
  height: 400px;
  overflow: hidden;
  margin-left: 15%;
}

.ant-upload-list-item-name {
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flex;
}

.ar_property_anim_image {
  position: relative;
  top: -1px;
}

.ar_add_property_Input_container {
  width: 250px;
  margin-left: 10%;
}

.ar_advancedPay_incrImage {
  display: inline-block;
  cursor: pointer;
}

.ar_advancedPay_modal_body>* {
  margin-top: 20px;
  font-family: "Tajawal";
}

.ar_advancedPay_tittle_mounth {
  font-family: "tajwal-medium";
  color: #212121;
  line-height: 19px;
  font-size: 14px;
}

.ar_advancedPay_amount {
  font-size: 18;
  line-height: 19px;
  color: #212121;
}

.ar_advancedPay_monthnumber {
  color: #212121;
  border: none;
  width: 20px;
  padding: 0;
  display: inline-block;
  margin: 0px 33px;
  text-align: center;
}

.ar_advancedPay_rows_spacebetween {
  display: flex;
  justify-content: space-between;
  direction: rtl;
}

.ar_vacantUnitView-Addbutton {
  position: absolute;
  left: 0;
}

/********************************/

.ar_vacantUnitView-Addbutton:hover {
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  background-color: #fff !important;
}

.ar_changePassordButton:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.ar_cancelButton:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.ar_cancelButton:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

/*******************************************/

/*Register : Password fields ***/

.ar_CPUpper_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPUpper_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPLower_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPLower_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPLower_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPLower_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPNumber_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPNumber_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPMinCar_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPMinCar_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPSpecialChar_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "tajwal-medium";
}

.ar_CPSpecialChar_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "tajwal-medium";
}

/*----Stay tuned---*/

.ar_stay_tuned_container {
  direction: rtl;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.ar_stay_tuned {
  font-size: 24px;
  font-family: "tajwal-bold";
  color: #212121;
  margin-bottom: 10px;
}

.ar_stay_tunedTxt {
  font-size: 18px;
  font-family: "tajwal-regular";
  color: #808080;
}

.ar_settingUser_title {
  font-size: 24px;
  color: #212121;
  line-height: 23px;
  margin-bottom: 40px;
}

.ar_User-invite-buttonn.ant-btn-primary {
  background-color: #27aad8;
  border-color: #27aad8;
}

.ar_invite_button.ant-btn-primary {
  background-color: #27aad8;
  border-color: #27aad8;
}

/***************PageNotFound**********************/

.ar_btnReturn {
  width: 200px;
  height: 36px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  margin-top: 30px;
  border-color: #27aad8;
  box-shadow: none;
  border-width: 0px !important;
}

.ar_containerPageNotFound {
  direction: rtl;
}

.ar_img-date-ico1 {
  position: absolute;
  left: 61px;
  top: 35px;
}

.ar_input_contain {
  position: relative;
}

.ar_Rental-input-select {
  position: absolute;
  left: 66px;
  top: 42px;
}

.ar_Rental-inputPaym1 {
  height: 39px;
  width: 258px;
  border: 1px #d9d9d9 solid;
  margin-top: 2%;
  padding-right: 4%;
  padding-top: 8px;
}

.ar_Rental-inputPaym1 span:last-child {
  color: #b3b3b3;
  margin-right: 3px;
}

.ar_RentalPaymCont {
  margin-top: 7%;
}

.ar_RentalPaymlabel {
  color: #212121;
  font-family: "Tajawal Regular";
}

.ar_date-rentl-pay {
  width: 222px;
  height: 39px;
}

.ar_img-date-ico1 {
  position: absolute;
  left: 61px;
  top: 35px;
}

/*********************Select language *********************/

.ar_img-date-ico1 {
  position: absolute;
  left: 61px;
  top: 35px;
}

.ar_Hrental-CommingParnt {
  width: 661px;
  background-color: rgb(255, 255, 255, 0.5);
  position: absolute;
}

.ar_Hrental-Comming {
  font-size: 21px;
  position: absolute;
  margin-top: 29px;
  font-family: "Tajawal Bold" !important;
  font-weight: normal !important;
  color: #808080;
  left: 78%;
}

/******************Payment**************************/

.ar_PayAdvance {
  background-color: #e6e6e6;
  border-radius: 5px;
  height: 40px;
  width: 130px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  float: left;
  margin-left: 3%;
  font-family: "tajwal-bold";
}

.ar_PayAdvance:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.65);
}

.ar_PayAdvance:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.65);
}

.ar_paymentTotal {
  font-size: 24px;
  color: #808080;
  line-height: 2px;
  font-weight: normal;
  margin-left: 40px;
  font-family: 'tajwal-medium';
}

.ar_PaymentContainer {
  display: flex;
  align-items: baseline;
  width: 89%;
  margin-right: 11%;
}

.ar_Total {
  font-size: 24px;
  color: #5fb563;
  margin-right: 70px;
  margin: auto;
  white-space: nowrap;
  font-size: 24px;
  font-family: "tajwal-bold";
}

.ar_TotalEmpty {
  font-size: 24px;
  color: #808080;
  font-size: 24px;
  font-family: "tajwal-bold";
  margin-left: 50px;
}

.ar_PaymentTable {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(7, 1fr);
  background-color: #fff;
  align-items: center;
  margin-right: 15px;
}

.ar_PaymentTable span {
  font-size: 16px;
}

.ar_PayButton button {
  background-color: #5fb563;
  color: #fff;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  align-self: center;
  border-color: #5fb563;
}

.ar_PayButton {
  background-color: #5fb563;
  color: #fff;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  align-self: center;
  border-color: #5fb563;
}

.ar_PayButton:hover {
  background-color: #5fb563;
  color: #fff;
  border-color: #5fb563;
  align-self: center;
  border-color: #5fb563;
}

.ar_PayButton:focus {
  background-color: #5fb563;
  color: #fff;
  border-color: #5fb563;
}

.Rectangle-687 {
  padding: 20px;
}

@media only screen and (max-width: 992px) {
  .ar_table {
    width: 1024px;
  }

  .ar_tenantPaymentHistoryContainer {
    overflow: scroll;
  }

  .ar_Total {
    margin-right: 20px !important;
  }

  .ar_TotalEmpty {
    margin-right: 20px !important;
  }

  .ar_paymentTotal {
    margin-left: 20px !important;
  }

  .Rectangle-687 {
    margin-top: 10px;
  }

  .ar_transfers-table {
    padding-left: 20px;
  }

  .ar_styleButtonCancelUikitModalPayment {
    width: -webkit-fill-available !important;
    margin-right: 0px !important;
    margin-bottom: 12px;
  }

  .ar_styleButtonPayNowUikitModalPayment {
    width: -webkit-fill-available !important;
  }
}

.ar_table {
  margin: 0 12%;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
  font-family: "tajwal-regular";
}

.ar_textColor {
  font-family: "tajwal-regular";
  margin-top: 10px;
  font-size: 18px !important;
  color: #212121;
}

.ar_payment_amount {
  font-family: "tajwal-regular";
  color: #5FB563;
  font-size: 18px !important;
  margin-top: 10px;
}

/* .textColor {
  font-family: "tajwal-regular";
  margin-top: 10px;
} */

.ar_payment-btn-container {
  margin-bottom: 30px;
  margin-top: 45px;
  /* margin-right: 12%; */
  font-family: "tajwal-regular";
}

/*-------Receip----------*/

.ar_TeantReceipView .ant-modal-body {
  width: 520px;
  /* max-height: 750px; */
  padding: 40px 0px 30px;
  direction: rtl;
}


.ar_TeantReceipViewFailed .ant-modal-body {
  width: 520px;
  padding: 40px 0px 30px;
}

.ar_TeantReceipView_logo {
  float: left;
  margin-left: 40px;
  max-width: 90%;
  margin-top: 6px;
}

.ar_TeantReceipView_title {
  margin-bottom: 0px;
  float: right;
  font-family: "tajwal-bold";
  font-size: 24px;
  margin-right: 40px;
}

.ar_TeantReceipView_body {
  margin-bottom: 40px;
  margin-left: 55px;
  margin-right: 55px;
}

.ar_TeantReceipView_body2 {
  margin: 18px 40px 0px;
}

.ar_TeantReceipView_date {
  color: #b3b3b3;
  font-family: "tajwal-regular";
  font-size: 16px;
  margin-top: -5px;
}

.ar_TeantReceipView_receip {
  color: #808080;
  font-family: "tajwal-regular";
  font-size: 18px;
  /* margin-bottom: 5px; */
}

.ar_TeantReceipView_receipNumber {
  color: #b3b3b3;
  font-family: "tajwal-regular";
  font-size: 18px;
}

.ar_TeantReceipView_receipL {
  float: right;
}

.ar_TeantReceipView_receipR {
  float: left;
}

.ar_TeantReceipView_landlord {
  font-family: "tajwal-bold";
  font-size: 18px;
  color: #212121;
}

.ar_TeantReceipView_moneyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.ar_TeantReceipView_moneyContainerDiv {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.ar_TeantReceipView_receipAmount {
  font-family: "tajwal-bold";
  font-size: 18px;
  color: #5fb563;
  float: left;
  display: flex;
  justify-content: flex-end;
}

.ar_TeantReceipView_cash {
  font-family: "tajwal-regular";
  font-size: 16px;
  color: #212121;
  float: left;
  display: flex;
  justify-content: flex-end;
}

.ar_TeantReceipView_cashLogo {
  margin-right: 6px;
}

.ar_TeantReceipView_btnModal {
  margin-top: 32px;
  color: white;
  font-size: 14px;
  font-family: "tajwal-bold";
  background-color: #27aad8;
  border: 1px solid #27aad8;
  border-radius: 4px;
  height: 40px;
  width: 130px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
}

/***************PageNotFound**********************/

.ar_notFoundPageTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Tajawal";
}

.ar_btnReturn {
  width: 200px;
  height: 36px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  margin-top: 30px;
  border-color: #27aad8;
  box-shadow: none;
  border-width: 0px !important;
}

.ar_containerPageNotFound {
  direction: rtl;
}

/*-------Tenant-Link---------*/

.ar_tenantNumber_container {
  display: flex;
  background-color: #36d1e0;
  justify-content: center;
  min-height: 100vh;
}

.ar_tenantNumber_backLogo {
  position: fixed;
  bottom: 0;
}

.ar_tenantNumber_numberContainer {
  width: 445px;
  height: 367px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}

.ar_tenant_pwd {
  width: 445px;
  height: 500px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}

.ar_tenantNumber_logoWhite {
  position: fixed;
  right: 5%;
  top: 2%;
}

.ar_tenantNumber_littleLogo {
  float: left;
  margin-top: 40px;
  margin-left: 40px;
}

.ar_tenantNumber_cardTitle {
  float: right;
  margin: 40px 40px 40px 0px;
  color: #212121;
  font-size: 24px;
  font-family: 'tajwal-bold';
}

.ar_tenantNumber_label {
  font-size: 16px;
  margin: 137px 0px 24px;
}

.ar_tenantNumber_inputContainer {
  padding: 0px 40px;
}

.ar_tenantNumber_inputNumber {
  height: 40px;
}

.ar_tenantNumber_btn {
  padding: 45px 40px 40px;
}

@media (max-width: 413px) {
  .ar_tenantNumber_numberContainer {
    width: 100%;
  }
}

.ar_notFoundPageTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Tajawal";
}

.ar_btnReturn {
  width: 255px;
  height: 48px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  border-color: #27aad8;
  box-shadow: none;
  font-family: 'tajwal-bold';
  font-size: 16px;
  margin-top: 30px;
}

.ar_containerPageNotFound {
  direction: rtl;
}

/*-------Tenant-Link---------*/

.ar_tenantNumber_container {
  display: flex;
  background-color: #36d1e0;
  justify-content: center;
  min-height: 100vh;
}

.ar_tenantNumber_backLogo {
  position: fixed;
  bottom: 0;
}

.ar_tenantNumber_numberContainer {
  width: 445px;
  height: 367px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}

.ar_tenantNumber_logoWhite {
  position: fixed;
  right: 5%;
  top: 2%;
}

.ar_tenantNumber_littleLogo {
  float: left;
  margin-top: 40px;
}

.ar_tenantNumber_cardTitle {
  float: right;
  margin: 40px 40px 40px 0px;
  color: #212121;
  font-size: 24px;
}

.ar_tenantNumber_label {
  font-size: 16px;
  margin: 137px 0px 24px;
  font-family: 'tajawal';
}

.ar_tenantNumber_inputContainer {
  padding: 0px 40px;
}

.ar_tenantNumber_inputNumber {
  height: 40px;
}

.ar_tenantNumber_btn {
  padding: 45px 40px 40px;
  font-family: "tajwal-bold";
}

@media (max-width: 413px) {
  .ar_tenantNumber_numberContainer {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .ar_tenantNumber_cardTitle {
    margin: 40px 20px 40px 0px;
  }

  .ar_tenantNumber_label {
    font-size: 15px;
  }
}

/*********************Tenant history*******************/

.ar_historyTenant_btn {
  height: 40px;
  width: 100%;
  width: 130px;
  border-radius: 4px;
  background-color: #dde3e9;
  border: 1px solid #dde3e9;
  color: #1d456d;
  font-size: 14px;
  font-family: "tajwal-bold";
  text-align: center;
  cursor: pointer;
}

.ar_historyTenant_unitNum {
  color: #b3b3b3;
}

/******************Tenant smsVerify**********************/

/*****************Tenant psw******************/

.ar_pswTenantLogoT {
  float: left;
  margin-left: 40px;
}

.ar_pswTenantGreat {
  height: 16.5px;
  font-family: tajwal-bold;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  margin-right: 40px;
}

.ar_pswTenantCreatepsw {
  margin-left: 9%;
  height: 11px;
  font-family: tajwal-regular;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  padding-top: 10px;
  margin-right: 40px;
}

.ar_pswTenant_label {
  font-family: "tajwal-bold";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.inputPassword_labelForm__X3g-x {
  font-family: 'tajwal-regular';
  font-size: 16px;
}

.ar_cnxTenantEnterPassword {
  margin-right: 40px;
  height: 11px;
  font-family: 'tajwal-regular';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  padding-top: 30px;
}

.ar_cnxTenantForgotPassword {
  height: 9.5px;
  font-family: 'tajwal-regular';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
  margin-top: 30px;
}

/*************************Modal uikit payment******************/

.ar_propertyNameUikitModalPayment {
  width: 63px;
  height: 21px;
  font-family: "tajwal-regular";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  margin-top: 32px;
}

.ar_propertyValueUikitModalPayment {
  width: 125px;
  height: 21px;
  font-family: "tajwal-regular";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #808080;
  margin-top: 32px;
}

.ar_styleButtonCancelUikitModalPayment {
  font-family: "tajwal-bold";
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #212121;
  margin-right: 65px;
  margin-top: 7px;
}

.ar_styleButtonCancelUikitModalPayment:hover {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  margin-top: 7px;
}

.ar_styleButtonCancelUikitModalPayment:focus {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  margin-top: 7px;
}

.ar_styleButtonPayNowUikitModalPayment {
  font-family: "tajwal-bold";
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
  margin-bottom: 10px;
  font-size: 16px;
}

.ar_styleButtonPayNowUikitModalPayment:focus {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
}

.ar_styleButtonPayNowUikitModalPayment:hover {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
}

.ar_PayWithStyle {
  width: 67px;
  height: 21px;
  font-family: "tajwal-regular";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  margin-top: 10px;
}

.ar_AddCardModalPay {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #27aad8;
  color: white;
  box-shadow: none;
  border-width: 0px !important;
  cursor: pointer;
  margin-bottom: 39px;
}

.ar_addCardModalUikitpayment {
  width: 56px;
  height: 16px;
  font-family: "tajwal-regular";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.ar_knetUikitModalPayment {
  width: 36px;
  height: 21px;
  font-family: "tajwal-regular";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.ar_contentTextStyleUikitModalPayment {
  width: 106px;
  height: 29px;
  font-family: "tajwal-bold";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  padding-bottom: 32px;
}

.ar_contentTextStyle2UikitModalPayment {
  height: 14.5px;
  font-family: "tajwal-regular";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.ar_propertyValueUikitModalPaymentGreen {
  width: 80px;
  height: 21px;
  font-family: "tajwal-regular";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #5fb563;
  margin-top: 32px;
}

.ar_ModalAddCardTitle {
  width: 49px;
  height: 14.5px;
  font-family: "tajwal-regular";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  margin-bottom: 40px;
  padding-right: 10px;
}

.ar_ModalAddCardStyle {
  padding-right: 30px;
  direction: rtl;
}

.ar_ModalAddCardLabelStyle {
  width: 50px;
  height: 8px;
  font-family: "tajwal-regular";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #666666;
}

.ar_ModalAddCardInputStyle {
  padding-bottom: 20px;
}

.ar_ModalAddCardButtonAdd {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_ModalAddCardButtonAdd:focus {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_ModalAddCardButtonAdd:hover {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_ModalAddCardButtonCancel {
  height: 9.5px;
  font-family: "tajwal-regular";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  cursor: pointer;
  color: #666666;
}

.ar_colUikitModalPayment {
  padding-left: 30px;
}

.ar_RowStyleUikitModalPayment {
  margin-right: 20px;
}

.ar_styleButtonGlobalUikitModalPayment {
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.ar_globalStyleModalUikitPayment {
  direction: rtl;
  width: 100%;
}

.ar_RowStyleUikitModalPayment2 {
  margin-right: 20px;
  text-align: right;
}

.ar_imgStyleModalUikitPayment {
  text-align: left;
  bottom: 2px;
}

.ar_tenantNumber_label {
  font-size: 15px;
}

.ar_payment-btn-container {
  margin-bottom: 30px;
  margin-top: 45px;
  margin-right: 12%;
}

/******RENTALS ********************/

.ar_rentalsStatus-container {
  margin-left: 6% !important;
  margin-right: 6% !important;
  margin-bottom: 30px !important;
  margin-top: 45px !important;
}

#ar_rentalsstatus-child {
  background: white !important;
  border-radius: 2px;
  margin-bottom: 35px;
  min-height: 93%;
}

.ar_rentalsStatus-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px #cccccc solid;
  height: 22%;
  padding: 16px 24px;
}

.ar_rentals_statusA {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #dff0e0;
  color: #5fb563;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tajwal-bold";
  font-size: 14px;
}

.ar_rentals_status_Pending {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #fcf0df;
  color: #f1b55f;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "tajwal-bold";
  font-size: 14px;
}

.ar_rentals_statusB {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #f5d8d8;
  color: #cb3d3d;
  font-family: "tajwal-bold";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ar_rentalsStatus_type {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #808080;
}

.ar_rentalsStatus-body {
  padding: 3% 24px;
}

.ar_rentalsStatus_prop {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #212121;
}

.ar_rentalsStatus_unit {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #808080;
  margin-top: 2%;
}

.ar_rentalsStatus_adrss {
  display: flex;
  margin-top: 3%;
}

.ar_rentalsStatus_adrss div {
  margin-right: 2%;
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #808080;
}

.ar_rentalsStatus_adrss img {
  width: 14px;
  height: 20px;
}

.ar_rentalsStatus_adrss img {
  width: 14px;
  height: 20px;
}

.ar_Hroom-rental p span {
  margin-left: 7px;
  color: #000000;
}

.ar_Hroom-rentalNumbr {
  display: flex;
  font-family: "tajwal-medium";
  font-size: 16px;
}

.ar_Hroom-rentalNumbr p:first-child {
  font-family: "tajwal-medium";
  color: #212121;
}

.ar_Hroom-rentalNumbr p:last-child {
  font-family: "tajwal-bold";
  color: #5fb563;
  margin-right: 4%;
}

/******************Maintenance**************************/

/******************Maintenance**************************/

.DashboardOpenTicketBtn {
  border-radius: 4px;
  background-color: #1d456d;
  height: 40px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}

.DashboardNavBarBtnTxt {
  font-family: "tajwal-bold";
  color: #ffffff;
  font-size: 23;
}

.ar_maintenance-btn-container {
  margin-bottom: 30px;
  padding-top: 45px;
  padding-right: 12%;
  direction: rtl;
}

.ar_maintenance-container {
  background-color: #f7f7f7;
  padding-bottom: 30px;
  min-height: 100vh;
}

.ar_maintenance-container-block {
  margin-left: 12%;
  padding-right: 12%;
  direction: rtl;
}

.ar_maintenance-btn-list-e {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: white;
  background-color: #27aad8;
  width: 150px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  border: 1px solid #27aad8;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_maintenance-btn-list-e-start {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  background-color: #27aad8;
  width: 20%;
  text-align: center;
  height: 40px;
  border: 1px solid #27aad8;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_rentals_statusC {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #fcf0df;
  color: #f1b55f;
  text-align: center;
  padding-top: 3.5px;
  font-family: "tajwal-medium";
}

.ar_maintenanceEditIcon {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  cursor: pointer;
}

.ar_maintenance-btn-list-start {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #212121;
  width: 150px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_maintenance-btn-list {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #212121;
  width: 20%;
  text-align: center;
  height: 40px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ant-dropdown-menu-item-selected p {
  color: #212121;
}

.ant-dropdown-menu-item-selected a {
  color: #212121 !important;
}

@media (max-width: 465px) {
  .ar_PayAdvance {
    background-color: #e6e6e6;
    border-radius: 5px;
    height: 40px;
    width: 130px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    float: right;
    margin-left: 3%;
  }

  .ar_invoices_select_filter {
    width: 80% !important;
    margin-top: 20px;
    margin-right: 0;
  }

  .ar_acount-invoices-img-select {
    position: absolute !important;
    top: 50% !important;
    left: 75px !important;
  }

  .ar_maintenance-btn-list-e-start {
    width: 30% !important;
  }

  .ar_maintenance-btn-list-start {
    width: 30% !important;
  }

  .ar_maintenance-btn-list-e {
    width: 30% !important;
  }

  .ar_maintenance-btn-list {
    width: 30% !important;
  }
}

.ar_resolved_Container {
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  padding-bottom: 30px;
  height: 367px;
}

.ar_resolved_Container_block {
  height: 60px;
  background-color: #dff0e0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.ar_resolved_Container_txt {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #5fb563;
}

.ar_resolved_Container_picture {
  width: 100%;
  height: auto;
}

.ar_resolved_Container_info {
  padding: 24px;
}

.ar_unresolved_Container_info {
  padding: 24px;
  /* height:117px; */
}

.ar_resolved_Container_description {
  display: flex;
  justify-content: space-between;
  direction: rtl;
}

.ar_resolved_Container_descriptionTxt {
  font-family: "tajwal-medium";
  font-size: 18px;
  color: #000000;
  width: 80%;
  overflow: auto;
}

.ar_resolved_Container_descriptionDate {
  font-family: "tajwal-medium";
  font-size: 12px;
  color: #b3b3b3;
}

.ar_resolved_Container_descriptionUnit {
  font-family: "tajwal-medium";
  font-size: 10px;
  color: #808080;
  direction: rtl;
  margin-bottom: 10px;
}

.ar_resolved_Container_descriptionUnitScroll {
  font-family: "tajwal-medium";
  font-size: 14px;
  color: #808080;
  direction: rtl;
  margin-bottom: 16px;
  width: 100%;
}

.ar_unresolved_Container_block {
  height: 60px;
  background-color: #f4d4d4;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.ar_unresolved_Container_txt {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #cb3d3d;
}

.ar_maintenance-Modal-select {
  width: 100%;
}

.ar_maintenance-Modal-container {
  margin: 12px 30px;
}

.ar_maintenance-Modal-txt {
  font-family: "tajwal-medium";
  font-size: 14px;
  color: #666666;
  margin-top: 18px;
}

.ar_maintenance-btn-Link {
  margin-top: 40px;
}

.ar_maintenance-Modal_modal_body>* {
  margin: 0px 20px;
}

.ar_maintenance-Modal-Input {
  height: 60px;
  font-family: "tajwal-Regular" !important;
  font-size: 14px !important;
}

.ar_maintenance-Modal-Image {
  width: 100%;
  height: auto;
}

.ar_maintenance-Modal-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ar_maintenance-Modal-uploadImage {
  width: 50px;
  height: 50px;
}

.ar_maintenance-Modal-uploadImageTxt {
  color: #27aad8;
  font-size: 14px;
  font-family: "tajwal-bold";
  margin-top: 8px;
}

.ar_maintenanceModalText {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #212121;
  text-align: center;
  direction: rtl;
}

.ar_maintenanceDeleteBtn {
  width: 104px;
  color: white;
  background-color: #cb3d3d;
  border-radius: 4px;
  height: 32px;
  font-family: "tajwal-bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.ar_maintenanceDeleteBtn:hover {
  width: 104px;
  color: white;
  background-color: #cb3d3d;
  border-radius: 4px;
  height: 32px;
  font-family: "tajwal-bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.ar_maintenanceCancelbtn {
  width: 100px;
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 32px;
  font-family: "tajwal-bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.ar_maintenanceCancelbtn:hover {
  width: 100px;
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 32px;
  font-family: "tajwal-bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.ar_maintenanceCancelBtns {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.ar_smsVerification--resendCodeMessage {
  width: 100%;
  text-align: center;
  color: #4ead53;
  display: block;
  margin-top: 10px;
}

.ar_alertPaymentModal--alertIcon--container {
  display: flex;
  margin-bottom: 15px;
}

.ar_alertPaymentModal--alertIcon {
  margin: auto;
}

.ar_alertPaymentModal--monthToPay {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  margin-bottom: 24px;
  font-family: 'tajwal-medium';
}

.ar_alertPaymentModal--monthToPayDuePayement {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  margin-bottom: 5px;
  font-family: 'tajwal-medium';
}

.ar_alertPaymentModal--monthToPayDate {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  font-weight: bold;
}

.ar_alertPaymentModal--cancelButton button {
  font-family: 'tajwal-medium';
}

.ar_alertPaymentModal--cancelButton {
  display: flex;
  justify-content: center;
}

.ar_advancedPay--calandarContainer {
  width: 57%;
  justify-content: flex-end;
}

.ar_calandarContainer {
  margin-right: 10px;
  direction: rtl;
}

.ar_textColor__unit {
  color: #b3b3b3;
}

.ar_boxLoading {
  background-color: #cccccc;
  height: 55px;
  width: 200px;
  position: absolute;
  align-self: center;
}

.ar_dashboard_spinContainerImg {
  width: 200px;
  position: relative;
}

.ar_advancedPay--calandarContainer--calandar {
  display: -webkit-box;
  overflow-x: scroll;
  direction: ltr;
}

.ar_calandarContainer .ant-row-rtl {
  direction: var(--antd-wave-shadow-color);
}

.ar_openTicket_upload_container {
  position: relative;
  margin-top: 24px;
}

.ar_delete_img_openTicket {
  position: absolute;
  top: 0px;
  right: 10px;
}

.ar_openTicket_upload_btn .ant-upload.ant-upload-select-picture-card>.ant-upload {
  width: 287px;
  height: 88px;
}
.ar_openTicket_upload_btn .ant-upload-rtl.ant-upload.ant-upload-select-picture-card{
 margin-left: 0;
}

.ar_OpenTicketModal_submitBtn {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_OpenTicketModal_submitBtn:hover {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_OpenTicketModal_submitBtn:focus {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "tajwal-medium";
}

.ar_OpenTicketModal_cancelBtn {
  width: 100%;
  height: 48px;
  background-color: transparent;
  border-color: transparent;
  color: #666666;
  font-size: 16px;
  cursor: pointer;
  font-family: "tajwal-medium";
  padding-right: 10px;
}

@media (max-width: 1046px) {
  .ant-radio-button-wrapper {
    width: 40%;
  }

  .ar_container_profiletop {
    margin-left: 0px;
    padding: 15px;
    margin-right: 0px;
    overflow: auto;
  }

  .ar_container_profilebottom {
    margin-left: 0px;
    padding: 15px;
    margin-right: 0px;
    overflow: auto;
  }

  .ar_confirm_editPswd {
    margin-right: 0px;
  }
}

@media (max-width: 415px) {
  .ar_accounting-btn-list {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 150px;
  }

  .ar_updatePasswordButton {
    margin-bottom: 15px;
  }

  .ar_accounting-btn-list-last {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 150px;
  }

  #ar_br-btn-list {
    border-right: 1px solid #d9d9d9;
    font-family: "tajwal-regular";
    width: 140px;
  }

  .ar_accounting-btn-list-e {
    width: 160px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 140px;
  }

  .ar_accounting-btn-list-e-last {
    width: 140px;
  }
}

@media (max-width: 998px) {
  .ar_accounting_transfer_bigContainer {
    width: 100%;
  }
}

.ar_navBar--menuIcon--margin {
  margin-left: 41px;
}

.mobileMenuDropdownContainer {
  justify-content: space-between;
  display: flex;
  margin: 0px 20px;
}

.ant-dropdown-menu-item.ar_setting-menuItem-logout.ant-dropdown-menu-item-only-child {
  border-top: 1px solid #b3b3b3;
}

.ar_navBar--menuIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 26px;
}

.ar_cardStatic {
  min-width: 350px;
  min-height: 355px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-left: 20px;
}

.ar_cardStaticBottom {
  /* margin-top: 30px; */
  /* margin-top: 5px; */
  margin-bottom: 5px;
  min-width: 350px;
  min-height: 355px;
}

@media (max-width: 500px) {
  .ar_cardStaticBottom {
    /* margin-top: 30px; */
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 250px;
    min-height: 355px;
  }

  .ar_cardStatic {
    min-width: 250px;
    min-height: 355px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ar_styleButtonCancelUikitModalPayment {
    margin-top: 15px;
  }

  .ar_navBar--menuIcon {
    margin-top: 5px;
  }

  .ar_cancelEditButton {
    width: 100%;
    text-align: right;
  }
}

/******************************Messaging**********************/

.compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.conversation-list {
  display: flex;
  flex-direction: column;
}

.ar_conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.ar_conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.ar_conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}

.ar_conversation-list-item-msg {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #d3eef7;
}

.ar_conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.ar_conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.conversation-search {
  padding: 10px;
  display: flex;
}

.ar_conversation-search-input {
  background: #f8f8f8;
  padding: 8px 10px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  margin: 32px 24px;
}

.ar_conversation-search-input .ant-input-clear-icon {
  font-size: 17px;
}

.ar_conversation-search-input .ant-input:placeholder-shown {
  font-size: 12px;
  color: #b3b3b3;
  background-color: transparent;
  font-family: "tajwal-Regular";
}

.ar_conversation-search-input:focus::placeholder {
  text-align: left;
}

.btnUploadMessenger {
  border: none !important;
  background-color: transparent !important;
}

#uploadImageMessenger {
  transform: rotate(270deg);
}

.ar_sendMessenger {
  position: absolute;
  left: 20px;
  bottom: 17px;
  transform: scaleX(-1);
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.ar_message_date_messenger_mine {
  font-family: "tajwal-medium";
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  margin-bottom: 0px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.ar_message_date_messenger {
  font-family: "tajwal-medium";
  font-size: 12px;
  color: #808080;
  text-align: left;
  min-width: 150px;
  margin-bottom: 0px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.ar_messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 102px);
  background: #eeeef1;
  grid-template-columns: 464px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.content {
  background: #f7f7f7;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.left-items,
.right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}

.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}

.scrollable .content {
  background-color: #f7f7f7;
}

input:focus {
  outline: none;
}

.end {
  margin-bottom: 70px;
}

.discussion_container {
  display: flex;
}

.discussion_noLogo_container {
  display: none;
}

.ar_discussion_logo_container {
  margin-right: 32px;
  display: flex;
  min-height: 81px;
  align-items: center;
  margin-left: 19px;
}

.ar_conversation-photo-history {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 16px;
}

.ar_conversation-fleche-bubble {
  position: absolute;
  right: 126px;
  transform: scaleX(-1);
}

.toolbar-title {
  margin: 0;
  display: flex;
  font-size: 16px;
  font-family: "tajwal-bold";
  color: #212121;
}

.ar_conversation-time-info {
  direction: ltr;
  position: absolute;
  left: 24px;
  color: #b3b3b3;
  font-size: 12px;
  font-family: "tajwal-medium";
}

.toolbar-view-unit {
  font-size: 16px;
  color: #36d1e0;
  font-family: "tajwal-bold";
}

.toolbar-delete-conversation {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-family: "tajwal-bold";
  color: #212121;
  cursor: pointer;
}

.btn-delete-modal-confirmation-delete-conversation {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
  margin-left: 50px;
}

.btn-delete-modal-confirmation-delete-conversation:hover {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
}

.btn-delete-modal-confirmation-delete-conversation:focus {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
}

.btn-cancel-modal-confirmation-delete-conversation {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
  float: right;
  margin-right: 50px;
}

.btn-cancel-modal-confirmation-delete-conversation:hover {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
  float: right;
}

.btn-cancel-modal-confirmation-delete-conversation:focus {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "tajwal-bold";
  height: 32px;
  width: 100px;
  float: right;
}

.title-modal-delete-conversation {
  font-size: 16px;
  font-family: "tajwal-medium";
  color: #212121;
  text-align: center;
  margin-bottom: 28px;
}

.image-message-conversation {
  width: 90%;
  height: 90%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.file-message-container {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: none;
}

@media only screen and (max-width: 767px) {
  .ar_TeantReceipView_title {
    margin-right: 25px;
  }

  .ar_Hroom-rental {
    width: 100%;
  }

  .conversation-message-option {
    display: none;
  }

  .conversation-search {
    display: none;
  }

  .ar_conversation-list-item {
    padding: 20px 5px;
  }

  .ar_conversation-info {
    display: none;
  }

  .ar_messenger {
    display: flex;
    height: 100%;
  }
}

.ar_retunIconBack {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-right: 24px;
  cursor: pointer;
}

.ar_backIcon {
  transform: scaleX(-1);
}

.ar_MessagesText {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #808080;
}

/*************************************statistic*****************/

.ar_staticAllCard {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding-top: 40px;
}

.ar_outstandingCercle {
  width: 168px;
  height: 168px;
  background-color: #f5d8d8;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_spin_big_Container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_spin_big_Container_fullHeight {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar_outstandingValue {
  color: #cb3d3d;
  font-size: 24px;
  font-family: "tajwal-bold";
}

#ar_payNowStaticOutstanding {
  width: 135px;
  color: white;
  background-color: #5fb563;
  border-color: #5fb563;
  border-radius: 4px;
  height: 40px;
  /* font-weight: 500; */
  margin-bottom: 30px;
  font-size: 14px;
  font-family: "tajwal-bold";
}

.ar_statisticOutstanding {
  font-size: 24px;
  font-family: "tajwal-bold";
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 25px;
  color: #212121;
}

.ar_payNowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}

.ar_outstandingCardCercle {
  display: flex;
  justify-content: center;
}

.ar_outstandingValueKWD {
  color: #808080;
  font-size: 16px;
  font-family: "tajwal-bold";
  text-align: center;
}

.ar_outstandingStatisticAllValue {
  text-align: center;
}

.ar_statisticCercleMaintenance {
  height: 56px;
  width: 56px;
  background-color: #f5d8d8;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dff0e0;
}

.ar_statisticCercleMaintenanceResolved {
  height: 56px;
  width: 56px;
  background-color: #dff0e0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar_statisticCercleMaintenceValue {
  color: #cb3d3d;
  font-size: 24px;
  font-family: "tajwal-bold";
}

.ar_statisticCercleMaintenceValueResolved {
  font-size: 24px;
  font-family: "tajwal-bold";
  color: #5fb563;
}

.ar_statisticMaintenanceAllCercle {
  padding: 20px;
}

.ar_statisticMaintenanceResolved {
  display: flex;
  justify-content: center;
}

.ar_cardStatistic {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.ar_statisticMaintenanceSmallCercleLabel {
  font-size: 14px;
  font-family: "tajwal-medium";
  color: #b3b3b3;
  margin-bottom: 8px;
}

.ar_statisticSeeAllMaintenance {
  display: flex;
  align-items: center;
  height: 135px;
  justify-content: center;
  padding-top: 24px;
  position: relative;
}

#ar_seeAllMaintenanceStatistic {
  width: 135px;
  color: #212121;
  /* color: #4d4d4d; */
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 40px;
  /* font-weight: 500; */
  margin-bottom: 30px;
  position: absolute;
  bottom: 0px;
  font-size: 14px;
  font-family: "tajwal-medium";
}

.ar_outstandingValueDays {
  color: #808080;
  font-size: 18px;
  font-family: "tajwal-medium";
  text-align: center;
}

.ar_statisticCardCercleGrey {
  display: flex;
  justify-content: center;
}

.ar_statisticCercleGrey {
  width: 168px;
  height: 168px;
  background-color: #e6e6e6;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar_statisticValueGreyCercle {
  color: #212121;
  font-size: 32px;
  font-family: "tajwal-bold";
}

.ar_labelSelectUnitStatistic {
  font-size: 14px;
  font-family: "tajwal-medium";
  margin-left: 32px;
}

.ar_selectUnitStatistic {
  padding-top: 30px;
}

.ar_labelSelectUnitStatistic .ant-select-selection-item {
  padding-top: 2px;
}

.ar_optionUnitStatistic {
  font-size: 14px;
  color: #212121;
  font-family: "tajwal-medium";
  direction: rtl;
}

/*************************Modal view contract **********************/

.ar_ViewContractRentals,
.ar_ViewContractRentals:hover,
.ar_ViewContractRentals:focus {
  width: 130px;
  height: 40px;
  background-color: #27aad8;
  color: white;
  font-family: "tajwal-bold";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #27aad8;
}

.ar_viewContract_title {
  font-size: 24px;
  color: #212121;
  font-family: "tajwal-bold";
}

.ar_viewContract_title_container {
  display: flex;
}

.ar_viewContract_logo {
  left: 25px;
  position: absolute;
  width: 154px;
  height: 40, 29px;
}

.ar_viewContract_contract_info {
  margin-top: 55px;
  display: flex;
}

.ar_viewContract_date_logo {
  width: 18px;
}

.ar_viewContract_date {
  color: #808080;
  font-size: 16px;
  font-family: "tajwal-medium";
}

.ar_viewContract_dateValue {
  color: #212121;
  font-size: 16px;
  font-family: "tajwal-medium";
  margin-right: 20px;
}

.ar_viewContract_to {
  font-size: 16px;
  margin-right: 16px;
  font-family: "tajwal-medium";
  color: #808080;
}

.ar_viewContract_contract_amount_info {
  margin-top: 24px;
  display: flex;
}

.ar_viewContract_money_logo {
  width: 18px;
}

.ar_viewContract_amount_value {
  color: #5fb563;
  font-size: 16px;
  font-family: "tajwal-bold";
  margin-right: 29px;
}

.ar_viewContract_btn {
  margin-top: 58px;
  color: white;
  background-color: #36d1e0;
  height: 56px;
  width: 334px;
  border-radius: 4px;
  border: 1px solid #36d1e0;
  font-size: 16px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_rowViewContract {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.ar_accept_contract_modal .ant-modal-body {
  padding: 0px !important;
}

.ar_accept_contract_modal.ant-modal {
  width: 414px !important;
  padding: 0px !important;
}

.ar_accept_contract_modal .ant-modal-content {
  height: 471px !important;
  padding: 40px 0px 40px 40px;
}

.ar_accept_contract_msg {
  font-family: "tajwal-bold";
  color: #212121;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 9px;
}

.ar_accept_contract_msg_access {
  font-family: "tajwal-medium";
  color: #808080;
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.ar_acceptContract_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-right: 40px;
  text-align: center;
}

.ar_contract_modal .ant-modal-body {
  padding: 37px 77px 53px 77px;
}

.ar_history_load {
  font-family: "tajwal-medium";
  font-size: 18px;
  position: absolute;
  top: 105px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .ar_conversation-list-item {
    padding: 0px;
    display: inherit;
    z-index: 1;
    display: flex;
  }

  .ar_history_load {
    position: initial;
  }

  .ar_conversation-fleche-bubble {
    display: none;
  }

  .ar_discussion_logo_container {
    display: none;
  }

  .ar_conversation-info {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .ar_conversation-time-info {
    /* top: 10px; */
    left: 15px;
  }
}

.ar_ProfilEdit {
  font-family: "tajwal-bold";
  font-size: 16px;
  color: #27aad8;
  cursor: pointer;
}

.ar_ProfilEditDisplay {
  font-family: "tajwal-bold";
  font-size: 16px;
  color: #27aad8;
  cursor: pointer;
  padding-right: 5px;
}

.ar_ProfilEditCancel {
  font-family: "tajwal-bold";
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}

.ar_updateBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 60px;
}

.ar_TeantPhoneNumber_ModalPhone .ant-modal-content {
  height: 377px;
  border-radius: 4px;
  margin-top: 19px;
}

.ar_TeantPhoneNumber_ModalPhone .ant-modal-body {
  padding: 48px 104px;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

@media only screen and (max-width: 567px) {
  .ar_TeantPhoneNumber_ModalPhone .ant-modal-body {
    padding: 35px 30px;
  }

  .ar_contract_modal .ant-modal-body {
    padding: 37px 30px 53px 30px;
  }
}

.ar_forgetPswVerification--inputContainer {
  display: flex;
  justify-content: center;
}

.ar_forgotPasswordCode {
  direction: ltr;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .ar_forgotPasswordCode {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.ar_connection_tenant_verif_card_phone {
  max-width: 445px;
  height: 527px;
  z-index: 10;
  background-color: white;
  padding: 25px 20px;
}

.ar_smsVerificationTenantGrey__reset {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-left: 35px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
}

.ar_smsVerificationTenantBlue_phone {
  width: 56px !important;
  height: 48px !important;
  margin-left: 35px;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_language_container {
  margin-right: 27px;
  text-align: center;
  padding: 4% 6%;
  border-radius: 10px;
  background-color: white;
  margin-right: 5%;
  color: #212121;
}

.ar_selectLanguage {
  font-size: 18px;
  font-family: "tajwal-bold";
  margin-top: 15px;
  margin-bottom: 25px;
}

.ar_language-switch {
  display: flex;
  justify-content: center;
  direction: rtl;
}

/*--- user verif ----*/

.ar_phoneVerification-inputPhoneNumber {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
}

/* .ar_phoneVerification-logo {
  margin-right: 8%;
  margin-top: 2%;
} */

.ar_connection_tenant_verif_card {
  max-width: 445px;
  height: 545px;
  margin-top: calc((100vh - 600px) / 3);
  z-index: 10;
  background-color: white;
  padding: 25px 20px;
}

.ar_forgotPasswordThankyou {
  font-size: 24px;
  font-family: "tajwal-bold";
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 15px;
}

.ar_forgotPasswordMessage {
  color: #808080;
  font-family: "tajwal-regular";
  font-size: 18px;
}

.ar_userSmsCode {
  direction: ltr;
  display: flex;
  justify-content: center;
  padding-top: 7%;
}

.ar_smsVerificationTenantBlue {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  margin-left: 35px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_smsVerificationTenantBlue_ {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_smsVerificationTenantGrey_ {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_smsVerificationTenantGrey {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-left: 35px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_userResendCode {
  font-family: "tajwal-medium";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #36d1e0;
  margin-top: 16px;
}

.ar_userVerig_propertyLogo {
  position: fixed;
  bottom: 0px;
  padding-left: 15%;
  padding-right: 7%;
}

/*-------User Register-----------*/

.ar_pswTenantGreat {
  margin-right: 15%;
  font-family: "tajwal-bold";
  font-size: 24px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
}

.ar_pswTenantLogoT {
  text-align: left;
}

.ar_pswTenantCreatepsw {
  margin-right: 9%;
  font-family: "tajwal-medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #212121;
  padding-top: 10px;
}

.ar_pswTenant_label {
  font-family: "tajwal-regular";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.ar_updatePasswordButton {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "tajwal-medium";
  color: white;
  margin-left: 24px;
}

.message.mine.start .bubble-container .ar_bubble {
  margin-top: 10px;
}

.message.mine.end .bubble-container .ar_bubble {
  margin-bottom: 10px;
}

.message .bubble-container .ar_bubble {
  margin: 1px 0px;
  background: #e6e6e6;
  border-radius: 4px;
  padding: 30px 25px 16px;
  min-width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "tajwal-medium";
  color: #212121;
  max-width: 50%;
  line-break: anywhere;
}

.message.mine .bubble-container .ar_bubble {
  margin-right: 32px;
  background: #1d456d;
  color: white;
  border-radius: 4px;
  padding: 30px 25px 16px;
  min-width: 300px;
  min-height: 81px;
  font-size: 16px;
  font-family: "tajwal-medium";
  align-items: center;
  max-width: 57%;
  overflow-wrap: anywhere;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .message.mine .bubble-container .ar_bubble {
    max-width: 65%;
  }
}

@media only screen and (max-width: 600px) {
  .ar_RowStyleUikitModalPayment {
    margin-right: 0px;
  }

  .ar_conversation-list-item-msg {
    padding: 0px;
  }

  .message .bubble-container .en_bubble {
    width: 90%;
    padding: 16px;
  }

  .message.mine .bubble-container .en_bubble {
    width: 90%;
    margin-right: 0px;
  }
}

.ar_cnxTenantForgotPassword {
  width: 100%;
  margin-top: 10px;
  color: #27aad8;
}

.ar_cnxTenantForgotPassword:hover {
  color: #27aad8;
}

.ar_acount-invoices-img-select-suffixIcon {
  position: absolute !important;
  top: -7px !important;
  left: -8px !important;
}

.ar_acount-invoices-img-select-suffixIcon-statics {
  position: absolute !important;
  top: -8px !important;
  left: -8px !important;
}

.ar_modalTenant_txt_Phone {
  font-size: 24px;
  font-family: "tajwal-bold";
  color: #212121;
  text-align: center;
  margin-bottom: 24px;
}

.ar_verifNew_txt {
  color: #808080;
  text-align: center;
  font-size: 16px;
  font-family: "tajwal-medium";
}

.ar_modalTenant_btn:hover {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
}

.ar_modalTenant_btn:focus {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
}

.ar_modalTenant_btn {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "tajwal-bold";
}

.ar_cancel_newNumber {
  color: #666666;
  font-size: 16px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_DashboardNavBarBtnTxt {
  font-family: "Tajwal-Bold";
  color: #ffffff;
  font-size: 23;
}

.ar_invoices_select_filter .ant-select-selection-placeholder {
  font-family: "tajwal-regular";
}

.ar_invoices_select_filter_option {
  font-family: "tajwal-regular" !important;
}

.ar_EditPhoneNumber {
  margin-left: 8px;
  color: #212121;
  font-family: 'SF-Pro-Display-Medium';
  width: 92%;
  overflow-x: auto;
  direction: ltr;
}

.ar_EditPhoneNumber::-webkit-scrollbar {
  height: 4px;
}

.ar_receipt_invoice_details {
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #212121;
  margin-right: 10px;
  direction: ltr;
}

.ar_specific_receipt {
  margin-right: 0px;
}

.ar_receipt_date_direction {
  direction: ltr;
}

.ar_TeantReceipView_receip_ {
  color: #808080;
  font-family: "tajwal-regular";
  font-size: 18px;
  margin-top: 20px;
  /* margin-bottom: 5px; */
}

.ar_TeantReceipView_receip_Down {
  color: #808080;
  font-family: "tajwal-regular";
  font-size: 18px;
}

.ar_receipt_money {
  margin-right: 10px;
  color: #b3b3b3;
  font-size: 18px;
  font-family: "tajwal-bold";
}

.ar_confirm_editPswd {
  margin-right: 24px;
}

@media screen and (max-width: 520px) {
  .ar_TeantReceipView_receip_Down {
    font-size: 16px;
  }

  .ar_TeantReceipView .ant-modal-body {
    width: 100%;
    max-height: 567px;
  }

  .ar_TeantReceipViewFailed .ant-modal-body {
    width: 100%;
    max-height: 660px;
  }

  .ar_confirm_editPswd {
    margin-right: 0px;
  }
}

.ar_maintenance-Modal-Input::placeholder {
  font-family: "tajwal-regular";
  font-size: 14px;
}

.ar_maintenance-Input-Modal {
  font-family: "tajwal-regular" !important;
  font-size: 14px !important;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-family: "tajwal-regular";
  font-size: 14px;
  padding-top: 4px;
}

.ar_maintenance-Modal_modal_body .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 4px;
}

.ar_react-switch-labelTxt {
  font-size: 18px;
  color: #212121;
  font-family: "tajwal-medium";
  margin: 0px 10px 0px 10px;
}

.ar_noData {
  font-size: 14px;
  font-family: "tajwal-medium";
  color: #cccccc;
  margin-top: -30px;
}

.ar_cancel_validNewNumber {
  display: inline-block;
  padding-top: 16px;
  color: #666666;
  font-size: 16px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_smsVerificationTenantBlue_phone_ {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  margin-left: 35px;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.ar_smsVerificationTenantGrey__reset_ {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-left: 35px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
}

.ar_logoutFooter {
  display: none;
}

@media screen and (max-width: 500px) {
  .ar_Rental-inputPaym {
    font-size: 14px !important;
  }

  .ar_setting-menuItem-logout {
    display: none;
  }

  .ar_logoutFooter {
    display: flex;
  }

  .ar_container_profiletop {
    padding-bottom: 24px;
    width: fit-content;
  }

  .ar_info {
    margin-bottom: 20px;
  }
}

.ar_fixed_logout {
  font-size: 16px;
  font-family: "tajwal-bold";
  color: white;
  margin-right: 20px;
}

.ar_changePasswordProfile_false {
  color: #b3b3b3;
  font-size: 14px;
  font-family: "tajwal-medium";
}

.ar_CPUpper_successful_true {
  color: #5fb563;
  font-size: 14px;
  font-family: "tajwal-medium";
}

.ar_uploadtext {
  color: rgb(54, 200, 216);
  font-family: "tajwal-medium";
  font-size: 14px;
}

.ar_orBtn {
  color: #212121;
  font-family: "tajwal-medium";
  font-size: 14px;
}

.ar_dnd_btn {
  font-family: "tajwal-medium";
  font-size: 14px;
}

.ar_tenantProfile_editBtn[disabled],
.ar_tenantProfile_editBtn[disabled]:hover {
  background-color: white;
}

.ar_term_condition {
  color: #666666;
  margin-top: 16px;
  font-size: 12px;
  font-family: "tajwal-medium";
  text-align: center;
}

.ar_label-inputPaym span {
  color: #212121;
  font-size: 14px;
  font-family: 'tajwal-medium';
}

.ar_Rental-inputPaym .ant-select-item-option-content {
  font-family: 'tajwal-regular';
}

#ar_empty_responsive {
  position: absolute;
  right: 40%;
  top: 40%;
}

#ar_empty_responsive_history {
  position: absolute;
  right: 40%;
  top: 20%;
  height: 70vh;
}

#ar_empty_responsive_ {
  position: absolute;
  right: 40%;
  top: 20%;
}

.ar_tenant_loader_container {
  position: absolute;
  right: 45%;
  top: 30%;
}

@media screen and (max-width: 500px) {
  .ar_payment_col {
    display: flex;
  }

  .ar_payment-btn-container {
    margin-left: 15%;
    margin-right: 14%;
  }

  .ar_setting-menu-logout {
    padding-right: 20px;
  }

  #ar_empty_responsive {
    position: absolute;
    right: 15%;
  }

  .ar_tenant_loader_container {
    right: 35%;
  }

  #ar_empty_responsive_ {
    position: absolute;
    right: 20%;
    top: 25%;
  }

  #ar_empty_responsive_history {
    position: absolute;
    right: 5%;
    top: 140px;
    height: 100%;
  }
}

#ar_time_style {
  font-family: "tajwal-medium";
}

.ar_navbar_item_font {
  font-family: 'tajwal-bold';
  margin: 0;
  line-height: 38px;
}

.ar_navBarLanding {
  direction: rtl;
}

.ar_navItem {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.ar_navItem>a {
  height: 40px;
  /* margin-left: 40px; */
  color: #ffffff;
  font-size: 18px;
  font-family: "Avenir-Next-Medium";
}

.ar_navItem>.bt-btn {
  text-align: unset;
}

.ar_navItem>.ar_BtnNavBarHomePage,
.ar_navItem>.ar_BtnNavBarHomePage:hover,
.ar_navItem>.ar_BtnNavBarHomePage:focus {
  /* width: 228px; */
  color: #36d1e0;
  background-color: white;
  border-color: white;
  border-radius: 4px;
  height: 48px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  cursor: pointer;
  line-height: 2;
}

.ar_navBarLanding .en_navItem>.ar_RequestBookBtn,
.ar_navBarLanding .en_navItem>.ar_RequestBookBtn:hover,
.ar_navBarLanding .en_navItem>.ar_RequestBookBtn:focus {
  width: 228px;
  color: white;
  background-color: #36d1e0;
  border-color: #36d1e0;
  border-radius: 4px;
  height: 48px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.ar_navBarLanding .en_navItem>a {
  height: 48px;
  /* margin-left: 40px; */
  color: #b3b3b3;
  font-size: 18px;
  font-family: "Avenir-Next-Medium";
}

.active {
  color: #36D1E0;
}

.ar-float-left {
  position: relative;
  float: right;
  margin: 0 20px;
  margin-top: 7px;
}

@media screen and (max-width: 1200px) {
  .ar_row-setting-navbar {
    border-bottom: solid 1px #cccccc;
    padding-top: 18px;
    padding-bottom: 10px;
  }

  .ar_row-setting-navbar-shadow {
    border-bottom: none;
    box-shadow: 0 10px 6px -6px #cccc;
    z-index: 9999;
  }

  .ar-float-left {
    margin-top: 0px;
    margin-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .ar-float-left {
    margin-top: 0px;
  }
}

.ar-float-right {
  position: relative;
  float: left;
  margin-left: 0;
}

.main-navigation {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ar_Burger {
  display: none;
}

.ar_Burger_Image {
  width: 30px;
  height: 30px;
  transform: scaleX(-1);
}

.ar_Burger_Image_close {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.ar_Burger_Image_close {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.menu-ul>li {
  /* top: 1px; */
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  height: 40px;
}

.menu-ul {
  width: 100%;
  display: flex;
  white-space: nowrap;
  border: 0;
  border-bottom: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  /* line-height: 0; */
  list-style: none;
  background: #fff;
  outline: none;
  /* -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); */
  -webkit-transition: "background 0.3s, width 0.2s";
  transition: "background 0.3s, width 0.2s";
}

.ar_menu-messenger {
  margin-right: auto !important;
}

@media screen and (max-width: 1200px) {
  .ar_menu-messenger {
    margin-right: 0;
  }

  .ar-float-right {
    margin-left: 0;
  }

  .menu-ul {
    /* width: 100%; */
    display: block;
    white-space: nowrap;
    border: 0;
    border-bottom: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.65);
    /* line-height: 0; */
    list-style: none;
    background: #fff;
    outline: none;
    /* -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); */
    /* box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); */
    -webkit-transition: "background 0.3s, width 0.2s";
    transition: "background 0.3s, width 0.2s";
  }

  .ar-menu-float-right {
    position: relative;
    float: right;
    width: 100%;
  }

  .ar_Burger {
    height: 48px;
    /* margin-left: 40px; */
    color: #b3b3b3;
    font-size: 18px;
    font-family: "Avenir-Next-Medium";
    display: block;
    position: relative;
    float: right;
  }

  .ar_navItem {
    display: block;
    height: 0px;
    width: 100%;
    position: relative;
    float: right;
    transition: height 0.5s;
  }

  .ar_navItem.show {
    display: block;
    height: 300px;
    transition: height 0.5s;
  }

  .ar_navItem>a {
    display: block;
  }

  .ar_navBarLanding .ar_navItem>.en_RequestBookBtn,
  .ar_navBarLanding .ar_navItem>.en_RequestBookBtn:hover,
  .ar_navBarLanding .ar_navItem>.en_RequestBookBtn:focus {
    width: auto;
  }
}

@media (max-width:320px) {
  .ar_containerDashboard {
    max-width: 100%;
  }

  .ar_EditPhoneContainer {
    position: absolute;
    left: 5px;
  }
}

.ar_terms_Row {
  margin-top: 10px;
  justify-content: center;
  font-size: 16px;
  font-family: "tajawal";
  direction: rtl;
  text-align: justify;
  color: #000;
}

.ar_text_Bold {
  font-family: "tajwal-bold";
  font-size: 18px;
  margin-bottom: 10px;
}

.ar_text_Bold_word {
  font-family: "tajwal-bold";
  font-size: 16px;
}

.ar_terms_lang_style {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
}

.ar_terms_languge {
  direction: ltr;
  margin-bottom: 20px;
}

.arrow_up {
  transition: all 0.5s ease;
  transform: rotate(180deg);
  margin-right: 10px;
}

.arrow_down {
  margin-right: 10px;
}

.ar_delete_upload_civilId {
  cursor: pointer;
  margin: 0px 10px 0px 0px;
}

.ar_delete_upload_civilIdMinWidth {
  position: absolute;
  right: 148px;
}

.ar_modalFailedMsg {
  margin-right: 40px;
}

.ar_UIKIT_password_input_suffixe {
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
  height: 24px;
  border: none;
  width: 24px;
}

.ar_editEmailAddressPlaceholder::placeholder {
  font-family: 'tajwal-regular';
  font-size: 14px;
}

.ar_message_logo_not {
  font-family: 'tajawal';
  font-size: 10px;
  position: absolute;
  right: 19px;
  bottom: 17px;
  background-color: #cb3d3d;
  border: 1px solid #cb3d3d;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar_compose-input.ant-input:focus {
  -webkit-box-shadow: none
}

.ar_compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px !important;
  background: none;
  font-family: inherit !important;
  padding-left: 50px;
  resize: none;
  padding-top: 11px;
}

.ar_compose-input:focus {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
  outline: none;
  padding-top: 11px;
}

.ar_compose-input::placeholder {
  opacity: 0.3;
}

.ar_empty-style {
  font-size: 24px;
  color: #212121;
  font-family: "tajwal-medium";
}

.ar_empty2-style {
  font-size: 12px;
  color: #808080;
  font-family: "tajwal-regular";
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  direction: ltr;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  color: #212121;
}

.ar_tableScroll {
  overflow: auto;
}

.ar_errorMsg {
  color: #c62828;
  font-size: 14px;
  margin-top: 10px;
  font-family: "tajwal-medium";
}

.ar_property_pending_notif {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F1B55F;
  font-size: 16px;
  font-family: "tajwal-medium";
  background-color: #FCF0DF;
  text-align: center;
}

.ar_property_pending_notif img,
.ar_property_success_notif img {
  margin-left: 8px;
}

.ar_property_success_notif {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5FB563;
  font-size: 16px;
  font-family: "tajwal-medium";
  background-color: #DFF0E0;
  text-align: center;
}

.ar_property_success_notif .close_property_notif {
  position: absolute;
  left: 15px;
  cursor: pointer;
}

.ar_edit_phone_number {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  float: right;
  margin-top: 5px;
}

@media (max-width: 768px) {

  .ar_property_pending_notif,
  .ar_property_success_notif {
    padding: 10px;
  }

  .ar_property_success_notif .close_property_notif {
    position: absolute;
    left: 0px;
  }
}

@media (max-width: 1200px) {
  .ar_Burger_Image {
    width: 23px;
    height: 17px;
    margin-top: 15px;
  }

  .ar-float-left .tenantive_logo_image {
    width: 114px;
    height: 32px;
  }

  .ar_message_logo_not {
    right: 60px;
  }
}

@media (max-width: 567px) {
  .ar_Burger_Image {
    width: 23px;
    height: 17px;
    margin-top: 10px;
  }

  .ar-float-left img {
    width: 114px;
  }

  .ar-float-left .beta_logo_image {
    width: 56px;
    height: 12px;
  }

  .ar_row-setting-navbar {
    height: 73px;
    padding-top: 15px;
  }

  .ar_edit_phone_number {
    display: flex;
    flex-direction: column;
    order: 2 !important;
    margin-top: 0px;
  }

  .ar_phoneVerification-title {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .ar_dashboard_spinContainerHistory {
    height: 40vh;
  }

  .ar_infonameResponsive {
    display: flex;
    font-family: "tajwal-bold";
    color: #212121;
    font-size: 18px;
  }

  .ar_infoname {
    display: none;
  }

  .ar_infoEmailResponsive {
    display: flex;
    color: #808080;
    font-size: 16px;
    font-family: "tajwal-regular";
    direction: ltr;
  }

  .ar_infoEmail {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ar_payment-btn-container {
    margin-right: 9%;
  }

  .ar_table {
    margin-left: 15px;
    margin-right: 15px;
  }

  .ar_PaymentContainer {
    margin-right: 9%;
    width: 95%;
  }

  .ar_transfers-table {
    width: 95% !important;
  }

  .ar_rentalsStatus-container {
    margin-left: 9%;
  }

  .ar_maintenance-btn-container {
    padding-right: 9%;
  }

  .ar_maintenance-container-block {
    margin-left: 9%;
    padding-right: 9%;
  }

  .ar_containerDashboard {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .ar_containerDashboard {
    max-width: 80%;
  }
}

.ar_errStyle {
  color: #cb3d3d;
  font-size: 14px;
  font-family: "tajwal-medium";
}

.ar_resolved_Container .ant-carousel .slick-dots li.slick-active {
  padding-right: 7px;
  padding-left: 3px;
}

.ar_resolved_Container .ant-carousel .slick-dots {
  height: 10px;
}

.ar_text_blue {
  font-family: "tajwal-bold";
  font-size: 18px;
  margin-bottom: 10px;
  color: #27aad8;
}

.ar_text_black {
  font-family: "tajwal-bold";
  font-size: 18px;
  margin-bottom: 10px;
  color: #212121;
}

.ar_contract_info_lease {
  display: flex;
  font-family: "tajwal-medium";
  font-size: 16px;
  color: #808080;
}

.ar_monthly_rentAmount {
  color: #212121;
  font-family: "tajwal-medium";
  font-size: 18px;
}

.ar_black_color {
  color: #212121;
  font-family: "tajwal-medium";
  font-size: 16px;
}

.ar_image_space {
  margin-left: 8px;
}

.ar_alert_toast_message {
  font-family: "tajwal-medium";
  font-size: 16px;
  text-align: center;
  margin-top: 14px;
  color: #CB3D3D;
}

.ar_claims_container {
  background-color: white;
  min-height: 340px;
  border-radius: 10px;
  margin: 0 40px 0 27px;
  padding: 48px 41px 50px;
}

.ar_claims_title {
  font-size: 24px;
  color: #212121;
  font-family: "tajwal-bold";
}

.ar_claims_select_label {
  color: #666666;
  font-size: 14px;
  font-family: "tajwal-medium";
  margin-bottom: 8px;
}

.ar_claims_select.ant-select {
  width: 280px;
  height: 38px;
}

.ar_claims_property.ant-select {
  width: 200px;
  height: 30px;
}

.ar_claims_property.ant-select-rtl,
.ar_claims_property.ant-select-rtl .ant-select-selector {
  width: 200px;
  height: 30px !important;
  border-radius: 4px;
}

.ar_claims_select .ant-select-selection-item,
.ar_claims_property .ant-select-selection-item {
  font-family: "tajwal-regular" !important;
  font-size: 14px !important;
  color: #212121;
  display: flex;
  align-items: center;
  padding: 0px;
}

.ar_claim_btn,
.ar_claim_btn:hover,
.ar_claim_btn:focus {
  width: 280px;
  height: 48px;
  color: white;
  font-size: 16px;
  font-family: "tajwal-bold";
  background: #27AAD8;
  border: 1px solid #27AAD8;
  border-radius: 4px;
  margin-top: 73px;
}

.ar_claim_btn.ant-btn[disabled] {
  background: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  opacity: 0.2;
}

.ar_claims_logo {
  position: absolute;
  top: 43px;
  left: 38px;
}

.ar_customer_info_title {
  font-size: 18px;
  font-family: "tajwal-medium";
  color: #212121;
  border-bottom: 1px solid #CCCCCC;
}

.ar_claim_modal_label {
  font-size: 14px;
  color: #808080;
  font-family: "tajwal-medium";
  margin-top: 9px;
}

.ar_claim_modal_label.paddin-rl {
  padding-left: 15px;
}

.ar_claim_modal_value {
  font-size: 14px;
  color: #212121;
  font-family: "tajwal-medium";
  margin-top: 9px;
}

.ar_claim_modal_value input {
  height: 38px;
}

textarea.ant-input.ar_claim_area_txt {
  margin-top: 10px;
  border: 1px solid #CCCCCC;
  height: 98px !important;
  font-family: "tajwal-medium";
  font-size: 14px;
  padding: 12px 14px 12px 9px;
  line-height: 17px;
  resize: none;
}

.ar_claims_duration {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 0px;
  font-family: "tajwal-medium";
  color: #666666;
  margin-right: 40px;
  max-width: 852px;
}

.ar_claims_txt_count {
  position: absolute;
  left: 6px;
  bottom: 3px;
  font-size: 8px;
  font-family: "tajwal-medium";
  color: #B3B3B3;
}

.ar_claims_check .ant-checkbox+span {
  color: #212121;
  border-radius: 4px;
  font-size: 12px;
  font-family: "tajwal-regular";
  padding-left: 16px;
  display: inline-block;
}

.ant-upload.ant-upload-drag.ar_claims_drag {
  width: 185px;
  height: 82px;
  margin-top: 6px;
}

.ant-upload.ant-upload-drag.ar_claims_drag .ant-upload-btn,
.ant-upload.ant-upload-drag.ar_claims_drag .ant-upload {
  padding: 0;
}

.ar_claims_dragText {
  font-family: "tajwal-medium";
  color: #212121;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  margin-bottom: 5px;
}

.ar_claims_dragText img {
  padding-bottom: 6px;
}

.ar_claims_drag_name {
  width: 100%;
  padding-right: 56px;
  height: 90px;
  overflow: auto;
}

.ar_sendClaim_button.ant-btn[disabled] {
  background: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  opacity: 0.2;
}

.ar_alertModalBtn,
.ar_alertModalBtn:hover,
.ar_alertModalBtn:focus {
  width: 200px;
  height: 48px;
  margin-top: 47px;
  background-color: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  font-size: 16px;
  font-family: 'tajwal-medium';
  border-radius: 4px;
}

.ar_confirmlAlertBtn,
.ar_confirmlAlertBtn:hover,
.ar_confirmlAlertBtn:focus {
  width: 134px;
  height: 32px;
  background-color: #27AAD8;
  border-radius: 4px;
  border: 1px solid #27AAD8;
  color: white;
  font-size: 12px;
  font-family: "tajwal-bold";
  margin-top: 39px;
}

.ar_cancelAlertBtn,
.ar_cancelAlertBtn:hover,
.ar_cancelAlertBtn:focus {
  width: 134px;
  height: 32px;
  background-color: #E6E6E6;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  color: #4D4D4D;
  font-size: 12px;
  font-family: "tajwal-bold";
  margin-top: 39px;
}

.ar_claims_alert_title {
  font-size: 24px;
  font-family: "tajwal-bold";
  color: #212121;
  margin-top: 10px;
  margin-bottom: 34px;
  text-align: center;
}

.ar_history_claims_container {
  margin: 48px 40px 0 27px;
}

.ar_claims_history_title {
  font-size: 24px;
  font-family: "tajwal-bold";
  margin-bottom: 30px;
  color: #212121;
  margin-right: 41px;
}

@media (max-width: 1200px) {
  .ar_claims_history_title {
    margin-right: 0px;
  }

  .ar_history_claims_container {
    margin: 48px 40px 0 0;
  }
}

.ar_claims_tab_view {
  width: 100%;
  border: 1px solid #E2E2E3;
  min-width: 900px;
  border-radius: 4px;
  overflow: auto;
}

.ar_claims_tab_label.description {
  width: 400px;
}

.ar_claims_tab_view .ant-table-container table>thead>tr:first-child th:first-child {
  font-size: 14px;
  font-family: "tajwal-bold";
  background: #E6E6E6;
  color: #808080;
  height: 47px;
  padding: 0 27px;
  text-align: right;
}

.ar_claims_tab_view .ant-table-container table>thead>tr:first-child th {
  font-size: 14px;
  font-family: "tajwal-bold";
  background: #E6E6E6;
  color: #808080;
  height: 47px;
  padding: 0 27px;
  text-align: center;
}

.ar_claims_tab_view tbody tr:nth-child(even)>td {
  background-color: #F7F7F7;
  border-bottom: 1px solid #E2E2E3;
  height: 92px;
  font-family: "tajwal-regular";
  font-size: 14px;
  color: #212121;
  text-align: center;
}

.ar_claims_tab_view tbody tr:nth-child(odd)>td {
  background-color: white;
  border-bottom: 1px solid #E2E2E3;
  height: 92px;
  font-family: "tajwal-regular";
  font-size: 14px;
  color: #212121;
  text-align: center;
}

.ar_claims_tab_view tbody tr td:first-child {
  text-align: right;
}

.ar_claims_tab_view tbody tr:last-child>td {
  border-bottom: 0;
}

.ant-table-cell.ar_claims_tab_label {
  padding: 15px 26px;
}

.ar_claims_tab_view .ant-table-tbody {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ar_medium_font {
  font-family: "tajwal-medium";
}

.ar_inProgress_txt {
  color: #808080;
  font-size: 10px;
  font-family: "tajwal-medium";
  margin-top: 6px;
}

.ar_progress_claim {
  width: 102px;
  height: 40px;
  background: #FCF0DF;
  border-radius: 20px;
  color: #F1B55F;
  font-size: 14px;
  font-family: "tajwal-bold";
  text-align: center;
  align-content: center;
  display: inline-block;
  padding: 9px 0;
}

.ar_resolved_claim {
  width: 102px;
  height: 40px;
  background: #DFF0E0;
  border-radius: 20px;
  color: #5FB563;
  font-size: 14px;
  font-family: "tajwal-bold";
  text-align: center;
  align-content: center;
  display: inline-block;
  padding: 9px 0;
}

.ar_claim_save_mail {
  background: transparent;
  border: none;
  color: #27aad8;
  font-size: 14px;
  font-family: "tajwal-medium";
  padding-left: 0;
}

.ar_sendClaim_button,
.ar_sendClaim_button:hover,
.ar_sendClaim_button:focus {
  width: 130px;
  height: 40px;
  font-size: 14px;
  font-family: "tajwal-bold";
  color: white;
  background: #27aad8;
  border: 1px solid #27aad8;
  border-radius: 4px;
  margin-left: 20px;
}

.ar_acount-rentl-img-select-suffixIcon-createInvoice {
  cursor: pointer;
  top: -7px;
  position: absolute;
  right: -6px;
}

.ar_searchInput.ant-input-affix-wrapper {
  height: 40px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
}

.ar_searchInput.ant-input-affix-wrapper-rtl .ant-input-suffix {
  padding-top: 5px;
}

#ar_date-btn-transfers {
  width: 39px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ar_date-btn-transfers:hover {
  background-color: #d9d9d9;
  border-color: #cccccc;
}

.ar_transfers-date-picker {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding-left: 0px;
}

.ar_transfers-date-picker .ant-picker-clear {
  right: 41px;
}

.ar_transfers-date-picker .ant-picker-suffix,
.ar_reports-picker-container .ant-picker-suffix {
  border-right: 1px solid #d9d9d9;
  position: absolute;
  left: 0;
}

.ar_transfers-date-picker ::placeholder {
  font-family: "tajwal-Regular";
  font-size: 14px;
}

.ant-col-rtl .ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: #cb3d3d;
  font-family: "tajwal-medium";
}

.ar_addUnit_selectType-optionStyles {
  font-family: "tajwal-Regular";
  font-size: 14px;
  color: #212121;
}

.ar_addUnit_selectType-optionStyles .ant-select-item-option-content {
  font-family: "tajwal-Regular";
  font-size: 14px;
  color: #212121;
}

.ar_type_claim_tab {
  color: #212121;
  font-size: 14px;
  font-family: "tajwal-medium";
  margin-bottom: 11px;
}

.ar_claimsDoc_dragText {
  color: #212121;
  text-decoration: underline;
  font-size: 14px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_claims_select.filter.ant-select {
  width: 100%;
}

.ar_transfers-date-picker.filter .ant-picker-suffix {
  border-right: 0;
  top: -0.5px;
}

.ar_transfers-date-picker.filter .suffix_date {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ar_transfers-picker-container {
  display: flex;
  margin-bottom: 12px;
}

.ar_paddingLR_16 {
  padding-right: 16px;
}

.ar_Bold_txt {
  font-family: "tajwal-bold";
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  font-family: "tajwal-regular";
  font-size: 14px;
  color: #B3B3B3;
}

.ar_clear_invoiceDatePicker {
  position: absolute;
  left: 48px;
  top: 14px;
  cursor: pointer;
}

@media (max-width: 769px) {
  .ar-float-right {
    margin-left: 0;
  }

  .ar-float-left {
    margin-right: 0;
  }

  .ar_setting-menu-text a {
    padding-right: 0;
  }

  .ar_claims_container {
    margin: 0 10px 0 0;
  }

  .ar_claims_duration {
    margin-right: 0px;
  }

  .ar_history_claims_container {
    margin: 48px 0 60px;
  }
}

@media (max-width: 567px) {
  .ar_claims_container {
    margin: 0px;
    padding: 20px;
  }

  .ar_claims_history_title {
    margin-right: 20px;
  }

  .ar_claim_btn,
  .ar_claim_btn:hover,
  .ar_claim_btn:focus,
  .ar_claims_select.ant-select,
  .ar_claims_property.ant-select {
    width: 100%;
  }

  .ar_claims_property.ant-select,
  .ar_claims_property.ant-select .ant-select-selector {
    width: 100%;
  }

  .ar_claims_tab_label.description {
    width: 242px;
  }
}

.ar_languageSelectRadiosButtom span.ant-radio+* {
  font-family: "tajwal-medium";
  font-size: 16px;
}

.ar_languageSelectRadiosButtom .ant-radio-wrapper {
  margin: 0 30px;
}

@media (max-width: 361px) {
  .ar_claims_title {
    font-size: 20px;
  }
}

@media (max-width: 571px) {
  .ar_language_container {
    margin-left: 0;
    margin-right: 0;
  }
}

.ar_PaymentSuccess-Block {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.ar_PaymentSuccess-img {
  width: 114px;
  height: 114px;
}

.ar_PaymentSuccess-successTxt {
  font-size: 32px;
  font-family: "tajwal-bold";
  text-align: center;
  margin-bottom: 20px;
  direction: rtl;
}

.ar_PaymentSuccess-enjoyTxt {
  font-size: 24px;
  color: #808080;
  font-family: "tajwal-medium";
  text-align: center;
  direction: rtl;
}

.ar_PaymentSuccess-ButtonDashboard {
  width: 255px;
  height: 48px;
  background-color: #27aad8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  margin-top: 48px;
  font-family: "tajwal-bold";
  cursor: pointer;
}

.ar_record_payment_view {
  color: #212121;
  text-decoration: underline;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
}

.ar_beta_logo_navbar_container {
  display: flex;
  height: 16px;
  width: 143px;
  margin-top: 5px;
}

.ar_notFoundPageContainer img {
  width: 100%;
  max-width: 642px;
  display: block;
  margin: auto;
  margin-right: auto;
  margin-top: 55px;
  margin-bottom: 45px;
}

.ar_discount_label {
  font-size: 14px;
  font-family: "tajwal-medium";
  color: #B3B3B3;
}

@media only screen and (min-width:1000px) and (max-width: 1200px) {
  .ar_notFoundPageContainer img {
    width: 45%;
  }
}

.ar_basicMaintenance_title {
  font-size: 24px;
  color: #818181;
  margin-bottom: 54px;
  font-family: "tajwal-regular";
  text-align: center;
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.ar_notFoundPageTitle div {
  font-family: 'tajwal-regular';
  font-size: 44px;
  color: #212121;
  line-height: auto;
}

.ar_notFoundPageTitle span {
  font-family: 'tajwal-regular';
  font-size: 24px;
  color: #818181;
}

@media screen and (max-width: 1200px) {
  .ar_beta_logo_navbar_container {
    margin-top: 3px;
  }

  .ar_PayAdvance {
    margin-left: 0;
  }

  .ar_containerDashboard {
    max-width: 100%;
  }

  .ar_PaymentContainer {
    margin-right: 15px;
    width: auto;
  }

  .ar_table {
    margin-right: 15px;
  }

  .ar_pay_in_advance_col {
    display: flex;
    justify-content: flex-end;
    padding-left: 15px;
    align-self: flex-end;
  }

  .ar_PayAdvance {
    margin-bottom: 21px;
  }

  .ar_payment-btn-container {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .ar_discussion_logo_container {
    margin-right: 0px;
  }

  .ar_conversation-fleche-bubble {
    right: 95px;
  }

  .ar_messenger {
    height: calc(100vh - 80px);
    grid-template-columns: 315px auto;
  }

  .ar_compose {
    width: calc(100% - 315px);
  }

  .ar_maintenance-btn-container {
    padding-right: 15px;
  }

  .ar_setting-profile-editProfile-avatar {
    display: flex;
  }

  .ar_setting-profile-editProfile-upload {
    display: flex;
  }
}

.ar_isOnline {
  position: absolute;
  right: 0;
  transform: scaleX(-1);
}

.ar_isOnlineResp {
  margin-right: 8px;
  display: block;
}

@media screen and (max-width: 576px) {
  .ar_beta_logo_navbar_container {
    height: 12px;
    width: 114px;
    margin-top: 5px;
  }

  .ar_setting-profile-editProfile-avatar {
    display: inline-block;
  }

  .ar_setting-profile-editProfile-upload {
    display: inline-block;
  }

  .ar_pay_in_advance_col {
    display: flex;
    justify-content: flex-start;
  }
}

.ar_CPUpper_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPUpper_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPLower_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPLower_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPNumber_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPNumber_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPMinCar_successful_true {
  font-size: 12px;
  margin-bottom: 0px;
}

.ar_CPMinCar_successful_false {
  font-size: 12px;
  margin-bottom: 0px;
}

.ar_CPSpecialChar_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_CPSpecialChar_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.ar_PayAdvance.ant-tooltip-disabled-compatible-wrapper button {
  width: 100%;
  height: 100%;
}

.ar_claims_property.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  font-size: 14px;
}

.tenantive-navbar-link:hover {
  color: #212121;
  text-decoration: none;
}

.ar_contractRenewText {
  font-size: 14px;
  font-family: 'tajwal-medium';
  color: #B3B3B3;
}

.ar_contractRenewTextBold {
  font-size: 14px;
  font-family: 'tajwal-bold';
  color: #B3B3B3;
}

.ar_msgNo_readt {
  margin-top: 9px;
  margin-right: auto;
  display: block;
}

.ar_settings_icon {
  padding-left: 0px !important;
}

.ar_navbar-padding {
  padding-top: 4px;
}

.ar_reciept-info {
  display: flex;
  justify-content: flex-end;
}

.ar_lang_style {
  margin-right: 10px;
  cursor: pointer;
  font-family: "Avenir-Next-Medium";
  vertical-align: middle;
  color: #ffffff;
}

.ar_global-lang-icon {
  width: 19px;
  height: 19px;
  position: fixed;
  left: 80%;
  top: 2%;
}

.ar_bt-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: fixed;
  left: 8%;
  top: 2%;
}

.ar_navbar_logo_container {
  display: flex;
  flex-direction: column;
  padding-right: 5%;
  padding-top: 1%;
}
.ar_white_beta_logo{
  margin-top: 5px;
}
.ar_pswTenantnewPassword {
  height: 16.5px;
  font-family: 'tajwal-bold';
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}
.ar_pswTenantRestPasswordContainer {
  padding-right: 13%;
  font-family: "tajwal-bold";
}
.ar_ConfirmPassResetTenant {
  font-family: "tajwal-medium";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}
.ar_phone-number-error {
  color: #cb3d3d;
  font-family: "tajwal-medium";
  padding: 0px 40px;
}

.ar_pwd_placeholder {
  font-family: "tajwal";
}

.ar_phone_number_error {
  color: #cb3d3d;
  font-family: "tajwal-medium";
  padding: 0px 40px;
}
input::placeholder{
  font-family: "tajwal";
}

.ar_tenantNumber_btn .ant-btn:focus > span {
  font-family: 'tajwal-bold';
}
.ar_pwd_placeholder input::placeholder{
  font-family: 'tajwal-bold';
}

.ar_rewrite_phone_number{
  font-family: 'tajwal-bold';
}