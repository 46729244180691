@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
.left {
  background-color: #36d1e0;
}
.image {
  width: 50%;
  height: 50%;
}
.shadow {
  border: 2px solid #cccccc;
}
.link {
  color: #27aad8;
}
@media (max-width: 767px) {
  .left {
    display: none;
  }
  .right {
    margin: "auto";
    margintop: "20%";
    maxwidth: 500;
    minwidth: 400;
  }
}
