@font-face {
  font-family: "SF-Pro-Display-Bold";
  src: url(./fonts/bold/SF-Pro-Display-Bold.otf) format("Opentype");
  src: url(./fonts/bold/SFProDisplay-Bold.eot);
  src: url(./fonts/bold/SFProDisplay-Bold.eot?#iefix) format("embedded-opentype"), url(./fonts/bold/SFProDisplay-Bold.woff2) format("woff2"), url(./fonts/bold/SFProDisplay-Bold.woff) format("woff"), url(./fonts/bold/SFProDisplay-Bold.ttf) format("truetype"), url(./fonts/bold/SFProDisplay-Bold.svg#SFProDisplay-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display-Medium";
  src: url(./fonts/medium/SF-Pro-Display-Medium.otf) format("Opentype");
  src: url(./fonts/medium/SFProDisplay-Medium.eot);
  src: url(./fonts/medium/SFProDisplay-Medium.eot?#iefix) format("embedded-opentype"), url(./fonts/medium/SFProDisplay-Medium.woff2) format("woff2"), url(./fonts/medium/SFProDisplay-Medium.woff) format("woff"), url(./fonts/medium/SFProDisplay-Medium.ttf) format("truetype"), url(./fonts/medium/SFProDisplay-Medium.svg#SFProDisplay-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url(./fonts/regular/SF-Pro-Display-Regular.otf) format("Opentype");
  src: url(./fonts/regular/SFProDisplay-Regular.eot);
  src: url(./fonts/regular/SFProDisplay-Regular.eot?#iefix) format("embedded-opentype"), url(./fonts/regular/SFProDisplay-Regular.woff2) format("woff2"), url(./fonts/regular/SFProDisplay-Regular.woff) format("woff"), url(./fonts/regular/SFProDisplay-Regular.ttf) format("truetype"), url(./fonts/regular/SFProDisplay-Regular.svg#SFProDisplay-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display-Semibold";
  src: url(./fonts/semi-bold/SF-Pro-Display-Semibold.otf) format("Opentype");
  src: url(./fonts/semi-bold/SFProDisplay-Semibold.eot);
  src: url(./fonts/semi-bold/SFProDisplay-Semibold.eot?#iefix) format("embedded-opentype"), url(./fonts/semi-bold/SFProDisplay-Semibold.woff2) format("woff2"), url(./fonts/semi-bold/SFProDisplay-Semibold.woff) format("woff"), url(./fonts/semi-bold/SFProDisplay-Semibold.ttf) format("truetype"), url(./fonts/semi-bold/SFProDisplay-Semibold.svg#SFProDisplay-Semibold) format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro-Display-Heavy";
  src: url(./fonts/heavy/SF-Pro-Display-Heavy.otf) format("Opentype");
  src: url(./fonts/heavy/SFProDisplay-Heavy.eot);
  src: url(./fonts/heavy/SFProDisplay-Heavy.eot?#iefix) format("embedded-opentype"), url(./fonts/heavy/SFProDisplay-Heavy.woff2) format("woff2"), url(./fonts/heavy/SFProDisplay-Heavy.woff) format("woff"), url(./fonts/heavy/SFProDisplay-Heavy.ttf) format("truetype"), url(./fonts/heavy/SFProDisplay-Heavy.svg#SFProDisplay-Heavy) format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Next-Demi-Bold";
  src: url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.eot);
  src: url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.eot?#iefix) format("embedded-opentype"), url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.woff2) format("woff2"), url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.woff) format("woff"), url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.ttf) format("truetype"), url(./fonts/AvenirDemiBold/AvenirNext-DemiBold.svg#AvenirNext-DemiBold) format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Next-Medium";
  src: url(./fonts/AvenirMedium/AvenirNext-Medium.eot);
  src: url(./fonts/AvenirMedium/AvenirNext-Medium.eot?#iefix) format("embedded-opentype"), url(./fonts/AvenirMedium/AvenirNext-Medium.woff2) format("woff2"), url(./fonts/AvenirMedium/AvenirNext-Medium.woff) format("woff"), url(./fonts/AvenirMedium/AvenirNext-Medium.ttf) format("truetype"), url(./fonts/AvenirMedium/AvenirNext-Medium.svg#AvenirNext-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Next-Regular";
  src: url(./fonts/AvenirRegular/AvenirNext-Regular.eot);
  src: url(./fonts/AvenirRegular/AvenirNext-Regular.eot?#iefix) format("embedded-opentype"), url(./fonts/AvenirRegular/AvenirNext-Regular.woff2) format("woff2"), url(./fonts/AvenirRegular/AvenirNext-Regular.woff) format("woff"), url(./fonts/AvenirRegular/AvenirNext-Regular.ttf) format("truetype"), url(./fonts/AvenirRegular/AvenirNext-Regular.svg#AvenirNext-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Next-Bold";
  src: url(./fonts/AvenirBold/AvenirNext-Bold.eot);
  src: url(./fonts/AvenirBold/AvenirNext-Bold.eot?#iefix) format("embedded-opentype"), url(./fonts/AvenirBold/AvenirNext-Bold.woff2) format("woff2"), url(./fonts/AvenirBold/AvenirNext-Bold.woff) format("woff"), url(./fonts/AvenirBold/AvenirNext-Bold.ttf) format("truetype"), url(./fonts/AvenirBold/AvenirNext-Bold.svg#AvenirNext-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import "~antd/dist/antd.css";
/* h1 {
  font-family: "SF-Pro-Display-Bold";
} */

body {
  letter-spacing: 0px;
}

.en_bigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
  overflow: hidden;
}

.en_bigContainerLoginPage {
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
}

.emptyHalf {
  background-color: #36d1e0;
}

.notEmptyHalf {
  background-color: white;
}

.fullHeight {
  height: 100%;
}

.loginBigTitle {
  font-family: "SF-Pro-Display-Bold";
  color: white;
  font-size: 28px;
  overflow: hidden;
  width: calc(100% - 50px);
  text-align: left;
  margin-left: 150px;
  margin-top: -50px;
  top: 50%;
  position: absolute;
  left: 0%;
}

.loginLogo {
  font-family: "SF-Pro-Display-Bold";
  color: white;
  font-size: 56px;
  border: 10px solid white;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 150px;
  padding-left: 20px;
  line-height: 1.2;
}

.inpuText {
  color: #212121;
}

.inputTextLabel {
  color: #666666;
}

.errorInput {
  border-color: #c62828;
}

.validInput {
  border-color: #388e3c;
}

.errorLabel {
  color: #c62828;
  font-size: 12px;
  font-weight: 600;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: #cccccc;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #333333;
  border-left: 0px;
  border-top: 0px;
}

.pinOn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 26px;
  margin-left: -13px;
  margin-top: -10px;
}

/*-------Tenant------------*/

.en_addTenant-uploader .ant-upload.ant-upload-select-picture-card>.ant-upload {
  border: none;
  width: 100%;
  height: 250px;
}

.en_addTenant-uploader .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  margin-left: 0px;
}

.ant-upload.ant-upload-drag.en_addTenant_drag {
  border-radius: 15px;
  background: none;
  height: 250px;
}

.en_addTenant_select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 0px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: transparent;
}

.ant-select.en_addTenant_select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  height: 40px;
}

.en_addTenant_container {
  margin-left: 12%;
  margin-top: 40px;
}

.en_addTenant_Subtitle {
  color: #808080;
  margin-top: 25px;
}

.en_addTenant_hr {
  border: 1px solid #cccccc;
  background-color: #cccccc;
}

.en_addTenant_detailsContainer {
  margin-top: 20px;
}

.en_addTenant_optional {
  color: #b3b3b3;
}

.en_addTenant_unitNum {
  font-size: 18px;
  color: #808080;
}

.en_addTenant_label {
  font-size: 14px;
  font-weight: 450;
  margin-bottom: 5px;
}

#en_addTenant_btn {
  height: 40px;
  width: 250px;
  color: #5fb563;
  font-size: 14px;
  font-weight: 500;
  background-color: #e4f3e5;
  border-color: #e4f3e5;
  border-radius: 4px;
  margin: 35px 0 0 0;
}

#en_inviteTenant_btn {
  height: 40px;
  width: 250px;
  color: #27aad8;
  font-size: 14px;
  font-weight: 500;
  background-color: #d3eef7;
  border-color: #d3eef7;
  border-radius: 4px;
  margin: 35px 0 0 0;
}

.en_inviteTenant_or {
  font-size: 14px;
  font-weight: bolder;
  color: #b3b3b3;
  text-align: center;
  padding-top: 6px;
}

.en_inviteTenant_unitInfo {
  margin-top: 25px;
}

.en_editTenant_photo {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
}

#en_editTenant_saveBtn {
  width: 135px;
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  margin-right: 25px;
  margin-bottom: 30px;
}

#en_editTenant_cancelBtn {
  width: 135px;
  color: #212121;
  font-weight: 500;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 30px;
}

.en_editTenant_dragText {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-around;
}

.en_editTenant_btn_file_names {
  text-align: center;
  position: absolute;
  top: 5px;
  margin: auto;
  width: 100%;
  left: 0px;
  height: 125px;
}

.en_add_Floor {
  margin-right: 35px;
}

.en_transfers-date-picker {
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#en_date-btn-transfers {
  width: 47px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

#en_date-btn-transfers:hover {
  width: 47px;
  background-color: #d9d9d9;
  border-color: #cccccc;
}

/*-----Property--------*/

.en_addProp_bigContainer {
  border-right: 1px solid #cccccc;
}

.en_addProp_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.en_addProp_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 250px;
  left: 300px;
  overflow: visible;
}

.en_addProp_vl {
  border-left: 1px solid #cccccc;
  height: 100vh;
}

.en_addProp_logo {
  margin-left: 10%;
  margin-top: 5%;
}

.en_addProp_avatar-uploader .ant-upload.ant-upload-select-picture-card {
  border: none;
  height: 250px;
  border-radius: 8px;
}

.en_addProp_avatar-uploader .ant-upload.ant-upload-select-picture-card>.ant-upload {
  border: none !important;
  border-radius: 8px;
}

.en_addProp_text {
  font-size: 24px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 10px;
}

.en_addProp_search {
  width: 50%;
  margin-left: 10%;
  margin-top: 5px;
  height: 40px;
  padding: 5px 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.en_addProp_pin {
  width: 18px;
  height: 18px;
}

.en_addProp_textPin {
  margin-left: 10%;
  margin-top: 2%;
  color: #b3b3b3;
  font-size: 15px;
}

.en_addProp_map {
  margin-left: 10%;
  margin-top: 2%;
  height: 100%;
  width: 50%;
}

.en_addProp_skip {
  cursor: pointer;
  color: #666666;
  font-size: 16;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.en_addProp_btn {
  font-size: 16px;
  font-weight: 550;
  width: 250px;
  height: 40px;
  margin-left: 10%;
  margin-top: 10%;
  background-color: #27aad8;
  border-color: #27aad8;
  border-radius: 4px;
}

.en_addProp_btn:hover {
  background-color: #27aad8;
  border-color: #27aad8;
}

.en_addProp_avatar-uploader.ant-upload-picture-card-wrapper {
  width: 27%;
  margin-left: 10%;
}

.en_addProp_link {
  margin-left: 10%;
  padding-top: 10px;
  font-size: 20px;
  color: #27aad8;
  font-weight: 600;
}

.en_addProp_link:hover {
  color: #27aad8;
}

.en_addProp_description {
  margin-left: 10%;
  padding-top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #808080;
}

.en_addProp_line {
  border-left: 1px solid #cccccc;
  margin-top: -5px;
  margin-left: 10%;
  width: 40%;
}

.en_addProp_animTxt {
  color: #cccccc;
  font-size: 32px;
  font-weight: bold;
}

.en_addProp_Input {
  width: 30%;
  margin-left: 10%;
  padding-top: 10px;
}

.en_addProp_Label {
  margin-left: 10%;
  padding-top: 10px;
}

/*----welcome----*/

.en_welcome_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin-top: -50%;
  margin-left: -50%;
}

.en_welcome_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 50%;
  left: 50%;
  overflow: visible;
}

.en_welcome_vl {
  border-left: 1px solid #cccccc;
  height: 100vh;
}

.en_welcome_logo {
  margin-left: 10%;
  margin-top: 5%;
}

.en_welcome_thanks {
  font-size: 32px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 5%;
}

.en_welcome_description {
  font-size: 24px;
  font-weight: 500;
  color: #808080;
  margin-left: 10%;
  margin-top: 2%;
}

.en_welcome_line {
  border-left: 1px solid #cccccc;
  margin-top: 10%;
  margin-left: 10%;
  width: 70%;
}

.en_welcome_steps {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10%;
  margin-left: 10%;
}

.en_welcome_checkbox {
  display: inline;
}

.en_welcome_step {
  font-size: 18px;
  font-weight: 450;
  color: #808080;
  padding-left: 5px;
  display: inline;
}

.en_welcome_stepsBlockTitle {
  font-size: 18px;
  font-weight: bold;
}

.en_welcome_stepsBlock {
  width: 40%;
  margin-top: 3%;
  margin-left: 10%;
}

.en_welcome_btn {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 550;
  width: 30%;
  height: 48px;
  margin-left: 10%;
  margin-top: 5%;
  background-color: #27aad8;
  border-color: #27aad8;
}

.en_welcome_btn:hover {
  background-color: #27aad8;
  border-color: #27aad8;
}

.en_addProp_containerElement {
  position: relative;
  height: 400px;
  overflow: hidden;
  margin-left: 10%;
  margin-right: 10%;
}

.ant-upload-hint {
  font-size: 12px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  /* border: solid 1px rgba(202, 199, 199, 0.692); */
  border: none;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: rgba(202, 199, 199, 0.692);
}

.ant-upload.ant-upload-drag {
  border-radius: 15px;
  background: none;
  height: 180px;
  width: 180px
}

.ant-upload-text,
.en_ant-upload-textt {
  color: #27aad8;
  font-size: 14px;
}

.ant-upload-list-item-image {
  border-radius: 5px;
  margin-right: 200px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  width: 120px;
  height: 100px;
}

.ant-upload-list-item {
  display: flex;
  flex-direction: column;
}

#templateBtn {
  padding: 8%;
  margin-bottom: 10px;
  width: 400px;
  height: 100%;
  border-radius: 15px;
  border: solid 1px #cccccc;
}

#donwload-bulk-txt {
  margin-top: 20px;
  font-weight: 450;
  color: #27aad8;
  font-size: 15px;
}

#dnd-txt {
  color: #b3b3b3;
  font-size: 15px;
  margin-bottom: 20px;
}

#drag-bulk {
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 415px) {
  #templateBtn {
    width: 310px;
  }
}

@media (min-width: 768px) {
  #en_rentalsstatus-child {
    margin-left: 30px;
  }
}

/*-----bulk----*/

.en_bulk_logo {
  margin-left: 5%;
  margin-top: 2%;
}

.en_bulk_content {
  display: flex;
  justify-content: center;
}

.en_bulk_bulkCard {
  border-radius: 8px;
  text-align: center;
  padding: 1% 10%;
  margin-top: 1%;
}

.en_bulk_bulkTxt {
  color: gray;
}

.en_bulk_uploadtext {
  color: rgb(54, 200, 216);
}

.en_bulk_dragBulk {
  border-radius: 5px;
}

.en_bulk_templateBtn {
  padding: 10%;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

.en_bulk_pdf {
  font-size: 50px;
}

.en_bulk_bulkLink {
  font-size: 16px;
  line-height: 2;
  font-weight: 550;
  display: flex;
  align-self: start;
  color: #27aad8;
}

.en_bulk_navigBulk {
  margin-top: 25px;
}

.en_iconInputTextUikit {
  border-radius: 4px;
}

.en_bulk_btn {
  display: block;
  width: 100%;
  background-color: #27aad8;
  border-color: #27aad8;
  height: 40px;
}

.en_bulk_btn:hover {
  background-color: #27aad8;
}

.en_bulk_container {
  background-color: white;
  margin-left: 5%;
  border-radius: 10px;
  padding: 30px;
}

/*---congrats----*/

.en_tenantive-logo {
  margin-left: 15%;
  margin-top: 4%;
}

.en_congrats-title {
  font-weight: bolder;
  font-size: 40px;
  margin-left: 15%;
  margin-top: 7%;
}

.en_congratsUnit-title {
  font-size: 32px;
  font-weight: bold;
  margin-left: 15%;
  margin-top: 10%;
}

.en_congratsUnit_list {
  margin-left: 15%;
  margin-top: 50px;
  display: flex;
}

.en_congrats-sous-title {
  font-size: 24px;
  margin-left: 15%;
  color: #808080;
}

.en_congrats-title-container {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 8%;
}

.en_congrats-steps-title {
  font-weight: bolder;
  margin-left: 15%;
  margin-top: 10%;
}

.en_congrats-first-container {
  border-right: solid 1px #cccccc;
  height: 100vh;
}

.en_steps-container {
  margin-left: 15%;
  display: flex;
  justify-content: space-between;
  padding-right: 30%;
}

.en_steps-container div {
  display: flex;
  flex-direction: column;
}

.en_steps-container span {
  font-size: 15px;
  color: #808080;
  margin-top: 4px;
}

.en_congrats-check {
  color: #4ead53;
}

.en_congrats-btns {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 5%;
  width: 200px;
}

.en_congrats-addOther-btn {
  width: 100%;
  color: #27aad8;
  height: 40px;
  border: 2px solid #27aad8;
  margin-top: 10px;
  font-weight: 550;
  border-radius: 4px;
}

.en_congrats-addOther-btn:hover {
  color: #27aad8;
  border: 2px solid #27aad8;
}

.en_congrats-finish-btn {
  width: 100%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 50%;
}

.en_congratsUnit_link {
  color: #27aad8;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
}

.en_congratsUnit_link:hover {
  color: #27aad8;
}

.en_congrats-finish-btn:hover {
  background-color: #27aad8;
}

@media only screen and (max-width: 515px) {
  .en_steps-container {
    padding-right: 10%;
  }
}

@media (min-width: 992px) {
  .en_containerDashboard {
    max-width: 100%;
  }
}

.en_congrats-list-container {
  overflow: scroll;
  height: 100vh;
}

.en_congrats_list {
  display: flex;
  margin-left: 45px;
}

.en_congrats_list-span {
  display: flex;
  flex-wrap: wrap;
}

.en_congratsUnit_list span {
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-right: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 90px;
}

.en_congrats_list span {
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-right: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 90px;
}

.en_congrats_list-title {
  font-weight: bolder;
  padding-left: 5%;
  margin-top: 16%;
  margin-bottom: 25px;
}

.en_congrats_img-div {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.en_congrats-property-name {
  margin-top: 0;
}

#en_addMoreUnit {
  border: solid 1px #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 50%;
  color: #27aad8;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 450;
  margin-bottom: 90px;
  cursor: pointer;
}

.en_cong-more-unit-logo {
  width: 25px;
  height: 25px;
}

.en_congratsUnit_propName {
  font-size: 24px;
  font-weight: 500;
  color: #212121;
}

.en_multipleProp_container {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.en_multipleProp_list {
  margin-left: 15%;
  margin-top: 50px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 3%;
  width: 100%;
  min-height: 485px;
}

.en_multipleProp_list span {
  border: solid 1px #5fb563;
  background-color: #5fb563;
  border-radius: 50%;
  margin-right: 25px;
  color: white;
  width: 125px;
  height: 125px;
  padding-top: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 90px;
}

.en_multipleProp_btns_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.en_multipleProps_btns_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  min-height: 485px;
  margin-top: 50px;
  border-radius: 5px;
  margin-left: 15%;
}

/*---add Unit----*/

.en_addUnit_logo {
  margin-left: 20%;
  margin-top: 5%;
}

.en_addUnit_card {
  border: none;
  padding: 24px;
}

.en_addUnit_infoDiv {
  display: flex;
  justify-content: space-between;
}

.en_addUnit_unitNumberDiv {
  display: flex;
  flex-direction: column;
}

.en_addUnit_infoTitle {
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .en_addUnit_cardContainer {
    padding: 0%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 2000px) {
  .en_addUnit_cardContainer {
    padding: 0% 15% 0% 15%;
  }
}

@media only screen and (min-width: 765px) and (max-width: 991px) {
  .en_addUnit_cardContainer {
    padding: 0% 25% 0% 15%;
  }

  .en_addUnit_middle {
    text-align: center;
    padding-left: 35%;
    top: -100px;
  }

  .en_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .en_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 764px) {
  .en_addUnit_cardContainer {
    padding: 0% 25% 0% 15%;
  }

  .en_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .en_addUnit_middle {
    text-align: center;
    padding-left: 35%;
    top: -100px;
  }

  .en_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .en_addUnit_or {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .en_addUnit_middle {
    text-align: center;
    padding-left: 30%;
    top: -100px;
  }

  .en_addUnit_bulkBtn {
    width: 150px;
    height: 40px;
  }

  .en_addUnit_or {
    display: none;
  }
}

.en_addUnit_add {
  margin: 0 20px;
  border: none;
  /* width    : 20% ;  */
  width: 50px;
  padding-left: 10px;
  align-self: center;
}

.en_addUnit_inc_dec {
  display: flex;
}

.en_addUnit_or {
  color: #b3b3b3;
  position: absolute;
  right: -17px;
  border: solid 1px #b3b3b3;
  border-radius: 50%;
  padding: 7px;
  background-color: white;
  z-index: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 16px;
}

.en_addUnit_bigContainer {
  overflow: hidden;
}

.en_addUnit_cardContainer {
  margin-top: 15%;
  border-right: solid 1px #cccccc;
  height: 100%;
}

.en_addUnit_infoTitleBasic {
  font-weight: bolder;
  margin-left: 8%;
}

.en_addUnit_middle {
  text-align: center;
  margin-top: 18%;
}

.en_addUnit_middle h1 {
  color: #808080;
  font-weight: bolder;
}

.en_addUnit_rigth {
  text-align: center;
}

.en_addUnit_bulkBtn {
  background-color: #e6e6e6;
  color: #212121;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
  border: none;
}

.en_addUnit_noMore {
  display: none;
}

.en_addUnit_btn {
  margin-top: 85%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  display: block;
  width: 90%;
}

.en_addUnit_bulkLink {
  font-weight: 550;
  font-size: 16px;
  line-height: 2;
  display: flex;
  align-self: start;
  color: #27aad8;
  margin-top: 20px;
}

.en_addUnit_btn:hover {
  border-color: #27aad8;
  background-color: #27aad8;
}

.en_addUnit_btnMore {
  display: block;
  width: 50%;
  border-color: #27aad8;
  color: #27aad8;
  height: 40px;
}

.en_addUnit_btnNext {
  margin-top: 12%;
  background-color: #27aad8;
  height: 40px;
  border-color: #27aad8;
  display: block;
  width: 50%;
}

.en_bank-check-text label {
  border-radius: 50%;
}

.en_bank-check-text {
  position: relative;
  margin-right: 10px;
}

.en_bank-check-text label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.en_bank-check-text label:after {
  border: 2px solid #27aad8;
  background-color: #27aad8;
  border-radius: 50%;
  content: "";
  height: 12px;
  right: 15%;
  opacity: 0;
  position: absolute;
  top: 15%;
  transform: rotate(-45deg);
  width: 12px;
}

.en_bank-check-text input[type="checkbox"] {
  visibility: hidden;
}

.en_bank-check-text input[type="checkbox"]:checked+label {
  border: 1px solid #cccccc;
}

.en_bank-check-text input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

/* .en_addUnit_bulkLink {
  font-weight: bolder;
  display: flex;
  align-self: start;
  color: #27aad8;
} */

.en_addUnit_typeView {
  font-size: 18px;
  color: #212121;
  font-weight: 450;
}

.en_addUnit_furnishedView {
  font-size: 16px;
  color: #808080;
}

.en_addUnit_infoView {
  font-size: 16px;
  color: #212121;
  font-weight: bolder;
}

.en_addUnit_amenetiesView {
  font-size: 16px;
  color: #808080;
  margin-right: 15px;
}

.en_addUnit_limitView {
  border: 1px solid #cccccc;
  width: 70%;
  margin-top: 4%;
  position: relative;
  left: -17%;
}

.en_addUnit_moreInfo {
  display: flex;
  margin-bottom: 25px;
}

.en_amenetie_infoDiv {
  display: flex;
  justify-content: space-between;
}

.en_amenetie_logo {
  margin-left: 12%;
  margin-top: 5%;
}

.en_amenetie_bulkLink {
  font-weight: 550;
  font-size: 16px;
  line-height: 2;
  display: flex;
  margin-top: 20px;
  align-self: start;
  color: #27aad8;
}

.en_amenetie_navigBulk {
  margin-top: 25px;
}

.en_amenetie_bulkCard {
  border: none;
  text-align: center;
  width: 40%;
  margin-left: 13%;
  margin-top: 35px;
}

.en_amenetie_title {
  padding-left: 12.5%;
  padding-top: 4%;
  margin: 0;
}

.en_amenetie_bigContainer {
  height: 100vh;
  border-right: solid 1px #cccccc;
}

.en_amenetie_btn {
  display: block;
  width: 90%;
  background-color: #27aad8;
  margin-top: 58%;
  height: 40px;
}

.en_amenetie_btn:hover {
  background-color: #27aad8;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.en_boxLoading {
  background-color: #cccccc;
  height: 55px;
  width: 200px;
  position: absolute;
  align-self: center;
}

.boxLoading {
  background-color: #cccccc;
  height: 55px;
  width: 200px;
  position: absolute;
  align-self: center;
}

.stepLoading {
  position: absolute;
  height: 55px;
  width: 0px;
  background-color: #27aad8;
  animation-name: mymoveloding;
  animation-duration: 3s;
  left: 0px;
}

@keyframes mymoveloding {
  from {
    width: 0px;
  }

  to {
    width: 200px;
  }
}

.box {
  width: 500px;
  height: 500px;
  background: #5ab05e;
  position: absolute;
  /*animation: mymoveUnit 2s;*/
  padding-top: 10%;
  top: 500px;
}

.box-steps-1 {
  animation: mymoveUnit 1s;
  top: 250px;
}

.box-steps-2 {
  animation: mymoveUnit2 1s;
  top: 0px;
}

.box-steps-3 {
  animation: mymoveUnit3 1s;
  top: 0px;
}

.Unitimage {
  position: relative;
  top: -1px;
}

.unit_backgroundAnimation {
  width: 500px;
  height: 497px;
  background-color: #cccccc;
  overflow: hidden;
  position: absolute;
  left: 55%;
  margin-left: -219px;
}

.next-btn {
  position: absolute;
  top: 550px;
}

@keyframes mymoveUnit {
  from {
    top: 500px;
  }

  to {
    top: 250px;
  }
}

@keyframes mymoveUnit2 {
  from {
    top: 250px;
  }

  to {
    top: 0px;
  }
}

@keyframes mymoveUnit3 {
  from {
    top: 0px;
  }

  to {
    top: 0px;
  }
}

.en_addProp_anim_img {
  margin-left: 150px;
}

/*----notif----*/

.en_bank-added-notification {
  border-left: 7px solid #4ead53;
  z-index: 100;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: none;
  height: 90px;
  padding-right: 35px;
  margin-top: 0px;
  float: right !important;
  width: fit-content;
}

.en_bank-added-icon {
  width: 35px;
  margin-top: 5px;
  left: 330px !important;
}

.en_bank-note-txt {
  color: #4ead53;
  font-weight: bold;
  line-height: 2;
  margin-top: 5px;
}

/*---property anim---*/

.box-step-1 {
  animation: mymove 1s;
  top: 300px !important;
}

.box-step-2 {
  animation: mymove2 1s;
  top: 100px !important;
}

.box-step-3 {
  animation: mymove3 1s;
  top: 0px !important;
}

.en_property_anim_image {
  position: relative;
  top: -1px;
}

.backgroundAnimationProperty {
  width: 501px;
  height: 497px;
  background-color: #cccccc;
  overflow: hidden;
  position: absolute;
  left: 55%;
  margin-left: -250px;
}

@keyframes mymove {
  from {
    top: 500px;
  }

  to {
    top: 300px;
  }
}

@keyframes mymove2 {
  from {
    top: 300px;
  }

  to {
    top: 100px;
  }
}

@keyframes mymove3 {
  from {
    top: 100px;
  }

  to {
    top: 0px;
  }
}

/*----dashboard----*/

@import url("https://fonts.en_dashbord_googleapis.en_dashbord_com/css?family=Source+Sans+Pro&display=swap");

.hr-property-card {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #f0f0f0;
}

.ant-card-body {
  padding: 0px;
}

.en_dashboard_spinContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.en_dashboard_spinText {
  font-size: 16px;
  font-weight: 500;
  color: #27aad8;
  margin-top: 5px;
}

.en_dashbord_bigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
}

.en_dashbord_site {
  margin: 20px;
}

.en_dashbord_textWithBorder {
  border-style: solid;
  border-color: #0000ff;
  border-width: 4px;
}

.en_dashbord_titleBankAccount {
  font-size: 20px;
  color: #212121;
}

.en_dashbord_textNumberUnit {
  font-size: 15px;
  color: #212121;
  font-weight: bold;
  padding-right: 10px;
}

.en_dashbord_textGreen {
  font-size: 15px;
  color: #5fb563;
  font-weight: bold;
}

.en_dashbord_textGreen2 {
  font-size: 16px;
  color: #5fb563;
  font-weight: bold;
}

.en_dashbord_textGreyLabel {
  color: #808080;
  font-size: 16px;
}

.en_dashbord_textGrey {
  font-size: 16px;
  color: #808080;
  margin-left: 13px;
  font-weight: 500;
}

.en_dashbord_Total {
  font-size: 16px;
  color: #808080;
  margin-left: 13px;
  font-weight: 400;
}

.en_dashbord_adress {
  font-size: 15px;
  color: #808080;
  padding-left: 20px;
}

.en_dashbord_connectBankTextGrey {
  font-size: 13px;
  color: #b3b3b3;
  padding-bottom: 20px;
}

.en_dashbord_titleText {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
  margin-left: 20px;
  padding-top: 20px;
}

.en_dashbord_textRed {
  font-size: 15px;
  color: #cb3d3d;
  font-weight: bold;
}

.en_dashbord_directionRight {
  text-align: end;
  padding-right: 20px;
  margin: 5px 0;
}

.en_dashbord_centerImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3eef7;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.en_dashbord_BtnConnect {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-left: 30;
}

.en_dashbord_cardColPadding {
  margin-left: 20px;
  margin-top: 20;
  margin-bottom: 20;
}

.en_dashbord_col-md-6 {
  display: inline;
}

.en_dashbord_editProp {
  cursor: pointer;
  display: block;
  float: right;
  color: #27aad8;
  font-size: 16px;
  font-weight: 500;
}

.en_dashbord_containerBankAccount {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.en_dashbord_Btn {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-left: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 45px;
  margin: 40px 0 45px;
}

.en_dashbord_Btn:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  color: white;
}

.en_dashbord_BtnBank {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-left: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 35px;
}

.en_dashbord_BtnBank:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  padding-left: 30;
  border-radius: 5px;
  color: white;
  width: 50%;
  height: 35px;
}

#dashbord-btn-prop {
  margin-left: 18px;
  font-size: 16px;
  font-weight: 600;
  border: solid 2px #cccccc;
  background-color: transparent;
  padding: 15px 20px 10px;
  border-radius: 5px;
  width: 180px;
  height: 40px;
  text-align: center;
  line-height: 0;
  margin-bottom: 20px;
}

#dashbord-btn-prop-link {
  color: #666666;
}

#connectBankStyle {
  color: white;
}

.en_dashbord_location {
  margin-left: 7px;
  order: 2;
}

.en_btn-dash-bank {
  background-color: #27aad8;
  border-color: #27aad8;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  width: 50%;
  height: 35px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
}

.en_dashbord_msg_setting {
  float: right;
}

.en_containerDashboard {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .en_containerDashboard {
    max-width: 80%;
  }
}

.ant-menu-horizontal>.ant-menu-item-selected .tenantive-navbar-link {
  border-bottom-color: white;
  color: #36D1E0;
  font-size: 18px;
  font-weight: 500;
}

.tenantive-navbar-link {
  color: #b3b3b3;
  font-size: 18px;
  font-weight: 500;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.tenantive-navbar-link:hover {
  color: #212121;
  text-decoration: none;
}

.ant-menu-horizontal>.ant-menu-item-selected {
  border-bottom-color: white;
  color: #212121;
}

.ant-menu-horizontal>.ant-menu-item-selected .tenantive-navbar-link:hover {
  border-bottom-color: white;
  color: #212121;
}

.ant-menu-horizontal>.ant-menu-item-active:hover,
.ant-menu-horizontal>.ant-menu-item:hover .tenantive-navbar-link {
  border-bottom-color: white;
  color: #212121 !important;
}

.ant-menu-horizontal>.ant-menu-submenu-selected {
  border-color: white;
  color: #b3b3b3;
}

.ant-menu-item a:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  border-color: white;
  color: #808080 !important;
}

.ant-menu-item-disabled {
  cursor: text;
}

/*----Edit unit ---*/

.en_editUnit_bigContainer {
  margin-left: 8%;
  margin-top: 40px;
}

.containerDashboard {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.en_editUnit_BasicInfo_Container {
  margin-bottom: 35px;
}

.en_editUnit_BasicInfo_form {
  padding: 25px 80px;
  border: solid 1px #cccccc;
  border-radius: 4px;
  height: 100%;
}

.en_editUnit_btns {
  margin-left: 10%;
  margin-top: 50px;
}

.en_editUnit_hr {
  margin-left: 55px;
  border: 1px solid #cccccc;
  width: 90%;
}

.en_editUnit_btn_container {
  margin-left: 55px;
  margin-top: 15px;
}

.en_editUnit_label {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}

.ant-switch-checked {
  background-color: #27aad8;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .en_editUnit_BasicInfo_form {
    padding: 25px 40px;
  }
}

@media only screen and (min-width: 765px) and (max-width: 860px) {
  .en_editUnit_BasicInfo_form {
    padding: 25px 40px;
  }
}

.en_container_profiletop {
  background-color: white;
  margin-left: 5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px;
  margin-right: 27px;
}

.en_container_profilebottom {
  background-color: white;
  margin-left: 5%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 32px 40px 40px 40px;
  margin-right: 27px;
}

.en_titles {
  font-size: 14px;
  color: #666666;
  line-height: 19px;
  font-family: "SF-Pro-Display-Medium";
  margin-bottom: 8px;
}

.en_phone_number_label {
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
  line-height: 19px;
  font-family: "SF-Pro-Display-Medium";
}

@media only screen and (max-width: 567px) {
  .en_phone_number_label {
    margin-top: 0px;
  }
}

.ant-input {
  color: #000;
}

.en_info {
  color: #212121;
  font-size: 18px;
  font-family: "SF-Pro-Display-Medium";
}

.en_infoname {
  font-family: "SF-Pro-Display-Medium";
  color: #212121;
  font-size: 18px;
}

.en_infonameResponsive {
  display: none;
}

.en_data {
  color: #808080;
  font-size: 16px;
  font-family: "SF-Pro-Display-Regular";
}

.en_infoEmail {
  color: #808080;
  font-size: 16px;
  font-family: "SF-Pro-Display-Regular";
}

.en_infoEmailResponsive {
  display: none;
}

.en_ChangePass {
  color: #808080;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
}

.en_saveeditButton {
  font-size: 16px;
  color: #27aad8;
  line-height: 23px;
  font-family: "SF-Pro-Display-Semibold";
  padding: 0px;
  margin-top: 10px;
}

.en_saveeditButton:hover {
  color: #27aad8;
}

.en_saveeditButton:focus {
  color: #27aad8;
}

.en_changePassordButton {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  color: #4d4d4d;
  font-size: 14px;
  width: 140px;
  font-family: "SF-Pro-Display-Semibold";
  margin: 24px 0 8px 0;
  box-shadow: none;
  text-shadow: none;
}

.en_updatePasswordButton {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "SF-Pro-Display-Medium";
  color: white;
  margin-right: 24px;
}

.en_updatePasswordButton:focus {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "SF-Pro-Display-Medium";
  color: white;
}

.en_updatePasswordButton:hover {
  background-color: #27aad8;
  border-color: #27aad8;
  color: white;
}

.ant-input {
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
}

.en_cancelButton,
.en_cancelButton:hover,
.en_cancelButton:focus {
  border-radius: 4px;
  height: 40px;
  width: 140px;
  font-size: 14px;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  color: #4d4d4d;
  font-family: "SF-Pro-Display-Medium";
}

.en_divider_profile {
  width: 110%;
  height: 2px;
  margin-left: -30px;
  color: #b3b3b3;
}

@media (min-width: 992px) {
  .en_inputField {
    margin-top: 100px;
  }
}

@media (max-width: 992px) {
  .en_inputField {
    margin-top: 10px;
  }

  .en_profileInfo {
    display: inline-block;
  }

  .en_divider_profile {
    width: 114%;
  }
}

.en_message_logo_not {
  font-family: "SF-Pro-Display-Bold";
  font-size: 10px;
  position: absolute;
  right: 0px;
  left: 18px;
  bottom: 18px;
  background-color: #cb3d3d;
  border: 1px solid #cb3d3d;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .en_message_logo_not {
    left: 80px;
  }
}

@media (max-width: 567px) {
  .en_divider_profile {
    width: 130%;
  }

  .en_message_logo_not {
    left: 80px;
  }
}

.en_setting_profile_editPassword {
  margin-top: 12px;
}

.en_setting_profile_editPassword_erreurMessage {
  color: #cb3d3d;
}

.en_setting_profile_editPassword_uploadcancelbutton {
  margin-top: 30px;
}

.en_setting_profile_editPassword_alert {
  font-size: 12;
}

.en_setting-profile-editProfile-image {
  text-align: center;
}

.en_setting-profile-editProfile-avatar {
  display: inline-block;
}

.en_setting-profile-editProfile-upload {
  display: inline-block;
  width: 100%;
}

.en_setting-profile-editPassword-input {
  width: 30%;
}

.en_setting-profileInfo-info {
  margin-top: 10px;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.en_dataa {
  color: #000000;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  /* font-weight: bold; */
}

.en_none {
  display: none;
}

.en_display {
  display: none;
}

.en_inviteUser_container {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  color: #212121;
}

.en_user-container {
  background-color: white;
  min-width: 558px;
  padding: 10px;
  margin-bottom: 20px;
  min-height: 150px;
  border-right: 5px solid #5fb563;
}

.en_title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 23px;
  width: fit-content;
  display: block;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 80px;
  color: #212121;
}

.en_sub_tittle {
  width: fit-content;
  font-size: 18px;
  line-height: 19px;
  font-weight: normal;
  font-style: normal;
  font-family: "SF-Pro-Display-Medium";
}

.en_viewchart-col>* {
  margin-bottom: 10px;
}

.en_signupTxt {
  color: gray;
  font-size: 16px;
}

.en_col>* {
  margin-bottom: 20px;
}

.en_label {
  width: fit-content;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  font-weight: normal;
}

.en_optional_label {
  width: fit-content;
  color: #b3b3b3;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  margin-left: 85px;
  position: absolute;
  right: 0;
}

.en_cancel_button,
.en_invite_button {
  width: 100%;
  border-radius: 5px;
}

.en_cancel_button {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.en_cancel_button:hover {
  background-color: #e6e6d6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.en_user-name {
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-bottom: 20px;
}

.en_user-details {
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_editButton {
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #27aad8;
}

.en_setting-user-editUser-divider {
  margin-top: 10px;
  height: 2px;
}

.en_editUser-label {
  margin: 0%;
  padding: 0%;
  display: flex;
}

.en_EditUser_select {
  width: 100%;
}

.en_EditUser_checkbox {
  width: 100%;
  display: flex;
  margin: auto;
}

.en_User-container {
  padding: 30px;
  margin-left: 5%;
}

.en_User-invitebutton {
  margin-bottom: 30px;
}

.en_User-invite-buttonn {
  width: 40%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?xbtzsm");
  src: url("./fonts/icomoon.eot?xbtzsm#iefix") format("embedded-opentype"), url("./fonts/icomoon.ttf?xbtzsm") format("truetype"), url("./fonts/icomoon.woff?xbtzsm") format("woff"), url("./fonts/icomoon.svg?xbtzsm#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.en_VacantUnitView_select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  height: 40px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.en_vacantUnitView_TableIcon-style {
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
  display: inline;
}

.en_vacantUnitView-datepicker {
  display: flex;
}

.en_vacantUnitView-dateicon {
  width: 30%;
  display: flex;
  height: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d9d9d9;
  background-color: #e6e6e6;
}

.en_invitation-respinsive-style {
  background-color: white;
  border-radius: 5px;
  display: flex;
  padding-top: 5px;
  justify-content: left;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.en_vacantUnitView-invitation-style {
  display: flex;
  margin-right: 10px;
}

.icon-chartViewIcon:before {
  content: "\e900";
  color: #999999;
}

.icon-tableViewIcon:before {
  content: "\e901";
  color: #999999;
}

.icon-chartViewIcon:hover {
  content: "\e900";
  cursor: pointer;
}

.icon-tableViewIcon:hover {
  content: "\e901";
  cursor: pointer;
}

.icon-tableView::before {
  content: "\e901";
  color: #000000;
}

.icon-chartView:before {
  content: "\e900";
  color: #000000;
}

.en_VacantUnitView-BigContainer {
  background-color: #f7f7f7;
  min-height: 100vh;
}

.en_VacantUnitView-navbar-container {
  width: 100%;
  margin: 0;
  background-color: white;
}

.en_VacantUnitView-info {
  padding-top: 100px;
  padding-right: 27px;
}

.en_VacantUnitView-info>* {
  margin-bottom: 20px;
}

.en_VacantUnitView-info-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_VacantUnitView-info-property {
  background-color: white;
  width: 100%;
}

.en_propertyInfoView-collection-info {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #459d5a;
}

.en_propertyInfoView-collection {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_VacantUnitView-bars {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  text-align: center;
  background-color: white;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 5px;
}

.en_VacantUnitView-bars:hover {
  cursor: pointer;
}

.en_VacantUnitView-bars-vacantafter {
  background-color: #f5d8d8;
  color: #cb3d3d !important;
}

.en_VacantUnitView-bars-rentedafter {
  background-color: #dff0e0;
  color: #5fb563 !important;
}

.en_VacantUnitView-bars-listedafter {
  color: #3b73bf !important;
  background-color: #d3eef7;
}

.en_test.en_ant-input-group-addon {
  background-color: #e6e6e6;
}

.en_VacantUnitView-Invitation {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_VacantUnitView-chart {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
}

.en_VacantUnitView-SimularUnits {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_VacantUnitView-type {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3;
  margin-right: 3px;
}

.en_VacantUnitView-divider {
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid #cccccc;
  padding-top: 23px;
}

.en_VacantUnitView-SimilarUnit-Number {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin: 0;
  width: fit-content;
  height: 100%;
  background-color: #f7f7f7;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.en_VacantUnitView-SimilarUnit-text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 5px;
  width: 70%;
  margin-top: 3px;
}

/********************************/

.en_vacantUnitView-Addbutton:hover {
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  background-color: #fff !important;
}

.en_changePassordButton:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.en_changePassordButton:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

/*******************************************/

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: #cccccc;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #333333;
  border-left: 0px;
  border-top: 0px;
}

.en_pinOn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 26px;
  margin-left: -13px;
  margin-top: -10px;
}

.en_accountingDashboard-accounting-line-dashborad {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.en_accountingDashboard-Rectangle-681 {
  width: 330px;
  height: 355px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-right: 10px;
  margin-top: 40px;
}

.en_accountingDashboard-Rectangle-6812 {
  width: 330px;
  height: 355px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-right: 10px;
  margin-top: 40px;
}

.en_accountingDashboard-labelCard {
  width: 100px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 10px;
}

.en_accountingDashboard-color {
  padding-left: 20px;
  display: flex;
}

.en_accountingDashboard-value {
  width: 47px;
  height: 21px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-left: 60px;
}

.en_accountingDashboard-Ellipse-181 {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: #e6e6e6;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.en_accountingDashboard-Rectangle-451 {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  margin: 0 0 0;
  margin-left: 10px;
  background-color: #e6e6e6;
}

.en_accountingDashboard-flechHaut {
  margin-left: 10px;
}

.en_accountingDashboard-flechBas {
  padding-left: 83px;
}

.en_accountingDashboard-expirein {
  width: 78px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #b3b3b3;
  margin-top: 10px;
  margin-left: 20px;
}

.en_accountingDashboard-contract {
  width: 109px;
  height: 29px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #212121;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.en_accountingDashboard-number {
  width: 29px;
  height: 58px;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_accountingDashboard-of {
  width: 22px;
  height: 29px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #b3b3b3;
}

.en_accountingDashboard-teen {
  width: 35px;
  height: 38px;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.56;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_accountingDashboard-Expires-in-30-days {
  width: 126px;
  height: 19px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  display: flex;
  margin-top: 10px;
}

.en_accountingDashboard-expire {
  text-align: center;
  margin: auto;
  padding-top: 30px;
}

.en_accountingDashboard-days {
  font-weight: bold;
  color: #212121;
}

.en_accountingDashboard-cercle {
  display: block;
  margin: auto;
  text-align: center;
}

.en_accountingDashboard-t30-days {
  width: 80px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
  padding-top: 10px;
}

.en_accountingDashboard-bottom {
  padding-bottom: 0px !important;
}

.en_accountingDashboard-canva {
  margin-top: 50px;
}

.en_accountingDashboard-canva2 {
  margin-top: 12px;
}

.en_accounting-btn-list {
  color: #212121;
  width: 160px;
  text-align: center;
  height: 40px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.en_accounting-btn-list-withoutBorder {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.en_accounting-btn-list-first {
  color: #212121;
  width: 160px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: "SF-Pro-Display-Medium";
}

.en_accounting-btn-list-e {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  background-color: #27aad8;
  width: 160px;
  text-align: center;
  height: 40px;
  border: 1px solid #27aad8;
  margin-right: -4px;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
}

.en_accounting-btn-list-e-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: "SF-Pro-Display-Medium";
  background-color: red;
}

.en_accounting-btn-container {
  margin-bottom: 30px;
  margin-top: 45px;
  margin-left: 21px;
}

.en_payment-btn-container {
  margin-bottom: 30px;
  margin-top: 45px;
  margin-left: 11%;
}

.en_second_container_accounting {
  margin-left: 21px;
}

.en_accounting-big-container {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.en_transfers-collections-card {
  text-align: center;
  background-color: #dbe6f4;
  padding-top: 20px;
  padding-bottom: 6px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.en_transfers-transferred-card {
  text-align: center;
  background-color: #dff0e0;
  padding-top: 20px;
  padding-bottom: 6px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.en_transfers-table {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
}

.en_transfers-table td,
.en_transfers-table th {
  /* padding: 10px;*/
}

.en_transfers-table tr:nth-child(even) {
  font-weight: 500;
  background-color: white;
}

.en_transfers-table tr:nth-child(odd) {
  font-weight: 500;
  background-color: #efefef;
}

/* .transfers-table tr:hover {background-color: #ddd;} */

.en_transfers-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d9d9d9;
  color: #808080;
  font-size: 14px;
  font-family: 'SF-pro-Regular';
}

.en_transfers-table th:first-child {
  border-top-left-radius: 4px;
  font-family: "SF-Pro-Display-Medium";
}

.en_transfers-table th:last-child {
  border-top-right-radius: 4px;
}

.ant-input-search-enter-button+.ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input+.ant-input-group-addon .ant-input-search-button {
  background-color: #e6e6e6;
  color: #666666;
  border-color: #cccccc;
  height: 40px;
}

.en_collection-card-txt {
  color: #4e80c5;
}

.en_transferred-card-txt {
  color: #5fb563;
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  height: 40px;
}

#en_date-btn-transfers {
  width: 47px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
}

#en_date-btn-transfers:hover {
  width: 47px;
  background-color: #d9d9d9;
  border-color: #cccccc;
}

.en_transfers-picker-container {
  display: flex;
}

.en_accounting_transfer_bigContainer {
  width: 65%;
}

.en_transfers-date-picker {
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#en_export-btn-transfers {
  width: 100%;
  background-color: #666666;
  border: solid 1px #666666;
  border-radius: 4px;
  height: 40px;
  display: flex;
  color: white;
  font-weight: 700;
  justify-content: space-around;
  --antd-wave-shadow-color: none;
}

.en_row-transfers-btn {
  margin-top: 20px;
}

#en_export-btn-transfers:hover {
  color: white;
  background-color: #666666;
  border: solid 1px #666666;
}

#en_br-btn-list {
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#en_br-btn-list-first {
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.editPsw_input_container {
  display: flex;
}

.en_confirm_editPswd {
  margin-left: 24px;
}

@media (max-width: 415px) {
  .en_accounting-btn-list {
    width: 140px;
  }

  .en_updatePasswordButton {
    margin-bottom: 15px;
  }

  #en_br-btn-list {
    border-right: 1px solid #d9d9d9;
  }

  .en_accounting-btn-list-e {
    width: 140px;
  }
}

@media (max-width: 998px) {
  .en_accounting_transfer_bigContainer {
    width: 100%;
  }
}

.en_card1 {
  width: 49%;
  height: 480px;
  background-color: #ffffff;
  padding-left: 14%;
  margin-bottom: 40px;
  padding-bottom: auto;
}

.en_card2 {
  width: 100%;
  height: 480px;
  background-color: #ffffff;
  padding-bottom: 40px;
}

.en_vaccantUnitView-basicInfo {
  height: 29px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_vaccantUnitView-edit {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #27aad8;
  cursor: pointer;
}

.en_vaccantUnitView-ligne {
  width: 500px;
  height: 1px;
  background-color: #252121;
}

.en_vaccantUnitView-unit {
  height: 21px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.en_vaccantUnitView-type {
  width: 83px;
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 20px;
}

.en_vaccantUnitView-floor {
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-top: 40px;
}

.en_vaccantUnitView-value {
  width: 10px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.en_vaccantUnitView-label {
  width: 75px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_vaccantUnitView-label2 {
  width: 69px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  top: 3px;
}

.en_vaccantUnitView-addTenant-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(78, 173, 83, 0.15);
  border-color: rgba(78, 173, 83, 0.15);
  box-shadow: none;
  border-width: 0px !important;
}

.en_vaccantUnitView-addTenant-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #5fb563;
}

.en_vaccantUnitView-invitTenant-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: #d3eef7;
  border-color: #d3eef7;
  box-shadow: none;
  border-width: 0px !important;
}

.en_vaccantUnitView-invitTenant-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #27aad8;
}

.en_vaccantUnitView-description {
  width: 290px;
  height: 19px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b3;
}

.en_vaccantUnitView-listUnit-btn {
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(59, 115, 191, 0.15);
  border-color: rgba(59, 115, 191, 0.15);
  box-shadow: none;
  border-width: 0px !important;
}

.en_vaccantUnitView-listUnit-label {
  width: 78px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4e80c5;
}

.en_accounting-big-container {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.en_test1 {
  display: none;
}

.en_test2 {
  display: block;
}

.en_vaccantUnitView-deleteUnit {
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-top: 10px;
  padding-left: 11.6% !important;
}

.en_vaccantUnitView-label1 {
  width: 101px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_vaccantUnitView-value1 {
  width: 105px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 20px;
}

.en_vaccantUnitView-btnCancel {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #e6e6e6;
  box-shadow: none;
  border-width: 0px !important;
}

.en_vaccantUnitView-cancel {
  width: 118px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
}

.en_vaccantUnitView-similarUnitCercle {
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: #dbdbdb;
  justify-content: center;
  display: flex;
  padding-top: 5px;
  padding-left: 0px !important;
}

.en_contentTextStyle {
  height: 21px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-right: 4px;
}

.en_styleButtonDelete {
  background-color: #cb3d3d;
  border-color: #cb3d3d;
  color: white;
  margin-right: 100px;
  width: 100px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.en_styleButtonDelete:hover {
  background-color: #cb3d3d;
  color: white;
  border-color: #cb3d3d;
}

.en_styleButtonCancel:hover {
  background-color: #e6e6e6;
  color: #212121;
  border-color: #e6e6e6;
}

.en_styleButtonCancelInvitation {
  background-color: #27aad8;
  color: white;
  margin-right: 100px;
  width: 140px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.en_styleButtonCancel {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
  color: #212121;
  width: 100px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
}

.en_buttonModalStyle {
  text-align: center;
  align-items: center;
}

.en_styleIconDeletedSimliarUnit {
  padding-bottom: 20px;
  margin-left: 12px;
}

/*********************contract edit*****************/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.en_editContractBigContainer {
  margin-top: 2%;
  margin-left: 10%;
  margin-bottom: 0px;
}

.en_editContractTextUnit {
  color: #808080;
  font-size: 18px;
}

.en_editContractTextGreyContract {
  color: #808080;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.en_editContractContractInfoContainer {
  margin-top: 15px;
}

.en_editContractDivider_Contract {
  width: 100%;
  height: 1px;
  margin-top: 0px;
  background-color: #cccccc;
}

.en_editContractTextGreyDate {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.en_editContractDatePicker {
  display: flex;
  width: 100%;
  height: 38px;
  position: relative;
  margin-right: 17px;
}

.en_editContractInput {
  height: 40px;
}

.en_editContractInd {
  height: 40px !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100% !important;
  border-right: 0;
}

.en_editContractIndimg {
  height: 40px;
}

.en_editContractMonthlyContainer {
  margin-top: 1%;
  margin-bottom: 3%;
}

.en_editContractBtnSubmitContainer {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
}

.en_editContractBtnSubmitContainer:hover {
  border-color: #27aad8;
  background-color: #27aad8;
}

.en_editContractBtnCancelContainer {
  width: 100%;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  height: 40px;
  border-radius: 4px;
}

.en_editContractBtnCancelContainer:hover {
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.en_editContractValidateText {
  color: #cb3d3d;
  font-weight: 400;
  margin-top: 2%;
  padding-top: 40px;
}

/******************forgot password*************/

.en_phoneVerification-container {
  background-color: #36d1e0 !important;
  min-height: 100vh;
  min-width: 100vw;
}

.en_phoneVerification-logo {
  margin-left: 8%;
  margin-top: 2%;
}

.en_white_beta_logo {
  margin-left: 8%;
  margin-top: 5px;
}

.en_phoneVerification-resendPassword {
  height: 29px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  text-align: center;
  font-family: "Avenir-Next-Demi-Bold";
}

.en_phoneVerification-title {
  width: 95%;
  padding-left: 15px;
  margin-top: 24px;
  height: 42px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #808080;
  font-family: "Avenir-Next-Medium";
}

.en_phoneVerification-inputPhoneNumber {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
}

.en_phoneVerificationBackPage,
.en_phoneVerificationBackPage:hover {
  font-size: 14px;
  font-family: "Avenir-Next-Demi-Bold";
  color: #27aad8;
}

.en_forgotPassword-newPassword {
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  width: 100%;
}

.en_VacantUnitView-number {
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #cb3d3d;
  padding-right: 4px;
}

.en_VacantUnitView-days {
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.en_VacantUnitView-view-button {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.en_VacantUnitView-view-button:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #4d4d4d;
}

.en_VacantUnitView-charts>* {
  margin-bottom: 20px;
}

.en_VacantUnitView-invitationIcon {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  overflow: hidden;
  display: block;
}

.en_VacantUnitView-table-Tilte {
  background-color: #e6e6e6;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #4b4b4b;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
}

.en_VacantUnitVie-Table-Info {
  background-color: white;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
}

.en_VacantUnitView-TableView-SimularUnits {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  font-size: 18px;
}

.en_VacantUnitView-TableView-Number {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_RentedUnitView-name {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  width: 100%;
}

.en_hideUnit {
  display: none;
}

.en_propertyInfoView-collection-info_due {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #cb3d3d;
}

@media (max-width: 1116px) {
  .en_VacantUnitView-info {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .en_invitationonly-responsive {
    margin-top: 40px;
  }

  .en_VacantUnitView-table-Tilte {
    background-color: #e6e6e6;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #4b4b4b;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .en_VacantUnitView-TableView-Number {
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
  }

  .en_VacantUnitView-TableView-SimularUnits {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #808080;
    font-size: 8px;
  }

  .en_VacantUnitView-SimilarUnit-Number {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    margin: 0;
    width: fit-content;
    height: 100%;
    background-color: #f7f7f7;
    padding: 5px;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: center;
    color: #212121;
  }

  .en_VacantUnitView-number {
    font-size: 8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #cb3d3d;
  }
}

.en_PropertyInfoView-img {
  width: 100%;
  height: 100%;
}

.en_PropertyInfoView-container {
  width: 100%;
  padding-top: 40px;
  padding-left: 10%;
  padding-bottom: 40px;
}

.en_propertyInfoView-collection-style {
  margin-right: 40px;
}

.en_propertyInfoView-collection-info-style {
  width: 50%;
  margin-right: 70px;
}

.en_propertyInfoView-textNumberUnit {
  font-size: 15px;
  color: #212121;
  font-weight: bold;
  padding-right: 10px;
}

.en_propertyInfoView-textGreen {
  font-size: 15px;
  color: #5fb563;
  font-weight: bold;
}

.en_propertyInfoView-directionRight {
  text-align: end;
  padding-right: 20px;
}

.en_propertyInfoView-textRed {
  font-size: 15px;
  color: #cb3d3d;
  font-weight: bold;
}

.en_propertyInfoView-colorBar-row {
  padding-right: 20;
  padding-left: 20;
  margin-top: 30px;
}

.en_propertyInfoView-realcolorBar {
  padding-right: 20;
  padding-left: 20;
}

.en_rentedUnitChartView-amountdue {
  width: 100%;
  margin-left: 3px;
}

.en_rentedUnitChartView-view-button {
  padding-left: 15px;
  padding-top: 20px;
}

.en_vacantUnitView-view-button-style {
  width: 70%;
  padding-left: 20px;
}

.en_similarUnitChartView-similarimg {
  padding-right: 8px;
  display: inline;
}

.en_vacantUnitView-SimilarUnits-style {
  display: inline;
}

.en_vacantUnitView-divider-style {
  padding-top: 16px;
}

.en_VacantUnitView-unit-style {
  padding-top: 15px;
}

.en_vacantUnitView-viewbutton-style {
  padding-left: 20px;
}

.en_unitChartView-viewbutton-style {
  padding-left: 20px;
  padding-top: 18px;
}

.en_vacantSimilarUnitTableView-viewbuton {
  width: 50%;
}

.en_VacantUnitView-info-stylee {
  padding-top: 88px;
}

.en_vacantUnitView-vacantinfo {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #212121;
}

.en_vacantUnitView-sarch {
  padding-top: 53px;
  padding-bottom: 20px;
}

.en_vacantUnitView-info-tablestyle {
  padding-top: 53px;
  padding-bottom: 20px;
}

.en_setting-big-div {
  height: 100%;
}

.en_row-setting-navbar {
  border-bottom: solid 1px #cccccc;
  padding-top: 20px;
  padding-bottom: 13px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.en_row-setting-navbar-shadow {
  border-bottom: solid 1px #cccccc;
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.en_setting-menu-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}

.en_setting-menu-text span {
  font-weight: bolder;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.en_setting-big-container {
  background-color: #f7f7f7;
  min-height: 100vh;
}

.en_setting-menu {
  background-color: #f7f7f7;
  border-right: none;
  color: #b3b3b3;
  height: 100%;
  font-family: "SF-Pro-Display-Medium";
}

.ant-menu-vertical>.ant-menu-item-selected {
  color: #212121;
}

.ant-menu-vertical>.ant-menu-item-selected .en_vertival-nav-link {
  color: #212121 !important;
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
}

.en_vertival-nav-link {
  color: #b3b3b3 !important;
  width: 100%;
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
}

.en_vertival-nav-link:hover {
  text-decoration: none;
  color: #b3b3b3;
}

.ant-menu-vertical>.ant-menu-item:hover {
  color: #b3b3b3;
}

.en_ant-menu-vertical>.ant-menu-item-selected:hover {
  color: #212121;
}

.en_setting-menu-logout {
  margin-top: 8%;
}

.ant-menu-vertical>.ant-menu-item {
  height: 50px;
}

.en_card-container {
  background-color: white;
  margin-left: 5%;
  margin-right: 27px;
  border-radius: 10px;
  padding: 30px;
}

.en_bank-info-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 265px) {
  .en_card-container {
    padding: 0px;
  }

  .en_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .en_txxxxxxxtt {
    font-size: 14px;
  }

  .en_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 365px) {
  .en_card-container {
    padding: 0px;
  }

  .en_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .en_txxxxxxxtt {
    font-size: 14px;
  }

  .en_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 600px) {
  .en_card-container {
    padding: 0px;
  }

  .en_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .en_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    width: 300px;
  }
}

@media (max-width: 768px) {
  .en_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }

  .en_subcription-card-list {
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
  }
}

@media (max-width: 992px) {
  .en_txxxxxxxt {
    font-size: 14px;
    flex-direction: column;
  }
}

@media (max-width: 1025px) {
  .en_txxxxxxxt {
    font-size: 15px;
  }

  .en_subcription-card-list {
    text-align: -webkit-center;
  }
}

.en_txxxxxxxt {
  margin: 0px;
  display: flex;
}

.en_bank-check-me {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2%;
}

.en_bank-radio-text {
  color: #808080;
  font-size: 18px;
  margin-bottom: 3%;
  display: flex;
  line-height: 1;
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border: none;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  margin: 10px 0px 5px 0px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 50%;
  height: 50%;
  margin-top: 10px;
}

.en_tenantive-btn-top {
  margin-top: 220px;
  width: 250px;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.en_tenantive-btn-edit {
  width: 100%;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.en_tenantive-btn {
  width: 250px;
  background-color: #27aad8;
  border: solid 1px #27aad8;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.en_tenantive-navbar-link:hover {
  text-decoration: none;
}

.en_navbar-menu {
  border-bottom: none;
}

.ant-tooltip.ant-dropdown-menu-inline-collapsed-tooltip {
  display: none;
}

.en_bank-iam-drag {
  margin-right: 5%;
}

.en_bank-iam-drag-label {
  color: #666666;
  font-size: 15px;
  line-height: 1;
  display: inline-block;
  /* padding-left: 30%; */
}

.en_bank-setup-link {
  color: #27aad8;
}

.en_bank-setup-link:hover {
  color: #27aad8;
  text-decoration: none;
}

.ant-input {
  border-radius: 5px;
}

.ant-select-multiple .ant-select-selector {
  border-radius: 5px;
}

.ant-input-number {
  border-radius: 5px;
}

.en_edit-bank-card {
  background-color: #f4fbfd;
  border-left: 4px solid #27aad8;
  height: 115px;
  position: relative;
  right: 0;
  padding: 15px;
  margin: 15px;
  width: 360px;
}

.en_bank-name-edit {
  display: flex;
  justify-content: space-between;
}

.en_edit-span-btn {
  color: #27aad8;
  cursor: pointer;
  font-weight: bold;
}

.en_edit-span-btn:hover {
  color: #27aad8;
}

.en_no-edit-bank-card {
  display: none;
}

.en_edit-span-btn2 {
  color: #b3b3b3;
  cursor: pointer;
  font-weight: bold;
}

.en_cancel-edit-bank {
  color: #666666;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.en_cancel-edit-bank:hover {
  color: #666666;
}

.en_save-edit-bank-info {
  color: white;
}

.en_save-edit-bank-info:hover {
  color: white;
}

.en_bank-note-txt {
  color: #4ead53;
  font-weight: bold;
  line-height: 2;
  margin-top: 5px;
}

.en_bank-added-icon {
  width: 35px;
  margin-top: 5px;
}

.en_subscription-big-container {
  padding: 0px 4%;
}

.en_subscription-card {
  display: flex;
  flex-direction: column;
  margin: 30px 30px 30px 0px;
  border-radius: 6px;
  width: 240px;
  text-align: center;
  background-color: white;
  padding: 20px 0px;
}

.en_subscription-card-bande {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  padding: 14px 0px 0px 0;
  background-image: linear-gradient(to right, #34cbdf, #27aad8);
}

.en_card-subscription-img {
  width: 8%;
  margin-right: 3px;
}

.en_card-subscription-txt {
  color: #808080;
  margin-bottom: 20px;
  font-weight: 450;
  font-size: 18px;
}

.en_subscription-card-choices {
  display: flex;
  flex-direction: column;
  text-align: start;
  color: #808080;
  padding: 20px 0px 6px 25px;
}

#en_subscription-card-btn {
  width: 55%;
  align-self: center;
  border-radius: 4px;
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
  font-size: 16px;
  height: 40px;
  margin-top: 25px;
}

.en_subcription-card-list {
  display: flex;
  flex-wrap: wrap;
}

.en_card-subscription-txt1 {
  color: #808080;
  margin-bottom: 37px;
  font-weight: 450;
  font-size: 18px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
  border-radius: 5px;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  min-height: 40px;
}

.ant-radio-button-wrapper:hover {
  color: #212121;
}

.ant-radio-button-wrapper {
  color: #212121;
  width: 25%;
  text-align: center;
  height: 35px;
  height: 40px;
  padding-top: 4px;
}

@media (max-width: 415px) {
  .ant-radio-button-wrapper {
    width: 40%;
  }
}

.ant-radio-group {
  width: 100%;
  margin-bottom: 30px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
  background-color: #27aad8;
  border-color: #27aad8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #27aad8;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #27aad8;
}

.en_dragBulk {
  border-radius: 5px;
}

.en_uploadtext {
  color: rgb(54, 200, 216);
}

.en_bbank_style {
  margin-left: 15px;
}

.en_bankk_style {
  margin-left: 2px;
}

.en_editbank-tyle {
  font-size: 18px;
  color: #808080;
  margin-right: 10px;
  display: flex;
}

.en_editINfo-style {
  font-size: 18px;
  color: #808080;
  margin-right: 10px;
}

.en_setting-menu.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 6px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}

.en_forgotPasswordResend {
  width: 100px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #36d1e0;
}

.en_forgotPasswordMessage {
  color: #808080;
  font-family: "Avenir-Next-Medium";
  font-size: 16px;
}

.en_forgotPasswordThankyou {
  font-size: 24px;
  font-family: "Avenir-Next-Demi-Bold";
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 15px;
}

/***************login************/

.en_right {
  background-color: #36d1e0;
  min-height: 100vh;
  direction: rtl;
  padding-top: 20%;
  width: 50%;
}

.en_languge {
  position: absolute;
  margin-left: 10%;
  margin-top: 1%;
}

.en_cardRegister {
  /* text-align: center;
  margin: auto;
  margin-top:10%;
  max-width: 600px;
  min-width: 500px; */
  text-align: center;
  margin: auto;
  /* margin-top: 100vw - 482px; */
  /* position  : absolute; */
  max-width: 600px;
  min-width: 500px;
  border-radius: 15px;
}

.en_divLogin {
  padding-left: 20%;
  padding-right: 20%;
}

.en_link {
  font-size: 14px;
  color: #27aad8;
  font-weight: 600;
}

.en_shadow {
  cursor: pointer;
  border: 2px solid #cccccc;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  padding: 5px;
  align-content: center;
  display: flex;
}

.en_googleImg {
  width: 10%;
  height: 80%;
  margin-left: 10px;
  margin-right: 18px;
}

.en_labelForm {
  display: flex;
  flex: start;
}

.en_trialTxt {
  color: #ffffff;
  font-size: 30px;
  direction: ltr;
  padding-left: 10px;
  overflow: auto;
}

.en_tContainer {
  border-style: solid;
  color: #ffffff;
  direction: ltr;
  width: 100%;
  height: 80px;
  border-width: 12px;
  /* font-family: "Source Sans Pro"; */
  margin-right: -10px;
}

.en_tTxt {
  color: #ffffff;
  font-weight: 900;
  padding-left: 10px;
  font-size: 48px;
  /* font-family: "Source Sans Pro"; */
  line-height: 1;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .en_bigContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .en_right {
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .en_cardContainer {
    width: 100%;
  }

  .en_bigContainer {
    display: flex;
    justify-content: center;
  }

  /* .en_cardRegister {
    top: 15%;
  } */
  .en_right {
    display: none;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .en_cardContainer {
    width: 100%;
  }

  .en_bigContainer {
    display: flex;
    justify-content: center;
  }

  /* .en_cardRegister {
    top: 15%;
    /* left: 20%; 
    padding-left: 10%;
    /* width: 300px;
  } */
  .en_right {
    display: none;
  }
}

.en_cardContainer {
  display: flex;
  justify-content: center;
  width: 50%;
  background-color: white;
}

/****************SignUp*******************/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.en_cardRegister {
  text-align: center;
  margin: auto;
  /* position: absolute; */
  max-width: 600px;
  min-width: 500px;
  border-radius: 15px;
}

.en_languge {
  position: absolute;
  margin-left: 10%;
  margin-top: 1%;
}

.en_divLogin {
  padding-left: 20%;
  padding-right: 20%;
}

.en_loginFormButton {
  width: 100%;
  height: 40px;
  background-color: #27aad8;
  border-radius: 5px;
  border-style: none;
}

.en_or {
  color: #000000;
  font-size: 18;
}

.en_signupTxt {
  color: #b3b3b3;
  font-size: 14px;
}

.en_orSignUp {
  color: #b3b3b3;
  font-size: 18px;
  font-weight: bold;
}

.en_shadow {
  margin-bottom: 15px;
  cursor: pointer;
  border: 2px solid #cccccc;
  width: 100%;
  height: 40px;
  font-weight: 500;
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  padding: 5px;
}

.en_right {
  background-color: #36d1e0;
  min-height: 100vh;
  direction: rtl;
  padding-top: 20%;
  width: 50%;
}

.en_image {
  width: 50%;
  height: 50%;
}

.en_googleImg {
  width: 20%;
  height: 80%;
}

.en_link {
  color: #27aad8;
}

.en_labelForm {
  display: flex;
  flex: start;
}

.en_trialTxt {
  color: #ffffff;
  font-size: 30px;
  direction: ltr;
  margin-right: -10px;
}

.en_tContainer {
  border-style: solid;
  color: #ffffff;
  direction: ltr;
  width: 100%;
  height: 70px;
  border-width: 8px;
  /* font-family: "Source Sans Pro"; */
  margin-right: -10px;
}

.en_tTxt {
  color: #ffffff;
  font-weight: 900;
  padding-left: 10px;
  font-size: 48px;
  /* font-family: "Source Sans Pro"; */
  line-height: 1;
}

.en_cardContainer {
  display: flex;
  justify-content: center;
  width: 50%;
}

@media (min-width: 1581px) {
  .en_cardRegister {
    top: 5%;
  }
}

@media (min-width: 1281px) and (max-width: 1580px) {
  .en_cardRegister {
    top: 5%;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .en_cardRegister {
    top: 5%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .en_bigContainer {
    display: flex;
    justify-content: center;
  }

  .en_cardContainer {
    width: 100%;
    margin: 0;
  }

  .en_cardRegister {
    margin-top: 15%;
  }

  .en_right {
    display: none;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .en_cardContainer {
    width: 100%;
  }

  .en_bigContainer {
    display: flex;
    justify-content: center;
  }

  .en_cardRegister {
    top: 5%;
  }

  .en_right {
    display: none;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .en_cardContainer {
    width: 100%;
  }

  .en_bigContainer {
    display: flex;
    justify-content: center;
  }

  .en_right {
    display: none;
  }
}

.en_bigContainer {
  display: flex;
  overflow: hidden;
}

/******************WelcomePage****************************/

.en_property {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin-top: -50%;
  margin-left: -50%;
}

.en_container {
  position: absolute;
  max-width: 45%;
  max-height: 45%;
  top: 50%;
  left: 50%;
  overflow: visible;
}

.en_vl {
  border-left: 1px solid #cccccc;
  height: 100vh;
}

.en_logo {
  margin-left: 10%;
  margin-top: 5%;
}

.en_thanks {
  font-size: 40px;
  margin-left: 10%;
  margin-top: 5%;
}

.en_description {
  font-size: 35px;
  margin-left: 10%;
  margin-top: 2%;
}

.en_line {
  border-left: 1px solid #cccccc;
  margin-top: 10%;
  margin-left: 10%;
  width: 70%;
}

.en_steps {
  margin-top: 5%;
  margin-left: 10%;
}

.en_checkbox {
  height: 20px;
  width: 20px;
  display: inline;
}

.en_step {
  padding-left: 5px;
  display: inline;
}

.en_stepsBlock {
  width: 40%;
  margin-top: 3%;
  margin-left: 10%;
}

.en_btn {
  width: 30%;
  height: 40px;
  margin-left: 10%;
  margin-top: 5%;
  background-color: #27aad8;
}

/********************Accounting************************************/

/********************Accounting Rentals****************/

@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");

.en_Container-Render {
  /* font-family: "Roboto", sans-serif !important; */
  background: #f7f7f7;
}

.en_Render-header div div {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  height: 82px;
  padding-top: 1%;
  line-height: 1.8;
}

.en_unresolved_images .ant-col:nth-child(odd) {
  padding-right: 5px;
}

.en_unresolved_images .ant-col:nth-child(even) {
  padding-left: 5px;
}

.en_ticket_delete_photo {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  cursor: pointer;
}

/* .unresolved_images:nth-child(odd) {
  margin-right: 10px;
} */

.en_Render-header div:nth-child(1) {
  background: #808080;
  color: white;
}

.en_Render-header div:nth-child(2) {
  background: #dff0e0;
  color: #5fb563;
}

.en_Render-header div:nth-child(3) {
  background: #f5d8d8;
  color: #cb3d3d;
}

.en_Render-input {
  margin-bottom: -4% !important;
  margin-top: 2% !important;
  /* font-family: "Roboto" sans-serif !important; */
}

.en_Render-input .Render-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.en_export {
  background: #666666 !important;
  width: 120px;
}

.en_rental-inpit-right .ant-select .ant-select-single .ant-select-show-arrow .ant-select-show-arrow:nth-child(2) .ant-select-selector {
  background-color: #666666 !important;
  color: #ffffff;
}

.en_rental-inpit-right .ant-select .ant-select-single .ant-select-show-arrow .ant-select-show-arrow:nth-child(2) .ant-select-arrow {
  color: #ffffff;
}

.en_table-acounting-rental .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-fixed-column .ant-table-content table .ant-table-thead tr th {
  background: #d9d9d9 !important;
  font-family: "SF-Pro-Display-Medium" !important;
  font-size: 14px !important;
  color: #686262c2 !important;
}

/**************Modular**********/

.en_none {
  display: none;
}

.en_show-more {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(47, 47, 47, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.en_mymodula {
  top: 20%;
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 3px;
  padding-top: 1%;
  padding-left: 48px;
  max-width: 367px;
}

@media (min-width: 1367px) {
  .en_mymodula {
    top: 7%;
  }
}

/**********************************************************/

.en_close-mymodula {
  color: #ffffff;
  background: #020202;
  position: absolute;
  top: -13px;
  font-size: 19px;
  right: -13.5px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.en_card-containerTitel {
  font-size: 18px;
  margin-left: 5%;
}

/***Form date input*/

.en_mymodula .ant-form-horizontal .ant-form-item {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 12px !important;
}

.en_show-more .en_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-label>label {
  margin-right: 57%;
  margin-bottom: 5%;
  margin-top: 6%;
}

.en_mymodula .ant-form-horizontal .ant-row .clearfix .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
  width: 82%;
  height: 109px;
  margin-left: 0;
  margin-top: 5%;
}

.en_mymodula .ant-form-horizontal .ant-form-item {
  margin-bottom: 8px !important;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
  border: none;
}

.en_date-rentl-pay {
  width: 255px;
  height: 39px;
}

.en_cancel {
  font-size: 13px;
}

.en_submit {
  font-size: 15px;
  font-weight: 700;
}

.en_drag {
  color: #b3b3b3;
}

/************Statue Data*****/

.en_Over {
  color: #cb3d3d !important;
}

.en_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
  width: 222px;
}

.en_btn-submit-rental {
  /* font-family: "Roboto", sans-serif; */
  width: 94%;
  height: 50px;
  margin-left: -50%;
}

.en_btn-cancel {
  /* font-family: "Roboto", sans-serif; */
  width: 100%;
  /* margin-left: -49%; */
  text-align: left;
  /* margin    : 1%; */
  margin-left: -10%;
  margin-top: 6%;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 3%;
}

#en_btn-s-modl {
  background-color: #27aad8;
  border-color: #27aad8;
  font-size: 16x !important;
  width: 263px;
}

#en_btn-s-modl span {
  font-size: 17px;
}

/***************Input*****/

.en_mymodula .ant-form-horizontal .ant-form-item {
  width: 391px !important;
}

#en_metho-pay-select {
  width: 106px;
  height: 34px;
}

/************Date Input**********************/

.en_input-rental {
  width: 162px;
  height: 38px;
  position: relative;
  margin-bottom: 71px;
  margin-right: 17px;
}

.en_input-rental .en_ind {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.en_ind3 {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.en_input-rental img {
  position: absolute;
  right: 0;
}

.en_input-rental .ant-select-show-arrow,
.en_input-rental .ant-select-show-arrow .ant-select-selector {
  position: absolute;
  height: 100%;
  width: 100%;
}

/**********input rentel Right**/

.en_rental-inpit-right {
  position: relative;
  margin-bottom: -4% !important;
  height: 40px;
  overflow: hidden !important;
  border-radius: 8px;
}

.en_rental-inpit-right .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 3%;
  background-color: #666666;
  color: white;
  height: 100%;
}

.en_rental-inpit-right .ant-select-show-arrow {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
}

.en_rental-inpit-right #en_ico-select {
  position: absolute;
  top: 20%;
  right: 16%;
  display: none;
}

@media all and (max-device-width: 575px) {
  .en_mymodula .ant-form-horizontal .ant-form-item {
    max-width: 80% !important;
  }
}

@media all and (max-width: 575px) {
  .en_btn-submit-rental {
    width: 111%;
    margin-left: -36%;
  }

  .en_btn-cancel {
    margin-left: 9%;
    cursor: pointer;
  }

  .en_date-pay {
    width: 66% !important;
  }

  .en_ant-upload-textt {
    width: 245px;
  }
}

/**************************************/

.en_input-filter {
  width: 100%;
  height: 38px;
  position: relative;
  margin-bottom: 4%;
  margin-right: 17px;
}

.en_input-filter .en_ind {
  width: 100% !important;
  position: absolute;
}

.en_input-filter .en_ind2 {
  margin-left: -11px !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.en_input-filter .ant-select-show-arrow,
.en_input-filter .ant-select-show-arrow .ant-select-selector {
  position: absolute;
  height: 100%;
  width: 100%;
}

.en_input-filter .ant-select-single .ant-select-selector {
  height: 41px;
  padding-top: 2%;
}

/*************************************************************/

.en_input-filter {
  width: 100%;
  height: 38px;
  position: relative;
  margin-bottom: 4%;
}

.en_input-filter .en_ind {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.en_input-filter .ant-select-show-arrow,
.en_input-filter .ant-select-show-arrow .ant-select-selector {
  position: absolute;
  height: 100%;
  width: 100%;
}

.en_input-filter .en_ind {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.en_Rental-filter1 {
  margin-top: 26px;
  margin-bottom: 58px;
}

@media (max-width: 767px) and (min-width: 575px) {
  .en_rental-inpit-right {
    width: 134px !important;
    display: none;
  }

  .en_input-filter {
    margin-bottom: 11%;
  }

  .en_Rental-filter1 {
    margin-bottom: 38px;
  }
}

.en_mymodula .ant-form-item-label>label::after {
  content: "";
}

.en_totalContainerPayment {
  margin-bottom: 5%;
  height: 72px;
  width: 310px;
  padding: 19px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
}

.en_editProfileCancelButton {
  color: #808080;
  margin-left: 10px;
}

.en_editProfileCancelButton:hover {
  color: #808080;
}

.en_editProfileCancelButton:focus {
  color: #808080;
}

.en_displaySaveCancel {
  display: none;
}

.en_editProfileSaveCol {
  display: block;
  position: absolute;
  right: 55px;
  top: 25px;
}

.en_viewContract_btn_accept {
  margin-top: 34px;
  color: white;
  background-color: #27aad8;
  height: 40px;
  width: 130px;
  border-radius: 4px;
  border: 1px solid #27aad8;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
}

.en_viewContract_btn_reject {
  margin-top: 34px;
  color: #212121;
  background-color: #e6e6e6;
  height: 40px;
  width: 130px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
}

.en_viewContract_btn_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 567px) {
  .en_input-filter .en_ind2 {
    margin-left: 0 !important;
  }

  .en_rental-inpit-right {
    top: -17%;
    display: none !important;
    display: none;
  }

  .en_rental-inpit-right .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 1%;
  }

  .en_Rental-filter1 {
    margin-bottom: 31px;
  }

  .phoneNumberCol {
    margin: 0px !important;
    order: 0
  }

  .civilIdCol {
    order: 3;
    margin: 0px;
    padding-top: 5px;
  }

  .phoneNumberInputCol {
    margin-left: 0px !important;
    margin-right: 0px !important;
    order: 1
  }

  .civilIdInputCol {
    order: 4;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .en_ProfilEdit {
    display: none;
  }

  .en_totalContainerPayment {
    margin-bottom: 15%;
  }

  .en_saveeditButton {
    font-size: 16px;
    color: #27aad8;
    line-height: 23px;
    font-family: "tajwal-bold";
    padding: 0px;
    display: flex;
    align-items: flex-start;
  }

  .upload_btn_messenger .ant-upload-list-item-info {
    width: 250px !important;
  }

  .en_editProfileCancelButton {
    margin-top: -5px;
    color: #808080;
    margin-left: 0;
  }

  .en_displaySaveCancel {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
  }

  .en_editProfileSaveCol {
    display: none;
  }

  .en_EditPhoneContainer {
    display: flex;
    flex-direction: column;
    order: 2 !important
  }

  .en_connection_tenant_card {
    width: 100vw !important;
  }

  .en_viewContract_btn_container {
    display: flex;
    justify-content: center;
    padding-right: 30px;
  }

  .en_viewContract_btn_accept {
    margin-right: 30px;
  }
}

.en_table-acounting-rental {
  margin-top: -40px;
}

.en_classNameOfColumn {
  vertical-align: top;
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
  color: #434343;
}

.en_classNameOfStatus {
  border: none;
  background: white;
}

.ant-table-container table>thead>tr:first-child th {
  background-color: #d9d9d9;
}

.en_mymodula2-child-rent_amount-metho {
  display: flex;
  justify-content: space-between;
  padding-left: 9%;
  padding-right: 9%;
}

.en_payment-method {
  display: flex;
  justify-content: space-between;
  width: 100px;
}

.en_payment-method img {
  height: 22px;
}

.en_rent_amount {
  font-weight: bolder;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100px;
}

.en_mymodula .ant-form-horizontal .ant-form-item:first-child {
  width: 387px !important;
}

.en_mymodula .ant-form-item-label>label.ant-form-item-required::before {
  position: relative;
  right: -127px;
  margin-right: -4px;
}

.en_input-filter img {
  position: absolute;
  right: 0;
}

.en_img-date-ico1 {
  position: absolute;
  right: 64px;
  top: 43px;
}

.en_acount-rentl-img-select {
  position: absolute !important;
  right: 9px !important;
  top: 15% !important;
}

.en_acount-invoices-img-select {
  position: absolute !important;
  right: 0px !important;
  top: 15% !important;
}

/*****************Rentals payment****************/

.en_container-btn-action-rental {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #dde3e9;
}

.en_icon-input {
  cursor: pointer;
}

.en_date-pay {
  display: flex;
  width: 100%;
  margin-bottom: 4%;
  flex-direction: row-reverse;
}

/************Receipt View*************************/

.en_mymodula2 {
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 3px;
  padding-top: 2%;
  padding-left: 0;
  max-width: 459px;
  width: 100%;
  height: 419px;
  background: #ffff;
}

.en_mymodula2-child {
  display: flex;
  justify-content: space-between;
  padding-left: 41px;
  padding-right: 41px;
  position: relative;
}

.en_mymodula2-child-rent_amount-metho {
  border: #cccccc solid 1px;
  height: 113px;
  display: flex;
  margin-left: 9%;
  margin-right: 9%;
  padding-top: 5%;
}

.en_mymodula2-child img {
  position: absolute;
  right: 0;
}

.en_mymodula2-child-amount-metho div div {
  display: flex;
}

.en_mymodula2-child-amount-metho div div img {
  width: 30px;
  height: 19px;
  margin-left: 10px;
}

.en_btn-export,
.en_btn-print {
  position: relative;
  width: 130px;
  height: 40px;
  /* font-family: "Roboto Condensed", sans-serif; */
  cursor: pointer;
  margin-top: 5%;
  left: 41px;
}

.en_btn-export p,
.en_btn-export img {
  position: absolute;
  left: 0;
}

.en_btn-print p {
  z-index: 2;
  margin-left: 21%;
  margin-top: 7%;
  /* font-family: "Roboto Condensed", sans-serif; */
}

.en_btn-print p,
.en_btn-print img {
  position: absolute;
  right: 0;
}

.en_btn-export p {
  margin-top: 6%;
  margin-left: 25%;
  font-weight: 700;
  color: white;
  font-size: 13px;
  /* font-family: "Roboto Condensed", sans-serif; */
}

.en_btn-print p {
  margin-right: 37%;
  font-weight: 700;
  color: rgb(22, 22, 22);
  font-size: 13px;
  /* font-family: "Roboto Condensed", sans-serif; */
}

.en_amount {
  /* font-family: "Source Sans Pro", sans-serif; */
  font-weight: 700;
  color: #000;
  font-size: 20px;
}

.en_mymodula2-child div p {
  font-weight: 500;
}

.en_cash {
  font-size: 15px;
}

.en_pdf {
  font-weight: 500 !important;
  font-size: 15px !important;
}

.en_amm-pid {
  /* font-family: "Source Sans Pro", sans-serif; */
  font-size: 17px;
  margin-bottom: 9%;
  font-size: 19px;
  color: #808080;
}

.en_mymodula2-child div p span {
  color: #000;
  font-size: 17px;
}

.en_rec-inv {
  font-size: 19px;
  /* font-family: "Source Sans Pro", sans-serif; */
  margin-left: 41px;
  margin-right: 2px;
}

.en_mymodula2-child div h3 {
  /* font-family: "Source Sans Pro", sans-serif; */
  font-weight: 700;
  font-size: 24px;
}

.en_rec-in-child p {
  font-size: 19px;
  /* font-family: "Source Sans Pro", sans-serif; */
}

.en_rec-in-child .rec-inv {
  margin-right: 5px;
}

/******************** Unit Rented View ****************/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");

.en_Hrental-info {
  display: flex;
  /* font-family : "Source Sans Pro", sans-serif; */
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fbfbfb;
}

.en_Hrental-info>div {
  background-color: #ffffff;
  width: 49%;
  margin-top: 9px;
  padding-left: 12%;
  padding-top: 2%;
  padding-bottom: 7%;
  position: relative;
}

/*********************************************************************/

.en_Hrental-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px #f7f7f7 solid;
  width: 323px;
}

.en_Hrental-title:last-child {
  border-bottom: none;
}

.en_Hrental-title h3 {
  font-size: 18px;
  color: #808080;
  margin-top: 2%;
}

.en_HrentalDelete2 {
  font-family: "SF-Pro-Display-Bold";
  font-size: 14px;
  padding-left: 170px;
  background-color: #f3f3f3cc;
  padding-top: 40px;
  cursor: pointer;
  padding-bottom: 50px;
}

.en_Hrental-title p {
  margin-top: 2%;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  color: #35d0e1;
}

.en_Hunit-name {
  /* font-family: SFProDisplay; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  /* font-style : normal; */
  /* line-height: 1.06; */
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 3%;
}

.en_HUnit-type {
  font-size: 15px;
  color: #000000;
  margin-top: 1%;
}

.en_Hfloor {
  font-size: 14px;
  color: #808080;
  margin-top: 2%;
}

.en_Hroom-rental {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 45%;
  margin-top: 3%;
}

.en_Hrental p {
  font-size: 15px;
  color: #808080;
  /* letter-spacing: 0px; */
  font-weight: 600;
}

.en_Hroom-rental p {
  color: #808080;
}

.en_Hroom-rental p span {
  margin-right: 7px;
  color: #000000;
}

.en_Hroom-rental2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.en_Hroom-rental2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 58%;
}

.en_Hroom-rental2 p {
  width: 50%;
  color: #808080;
  font-size: 14px;
}

.en_Hroom-rental2 p img {
  margin-right: 5%;
  width: 21px;
}

/*********************************************************************/

.en_Hrental-list-unit .en_bn-list-rent {
  cursor: pointer;
  width: 255px;
  height: 48px;
  border-radius: 4px;
  background-color: rgba(59, 115, 191, 0.15);
  font-family: SFProDisplay;
  /*font-size          : 13px;
    */
  font-weight: 600;
  text-align: center;
  color: #83b0ec;
  padding-top: 2%;
  margin-left: 6%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  margin-top: 150px;
}

.en_Hrental-list-unit p {
  margin-top: 5%;
  width: 317px;
  height: 19px;
  font-family: SFProDisplay;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #b3b3b3;
}

/*********************************************************************/

.en_Hrental-info-child:first-child {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 350px;
  margin-top: 33px;
}

.en_Hrental-info-child:nth-child(1) h3 {
  margin-top: 21px;
  font-size: 20ppx;
  font-weight: 600;
  font-size: 19px;
}

.en_Hrental-info-child2 {
  display: flex;
  justify-content: space-between;
  width: 283px;
  margin-top: 4%;
  margin-left: 2%;
  color: #a2a2a2;
  font-size: 16px;
}

/************************************/

.en_Hrental-info-ss-left p {
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
}

.en_Hrental-info-ss-right p {
  color: #676767;
  height: 22px;
  margin-left: 11px;
  font-family: "SF-Pro-Display-Medium";
}

.en_Hrental-info-pdf {
  text-decoration: underline;
}

/**********************************************************/

.en_Hrental-info-child3 {
  display: flex;
  justify-content: space-between;
  /* width       : 58%; */
  width: 283px;
}

.en_Hrental-info-child3 .en_btn-f-block {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  border: solid 2px #cccccc;
  text-align: center;
  padding-top: 1%;
}

.en_g-period-title {
  font-size: 13px;
  color: #666666;
  font-weight: 600;
}

.en_block_period_plus_sous img {
  width: 24px;
  height: 24px;
  z-index: 99;
  cursor: pointer;
}

.en_block_period_plus_sous p {
  margin-left: 9%;
  margin-right: 9%;
  width: 64px;
}

.en_block_period_plus_sous {
  display: flex;
  margin-top: 10%;
}

/***************************************************************/

.en_Hrental-amout {
  font-size: 16px;
  color: #212121;
  margin-top: 54px;
}

.en_Hrental-amout span {
  color: #5fb563;
  font-family: "SF-Pro-Display-Bold";
  font-size: 16px;
}

/******************************************/

.en_Hrental-btn-Amountdue {
  width: 147px;
  height: 50px;
  background-color: #f8f8f8;
  margin-bottom: 10%;
  text-align: center;
  padding-top: 1%;
}

.en_amout-h-nbr {
  font-size: 24px;
  font-weight: bold;
  color: #cb3d3d;
}

.en_btn-f-Renew-contract {
  display: flex;
  justify-content: space-between;
  width: 323px;
  font-family: "SF-Pro-Display-Bold";
}

.en_btn-f-Renew {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  text-align: center;
  padding-top: 2%;
  cursor: pointer;
  color: #7e7e7e;
}

.en_btn-f-Contract {
  cursor: pointer;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border: solid 2px #cccccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  text-align: center;
  padding-top: 2%;
  color: #666666;
}

/***************Transation*************************/

.en_Hrental-Transation {
  margin-left: 168px;
  width: 77%;
}

.en_Hrental-Transation-first-child {
  background-color: #ffffff;
  margin-bottom: 9px;
  padding-top: 2%;
  /* font-family  : "Source Sans Pro", sans-serif; */
}

.en_Hrental-title2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px #f7f7f7 solid;
  width: 100%;
}

.en_Hrental-title2 h3 {
  font-size: 23px;
  font-weight: 600;
  color: #9e9e9e;
  margin-top: 2%;
}

.en_Hrental-right-export {
  cursor: pointer;
  position: relative;
  /*  width            : 120px !important;*/
  height: 40px !important;
  border-radius: 5px !important;
  padding-left: 2%;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  font-size: 18px;
}

.en_Hrental-right-export img {
  top: 8px;
  right: 10px;
  position: absolute;
  z-index: 999;
  /* left : 91px; */
}

.en_Hexport-select-unit-rent .ant-select-selector {
  background-color: #666666 !important;
  width: 120px !important;
  height: 40px !important;
  border: none !important;
  color: #ffffff;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  padding-left: 26px !important;
  font-size: 15px !important;
}

.en_btn-unit-rental-receip {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #dde3e9;
  /*padding            : 9px;
  */
  text-align: center;
  padding-top: 3%;
  font-weight: 700;
  font-size: 15px;
  color: #1d456dad;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.en_Hrental-info-child:first-child img {
  width: 86px;
  height: 86px;
  overflow: hidden;
  border-radius: 47px;
}

.en_Hrental-info-child3 .en_btn-f-block {
  margin-top: 3%;
}

.en_mybardaate>div {
  width: 323px;
  background-image: linear-gradient(to right, rgba(78, 173, 83, 0.9) 35%, rgba(198, 40, 40, 0.9) 86%);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.en_mybardaate_child {
  display: flex;
  justify-content: space-between;
  width: 323px;
  color: #b3b3b3;
  margin-top: 2%;
}

.en_mybardaate_container {
  margin-top: 10%;
}

.en_bord {
  border-bottom: 2px #f7f7f7 solid;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 20px;
  left: 0;
}

.en_Hrental-Transation {
  display: none;
}

.en_Hrental-right-export>div>div {
  padding-top: 5px !important;
}

.en_RentedViw_table-transaction {
  width: 100%;
}

.en_input-filter .ant-picker-range .ant-picker-clear {
  right: 52px;
}

.en_col-inputrender2 {
  padding-left: 3px !important;
}

.en_HrentalDelete {
  background-color: #fbfbfb;
}

.en_mymodulaRentedView {
  z-index: 999999;
  width: 315px;
  height: 149px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
}

.en_btnEndContP {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  cursor: pointer;
}

.en_btnendModulCi {
  margin-right: 5px;
  width: 108px;
  height: 32px;
  border-radius: 4px;
  background-color: #27aad8;
  /*background-color   :#cb3d3d;
  */
  color: #ffffff;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.en_btnendModuldel {
  width: 108px;
  height: 32px;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #cb3d3d;
}

.en_btnCancelContract {
  margin-left: 5px;
  width: 108px;
  height: 32px;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #4d4d4d;
  text-align: center;
  padding-top: 2%;
  padding: 2%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.en_alertdeletUR {
  padding-right: 30px;
  width: 332px;
  right: 0;
  position: fixed;
  height: 48px;
  overflow: hidden;
  z-index: 9999;
  top: 21%;
}

.en_rental-inpit-right div div div {
  padding-top: 5px !important;
  background-color: #666666 !important;
  color: white;
  height: 52px !important;
  width: 324px !important;
  padding-left: 17px !important;
}

.en_delun {
  color: #666666;
  /* font-family: 'Source Sans Pro', sans-serif!important; */
  font-weight: bold !important;
  font-size: 16px;
}

.en_addProp_Map {
  position: relative;
  height: 400px;
  overflow: hidden;
  margin-right: 10%;
  margin-left: 10%;
}

.ant-input.en_addProp_Input {
  padding: 7px 11px;
}

.en_add_property_Input_container {
  width: 250px;
  margin-left: 10%;
}

.inputContainer {
  position: relative;
  margin-top: 14px;
}

.en_advancedPay_rows_spacebetween {
  display: flex;
  justify-content: space-between;
}

.en_advancedPay_incrImage {
  display: inline-block;
  cursor: pointer;
}

.en_vacantUnitView-Addbutton {
  position: absolute;
  right: 0;
}

/*Register : Password fields ***/

.en_CPUpper_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPUpper_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPLower_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPLower_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPLower_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPLower_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPNumber_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPNumber_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPSpecialChar_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPSpecialChar_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPMinCar_successful_false {
  color: #b3b3b3;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_CPMinCar_successful_true {
  color: #5fb563;
  font-size: 13px;
  font-family: "SF-Pro-Display-Regular";
}

.en_errorName {
  margin-bottom: 0;
}

.en_errorPhoeNmr {
  margin-bottom: 15px;
}

/*----Stay tuned---*/

.en_stay_tuned_container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.en_stay_tuned {
  font-size: 24px;
  font-weight: bold;
  color: #212121;
  margin-bottom: 10px;
}

.en_stay_tunedTxt {
  font-size: 24px;
  font-weight: 400;
  color: #808080;
}

/*********************/

.en_input_contain {
  position: relative;
}

.en_Rental-input-select {
  position: absolute;
  right: 66px;
  top: 48px;
}

.en_settingUser_title {
  font-size: 24px;
  color: #212121;
  line-height: 23px;
  font-family: "SF-Pro-Display-Bold";
  margin-bottom: 40px;
}

.en_settingUser_input {
  min-height: 40px;
}

.en_User-invite-buttonn.ant-btn-primary {
  background-color: #27aad8;
  border-color: #27aad8;
}

.en_invite_button.ant-btn-primary {
  background-color: #27aad8;
  border-color: #27aad8;
}

/****************************notFoundPage******************/

.en_notFoundPageTitle {
  display: flex;
  justify-content: center;
  text-align: center;
}

.en_btnReturn {
  width: 200px;
  height: 36px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  margin-top: 30px;
  border-color: #27aad8;
  box-shadow: none;
  border-width: 0px !important;
  cursor: pointer;
}

.en_Rental-inputPaym1 {
  height: 39px;
  width: 258px;
  border: 1px #d9d9d9 solid;
  margin-top: 2%;
  padding-left: 4%;
  padding-top: 8px;
}

.en_Rental-inputPaym1 span:last-child {
  color: #b3b3b3;
  margin-left: 3px;
}

.en_Rental-inputPaym {
  width: 100%;
  position: relative;
}

.en_advancedPay_amountpayed {
  font-size: 18px;
  line-height: 19px;
  color: #5fb563;
  font-style: bold;
}

.en_Rental-input-img {
  top: 37px;
  right: 13px;
  position: absolute;
}

.en_RentalPaymCont {
  margin-top: 7%;
}

.en_RentalPaymlabel {
  color: #212121;
}

/*********************Select language *********************/

.en_Hrental-CommingParnt {
  width: 661px;
  background-color: rgb(255, 255, 255, 0.5);
  position: absolute;
}

.en_Hrental-Comming {
  font-size: 21px;
  position: absolute;
  right: 66%;
  margin-top: 29px;
  /* margin-top: 5%; */
  /* top       : 64%; */
  color: #808080;
}

/***********Payment*************/

.en_PayAdvance {
  background-color: #e6e6e6;
  border-radius: 5px;
  height: 40px;
  width: 130px;
  font-family: "SF-Pro-Display-Semibold";
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 10px;
  float: right;
}

.en_PayAdvance:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.65);
}

.en_PayAdvance:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.65);
}

.en_paymentTotal {
  font-size: 24px;
  color: #808080;
  line-height: 2px;
  font-weight: normal;
  font-family: "SF-Pro-Display-Medium";
  padding-top: 3px;
}

.en_PaymentContainer {
  display: flex;
  align-items: baseline;
  width: 89%;
  margin-left: 11%;
}

.en_Total {
  font-size: 24px;
  color: #5fb563;
  font-weight: bold;
  font-family: "SF-Pro-Display-Bold";
  margin: auto;
  white-space: nowrap;
}

.en_TotalEmpty {
  font-size: 24px;
  color: #808080;
  font-weight: bold;
  font-family: "SF-Pro-Display-Bold";
  margin-right: 50px;
}

.en_PaymentTable {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(7, 1fr);
  background-color: #fff;
  font-family: "SF-Pro-Display-Medium";
  margin-left: 15px;
}

.en_PaymentTable span {
  font-size: 16px;
}

/* .en_ColumnPayment {
  display: flex ;
  flex-direction: column;
  align-items: center;
} */

.en_PayButton button {
  background-color: #5fb563;
  color: #fff;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  font-family: "SF-Pro-Display-Semibold";
  align-self: center;
  border-color: #5fb563;
}

.en_PayButton {
  background-color: #5fb563;
  color: #fff;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  font-family: "SF-Pro-Display-Semibold";
  align-self: center;
  border-color: #5fb563;
}

.en_PayButton:focus {
  background-color: #5fb563;
  color: #fff;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  align-self: center;
  border-color: #5fb563;
}

.en_PayButton:hover {
  background-color: #5fb563;
  color: #fff;
  border-color: #5fb563;
  height: 40px;
  width: 130px;
  border-radius: 5px;
  align-self: center;
  border-color: #5fb563;
}

.en_table {
  margin: 0 11%;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
}

.en_textColor {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 18px !important;
  margin-top: 10px;
  color: #212121;
}

.en_payment_amount {
  font-family: 'SF-Pro-Display-Semibold';
  margin-top: 10px;
  color: #5FB563;
  font-size: 18px;
}

/******************In Advance Pay  ***************************/

.en_AdvancePay_contain {
  font-family: "SF-Pro-Display-Regular";
}

.en_AdvancePay_contain .en_show-more .en_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-label>label {
  margin-right: 69%;
  font-family: "SF-Pro-Display-Regular";
  font-size: 13px;
  color: #666666;
  font-weight: 600;
}

.en_AdvancePay_contain .en_Rental-input-select {
  position: absolute;
  right: 22%;
  top: 62%;
}

.en_AdvancePay_contain .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}

.en_Months_container {
  display: flex;
  justify-content: space-between;
  width: 82%;
}

.en_Months_container {
  margin-top: 9%;
}

.en_month_plus_sous {
  display: flex;
  justify-content: space-between;
  width: 58%;
  flex-wrap: wrap;
}

.en_month_plus_sous img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.en_month_plus_sous p {
  color: #212121;
}

/*
ant-select-selector*/

.en_Pay_Adv_Btn {
  width: 82%;
  border-radius: 2px;
  background-color: #27aad8;
  padding: 4% 0;
  color: white;
  text-align: center;
  padding: 3% 0;
  margin-top: 8%;
  cursor: pointer;
  font-size: 15px;
}

.en_Pay_Adv_Btn:last-child {
  background-color: #ffffff;
  color: #3c3b3b;
  margin-top: 3%;
  cursor: pointer;
  font-family: "SF-Pro-Display-Semibold";
}

.en_AdvancePay_contain .en_mymodula {
  top: 2%;
}

.en_PAmout_container {
  display: flex;
  justify-content: space-between;
  width: 79%;
  font-size: 17px !important;
}

.en_PAmout {
  color: #5fb563;
  font-family: "SF-Pro-Display-Bold";
}

.en_PAmount_title {
  font-family: "SF-Pro-Display-Medium";
  color: #212121;
  font-weight: 500;
}

.en_AdvancePay_contain .en_show-more .en_mymodula hr {
  margin-left: -48px;
  border-top: 1px solid rgba(33, 33, 33, 0.11);
  margin-top: 9%;
}

.en_listMonth {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 58%;
}

.en_listMonth p {
  width: 31px;
  cursor: pointer;
  margin-right: 4%;
}

.en_elpayed {
  text-decoration: line-through;
  color: #b3b3b3;
}

.en_paydAdv {
  color: #b3b3b3 !important;
}

.en_paydAdv2 {
  color: #27aad8 !important;
}

/*-----------------*/

.en_containerDashboard {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*-------Table-----------*/

.en_transfers-table {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
}

.en_transfers-table tr:nth-child(even) {
  font-weight: 500;
  background-color: white;
}

.en_transfers-table tr:nth-child(odd) {
  font-weight: 500;
  background-color: #efefef;
}

/* .transfers-table tr:hover {background-color: #ddd;} */

.en_transfers-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d9d9d9;
  color: #808080;
  font-weight: 500;
}

.en_transfers-table th:first-child {
  border-top-left-radius: 4px;
}

.en_transfers-table th:last-child {
  border-top-right-radius: 4px;
}

.en_tab-line-qw {
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  /*  line-height: 24px;*/
  text-align: left;
  color: #212121;
}

/****************************notFoundPage******************/

.en_notFoundPageTitle {
  display: flex;
  justify-content: center;
  text-align: center;
}

.en_btnReturn {
  width: 200px;
  height: 36px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  margin-top: 30px;
  border-color: #27aad8;
  box-shadow: none;
  border-width: 0px !important;
  cursor: pointer;
}

/*-------Tenant-Link---------*/

.en_tenantNumber_container {
  display: flex;
  background-color: #36d1e0;
  justify-content: center;
  min-height: 100vh;
}

.en_tenantNumber_backLogo {
  position: fixed;
  bottom: 0;
}

.en_tenantNumber_numberContainer {
  width: 445px;
  height: 367px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}
.en_tenant_pwd {
  width: 445px;
  height: 500px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}
.en_tenantNumber_logoWhite {
  position: fixed;
  left: 5%;
  top: 2%;
}

.en_tenantNumber_littleLogo {
  float: right;
  margin-top: 40px;
}

.en_tenantNumber_cardTitle {
  float: left;
  font-family: "Avenir-Next-Demi-Bold";
  margin: 40px 0px 40px 40px;
  color: #212121;
  font-size: 24px;
}

.en_tenantNumber_label {
  font-family: "Avenir-Next-Demi-Bold";
  color: #212121;
  font-size: 16px;
  margin: 137px 0px 18px;
}

.en_tenantNumber_inputContainer {
  padding: 0px 40px;
  font-size: 14px;
  font-family: "Avenir-Next-Medium";
}

.en_tenantNumber_inputNumber {
  height: 40px;
  font-size: 14px;
  font-family: "Avenir-Next-Medium";
}

.en_tenantNumber_inputNumberError {
  height: 40px;
  font-size: 14px;
  font-family: "Avenir-Next-Medium";
  border: 1px solid #cb3d3d;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #cb3d3d;
}

.ant-input-suffix {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
  color: #B3B3B3;
}

@media (max-width: 413px) {
  .en_tenantNumber_numberContainer {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .en_tenantNumber_cardTitle {
    margin: 40px 0px 40px 20px;
  }

  .en_tenantNumber_label {
    font-size: 15px;
  }
}

/*------------------*/

.en_tab-line-qw {
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  /* line-height: 24px;*/
  text-align: left;
  color: #212121;
}

/*------history tenant --------*/

.en_historyTenant_btn {
  height: 40px;
  width: 100%;
  width: 130px;
  border-radius: 4px;
  background-color: #dde3e9;
  border: 1px solid #dde3e9;
  color: #1d456d;
  font-size: 14px;
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  text-align: center;
  cursor: pointer;
}

.en_historyTenant_unitNum {
  color: #b3b3b3;
}

/*-------Receip----------*/

.en_TeantReceipView .ant-modal-body {
  width: 520px;
  /* max-height: 695px; */
  padding: 40px 0px 30px;
}

.en_TeantReceipViewFailed .ant-modal-body {
  width: 520px;
  padding: 40px 0px 30px;
}

.en_TeantReceipView_logo {
  float: right;
  max-width: 90%;
  margin-top: 6px;
}

.en_TeantReceipView_title {
  margin-bottom: 0px;
  float: left;
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Bold";
  font-size: 18px;
  margin-left: 40px;
}

.en_TeantReceipView_body {
  margin: 37px 40px 55px;
}

.en_TeantReceipView_body2 {
  margin: 18px 40px 0px;
}

.en_TeantReceipView_date {
  color: #b3b3b3;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  margin-top: -5px;
}

.en_TeantReceipView_receip {
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  /* margin-bottom: 5px;  */
}

.en_TeantReceipView_receipEnd {
  /* text-align: end; */
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
}

.en_TeantReceipView_receipNumber {
  color: #b3b3b3;
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
}

.en_TeantReceipView_receipL {
  float: left;
}

.en_TeantReceipView_receipR {
  float: right;
}

.en_TeantReceipView_landlord {
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
  color: #212121;
}

.en_TeantReceipView_moneyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.en_TeantReceipView_moneyContainerDiv {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.en_TeantReceipView_receipAmount {
  font-family: "SF-Pro-Display-Bold";
  font-size: 18px;
  color: #5fb563;
  display: flex;
  justify-content: flex-end;
}

.en_TeantReceipView_cash {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #212121;
  display: flex;
  justify-content: flex-end;
}

.en_TeantReceipView_cashLogo {
  margin-left: 6px;
}

.en_TeantReceipView_btnModal {
  margin-top: 32px;
  color: white;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  background-color: #27aad8;
  border: 1px solid #27aad8;
  border-radius: 4px;
  height: 40px;
  width: 130px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

/*************************pswTeant*********************/

.en_pswTenantGreat {
  margin-left: 15%;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 24px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_pswTenantCreatepsw {
  margin-left: 9%;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 10px;
}

.en_pswTenant_label {
  font-family: "Avenir-Next-Medium";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.en_pswTenantLogoT {
  text-align: right;
}

.en_cnxTenantEnterPassword {
  margin-left: 9%;
  height: 11px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 27px;
}

.en_smsVerificationTenantGrey {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  /* margin-left: 35px; */
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
}

.en_forgotPasswordCode {
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
  padding-left: 10%;
}

.en_pswTenantnewPassword {
  height: 16.5px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.en_ConfirmPassResetTenant {
  font-family: "Avenir-Next-Medium";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.en_pswTenantRestPasswordContainer {
  padding-left: 13%;
}

.en_tenantNumber_inputNumber .ant-input-affix-wrapper {
  font-family: "Avenir-Next-Medium";
}

/*------- edit Tenant Profile -------*/

.en_tenantProfile_editBtn {
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.en_tenantProfile_editBtn img {
  width: 100%;
  max-height: 120px;
  object-fit: cover;
}

.en_tenantProfile_editBtn:hover {
  border: none;
  height: 100%;
  width: 100%;
}

.en_tenantProfile_editBtnContainer {
  display: flex;
}

.en_tenantProfile_editInput {
  width: 190px;
  border: 1px solid #cccccc;
  height: 40px;
  border-right: none;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.en_tenantProfile_upload {
  position: relative;
}

.en_tenantProfile_upload .ant-upload-list-item-info {
  position: absolute;
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  top: -40px;
  left: -210px;
}

.en_tenantProfile_upload .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent;
}

.en_tenantProfile_upload .ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  display: none;
}

/******************In Advance Pay  ***************************/

.en_AdvancePay_contain {
  font-family: "SF-Pro-Display-Regular";
}

.en_AdvancePay_contain .en_show-more .en_mymodula .ant-form-horizontal .ant-form-item .ant-form-item-label>label {
  width: 100%;
  /* font-family: "SF-Pro-Display-Regular";*/
  font-family: "SF-Pro-Display-Medium";
  font-size: 13px;
  color: #666666;
}

.en_AdvancePay_contain .en_Rental-input-select {
  position: absolute;
  right: 22%;
  top: 62%;
}

.en_AdvancePay_contain .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}

.en_Months_container {
  display: flex;
  justify-content: space-between;
  width: 82%;
}

.en_Months_container {
  margin-top: 9%;
}

.en_month_plus_sous p {
  color: #212121;
}

.en_monthnumber {
  color: #212121;
  border: none;
  width: 20px;
  padding: 0;
}

.en_advancedPay_monthnumber {
  color: #212121;
  border: none;
  width: 20px;
  padding: 0;
  display: inline-block;
  margin: 0px 33px;
  text-align: center;
}

.en_Pay_Adv_Btn {
  width: 78%;
  border-radius: 2px;
  background-color: #27aad8;
  padding: 4% 0;
  color: white;
  text-align: center;
  padding: 3% 0;
  margin-top: 8%;
  cursor: pointer;
  font-size: 15px;
}

.en_month_plus_sous {
  display: flex;
  justify-content: space-between;
  width: 58%;
  flex-wrap: wrap;
}

.en_month_plus_sous img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.en_month_plus_sous p {
  color: #212121;
}

.en_monthnumber {
  color: #212121;
  border: none;
  width: 20px;
  padding: 0;
}

.en_Pay_Adv_Btn {
  width: 78%;
  border-radius: 2px;
  background-color: #27aad8;
  padding: 4% 0;
  color: white;
  text-align: center;
  padding: 3% 0;
  margin-top: 8%;
  cursor: pointer;
  font-size: 15px;
}

.en_Pay_Adv_Btn:last-child {
  background-color: #ffffff;
  color: #3c3b3b;
  margin-top: 3%;
  cursor: pointer;
  font-family: SF-Pro-Display-Semibold;
  margin-bottom: 4%;
}

.en_AdvancePay_contain .en_mymodula {
  top: 2%;
}

.en_PAmout_container {
  display: flex;
  justify-content: space-between;
  width: 78%;
  font-size: 17px !important;
}

.en_PAmout {
  color: #5fb563;
  font-family: "SF-Pro-Display-Bold";
}

.en_PAmount_title {
  color: #212121;
  font-weight: 600;
}

.en_AdvancePay_contain .en_show-more .en_mymodula hr {
  margin-left: -48px;
  border-top: 1px solid #cccccc12;
  margin-top: 9%;
}

.en_listMonth {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 58%;
}

.en_listMonth p {
  width: 31px;
  cursor: pointer;
  margin-right: 4%;
}

.en_elpayed {
  text-decoration: line-through;
  color: #b3b3b3;
}

.en_paydAdv {
  color: #b3b3b3 !important;
}

.en_paydAdv2 {
  color: #27aad8 !important;
}

/*-----------------*/

.en_containerDashboard {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .en_containerDashboard {
    max-width: 940px;
  }
}

@media (min-width: 992px) {
  .en_containerDashboard {
    max-width: 100%;
  }
}

/*-------Table-----------*/

.en_transfers-table {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
}

.en_advancedPay_modal_body>* {
  margin-top: 20px;
}

.en_advancedPay_tittle_mounth {
  color: #666666;
  font-family: "SF-Pro-Display-Medium";
  line-height: 19px;
  font-size: 14px;
}

.en_advancedPay_amount {
  font-size: 18px;
  line-height: 19px;
  color: #666666;
}

.en_transfers-table td,
.en_transfers-table th {
  /* padding: 10px;*/
}

.en_transfers-table tr:nth-child(even) {
  font-weight: 500;
  background-color: white;
}

.en_transfers-table tr:nth-child(odd) {
  font-weight: 500;
  background-color: #efefef;
}

/* .transfers-table tr:hover {background-color: #ddd;} */

.en_transfers-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d9d9d9;
  color: #808080;
  /* font-weight: 500; */
  font-size: 14px;
  font-family: 'SF-Pro-Display-Medium';
}

.en_transfers-table th:first-child {
  border-top-left-radius: 4px;
}

.en_transfers-table th:last-child {
  border-top-right-radius: 4px;
}

.en_tab-line-qw {
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  /*  line-height: 24px;*/
  text-align: left;
  color: #212121;
}

/****************************notFoundPage******************/

.en_notFoundPageContainer img {
  width: 100%;
  max-width: 642px;
  display: block;
  margin: auto;
  margin-right: auto;
  margin-top: 55px;
  margin-bottom: 45px;
}

@media only screen and (min-width:1000px) and (max-width: 1200px) {
  .en_notFoundPageContainer img {
    width: 45%;
  }
}

.en_notFoundPageTitle {
  display: flex;
  justify-content: center;
  text-align: center;
}

.en_notFoundPageTitle div {
  font-family: 'SF-Pro-Display-Regular';
  font-size: 44px;
  color: #212121;
}

.en_notFoundPageTitle span {
  font-family: 'SF-Pro-Display-Regular';
  font-size: 24px;
  color: #818181;
}

.en_btnReturn {
  margin-top: 30px;
  width: 255px;
  height: 48px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  border-color: #27aad8;
  box-shadow: none;
  border-width: 0px !important;
  cursor: pointer;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 16px;
}

/*-------Tenant-Link---------*/

.en_tenantNumber_container {
  display: flex;
  background-color: #36d1e0;
  justify-content: center;
  min-height: 100vh;
}

.en_tenantNumber_backLogo {
  position: fixed;
  bottom: 0;
}

.en_tenantNumber_numberContainer {
  width: 445px;
  height: 367px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  z-index: 1;
}

.en_tenantNumber_logoWhite {
  position: fixed;
  left: 8%;
  top: 2%;
}

.en_tenantNumber_littleLogo {
  float: right;
  margin-top: 40px;
}

.en_tenantNumber_cardTitle {
  float: left;
  margin: 40px 0px 40px 40px;
  color: #212121;
  font-size: 24px;
}

.en_tenantNumber_label {
  font-size: 16px;
  margin: 127px 0px 24px;
}

.en_tenantNumber_inputContainer {
  padding: 0px 40px;
}

.en_tenantNumber_inputNumber {
  height: 40px;
}

.en_tenantNumber_btn {
  padding: 45px 40px 40px;
  font-family: "Avenir-Next-Bold";
}

@media (max-width: 413px) {
  .en_tenantNumber_numberContainer {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .en_tenantNumber_cardTitle {
    margin: 40px 0px 40px 20px;
  }

  .en_tenantNumber_label {
    font-size: 15px;
  }

  .en_EditPhoneContainer {
    position: absolute;
    right: 5px;
  }
}

/*------------------*/

.en_tab-line-qw {
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  /* line-height: 24px;*/
  text-align: left;
  color: #212121;
}

/* .loginFormButton {
  width           : 100%;
  height          : 40px;
  background-color: #27aad8;
  border-color    : #27aad8;
  font-size       : 16px;
  font-weight     : 500;
  border-style    : none;
  border-radius   : 5px;
  cursor          : pointer;
}

.loginFormButtonDisabled {
  width           : 100%;
  height          : 40px;
  border-radius   : 10;
  background-color: #d6f6f9;
  border-style    : none;
  border-radius   : 5px;
  cursor          : not-allowed;
} */

.loginFormButton {
  width: 100%;
  height: 56px;
  background-color: #36d1e0;
  border-color: #36d1e0;
  font-size: 16px;
  /* font-family: "Avenir-Next-Bold"; */
  font-weight: 500;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginFormButton:hover {
  width: 100%;
  height: 56px;
  background-color: #36d1e0;
  border-color: #36d1e0;
  font-size: 16px;
  /* font-family: "Avenir-Next-Bold"; */
  font-weight: 500;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginFormButton:focus {
  width: 100%;
  height: 56px;
  background-color: #36d1e0;
  border-color: #36d1e0;
  font-size: 16px;
  /* font-family: "Avenir-Next-Bold"; */
  font-weight: 500;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginFormButton:disabled,
.loginFormButton[disabled]:hover {
  width: 100%;
  background-color: #d6f6f9;
  border-color: #27aad8;
  font-size: 16px;
  font-weight: 500;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginFormButtonDisabled {
  width: 100%;
  height: 56px;
  border-radius: 10;
  /* font-family: "Avenir-Next-Bold"; */
  background-color: #d6f6f9;
  border-style: none;
  border-radius: 5px;
  cursor: not-allowed;
}

.TenantConnexionPhone {
  width: 100%;
  height: 56px;
  background-color: #36d1e0;
  border-color: #27aad8;
  font-size: 16px;
  font-weight: 500;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}

/*------history tenant --------*/

.en_historyTenant_btn {
  height: 40px;
  width: 100%;
  width: 130px;
  border-radius: 4px;
  background-color: #dde3e9;
  border: 1px solid #dde3e9;
  color: #1d456d;
  font-size: 14px;
  font-family: "SFProDisplay";
  font-family: "SF-Pro-Display-Semibold";
  text-align: center;
  cursor: pointer;
}

.en_historyTenant_unitNum {
  color: #b3b3b3;
}

/*************************Modal uikit payment******************/

.en_propertyNameUikitModalPayment {
  width: 63px;
  height: 21px;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-top: 32px;
}

.en_propertyValueUikitModalPayment {
  width: 125px;
  height: 21px;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  margin-top: 32px;
}

.en_styleButtonCancelUikitModalPayment {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #212121;
  margin-left: 65px;
  font-family: "SF-Pro-Display-Semibold";
  margin-top: 7px;
}

.en_styleButtonCancelUikitModalPayment:hover {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  margin-top: 7px;
}

.en_styleButtonCancelUikitModalPayment:focus {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #212121;
  margin-top: 7px;
}

.en_styleButtonPayNowUikitModalPayment {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
  font-family: "SF-Pro-Display-Bold";
  margin-bottom: 10px;
  font-size: 16px;
}

.en_styleButtonPayNowUikitModalPayment:focus {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
}

.en_styleButtonPayNowUikitModalPayment:hover {
  width: 228px;
  height: 48px;
  border-radius: 4px;
  background-color: #5fb563;
  border-color: #5fb563;
  color: white;
}

.en_PayWithStyle {
  width: 67px;
  height: 21px;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-top: 10px;
}

.en_AddCardModalPay {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: #27aad8;
  color: white;
  box-shadow: none;
  border-width: 0px !important;
  cursor: pointer;
  margin-bottom: 39px;
}

.en_addCardModalUikitpayment {
  width: 56px;
  height: 16px;
  font-family: "SFProDisplay";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.en_knetUikitModalPayment {
  width: 36px;
  height: 21px;
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_contentTextStyleUikitModalPayment {
  width: 106px;
  height: 29px;
  font-family: "SF-Pro-Display-Bold";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-bottom: 32px;
}

.en_contentTextStyle2UikitModalPayment {
  height: 14.5px;
  font-family: "SFProDisplay";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.en_propertyValueUikitModalPaymentGreen {
  width: 80px;
  height: 21px;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #5fb563;
  margin-top: 32px;
}

/*******Rentals Status**************/

.en_rentalsStatus-container {
  margin-left: 6%;
  margin-right: 6%;
  margin-bottom: 30px;
  margin-top: 45px;
}

#en_rentalsstatus-child {
  background: white !important;
  border-radius: 2px;
  margin-bottom: 35px;
  min-height: 93%;
}

.en_rentalsStatus-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px #cccccc solid;
  height: 22%;
  padding: 16px 24px;
  /* padding-left: 5%;
  padding-right  : 5%;
  padding-top    : 4%; */
}

.en_rentals_statusA {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #dff0e0;
  color: #5fb563;
  text-align: center;
  padding-top: 3.5px;
  font-family: "SF-Pro-Display-Semibold";
}

.en_rentals_status_Pending {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #fcf0df;
  color: #f1b55f;
  text-align: center;
  padding-top: 3.5px;
  font-family: "SF-Pro-Display-Semibold";
}

.en_rentals_statusB {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #f5d8d8;
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Semibold";
  text-align: center;
  /* padding-left: 2%; */
  padding-top: 3.5px;
  font-family: "SF-Pro-Display-Semibold";
}

.en_rentals_statusC {
  width: 81.5px;
  height: 31px;
  border-radius: 14px;
  background-color: #fcf0df;
  color: #f1b55f;
  text-align: center;
  padding-top: 3.5px;
  font-family: "SF-Pro-Display-Semibold";
}

.en_ViewContractRentals,
.en_ViewContractRentals:hover,
.en_ViewContractRentals:focus {
  width: 130px;
  height: 40px;
  background-color: #27aad8;
  color: white;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #27aad8;
}

.en_rowViewContract {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}

.en_rentalsStatus_type {
  font-family: SF-Pro-Display-Medium;
  font-size: 16px;
  color: #808080;
}

.en_rentalsStatus-body {
  padding: 3% 24px;
}

.en_rentalsStatus_prop {
  font-family: SF-Pro-Display-Medium;
  font-size: 16px;
  color: #212121;
  padding-bottom: 8px;
}

.en_rentalsStatus_unit {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #808080;
}

.en_rentalsStatus_adrss {
  display: flex;
  margin-top: 3%;
}

.en_rentalsStatus_adrss div {
  margin-left: 2%;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #808080;
}

.en_rentalsStatus_adrss img {
  width: 14px;
  height: 20px;
}

.en_Hroom-rental {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 58%;
  margin-top: 3%;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #b3b3b3;
}

.en_Hroom-rental-p {
  padding-right: 16px;
}

.en_Hroom-rental-p2 {
  padding: 0px 16px;
}

@media screen and (max-width: 414px) {
  .en_Hroom-rental-p {
    padding-right: 5px;
  }

  .en_Hroom-rental-p2 {
    padding: 0px 5px;
  }
}

/* .en_Hroom-rental p span {
  margin-right: 7px;
  color       : #000000;
} */

.en_Hroom-rentalNumbr {
  display: flex;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
}

.en_Hroom-rentalNumbr p:first-child {
  font-family: "SF-Pro-Display-Medium";
  color: #212121;
}

.en_Hroom-rentalNumbr p:last-child {
  font-family: "SF-Pro-Display-Semibold";
  color: #5fb563;
  margin-left: 4%;
}

.en_ModalAddCardTitle {
  width: 49px;
  height: 14.5px;
  font-family: "SF-pro-bold";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  margin-bottom: 40px;
}

.en_ModalAddCardStyle {
  padding-left: 30px;
}

.en_ModalAddCardLabelStyle {
  width: 50px;
  height: 8px;
  font-family: "SF-pro-Regular";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.en_ModalAddCardInputStyle {
  padding-bottom: 20px;
}

.en_ModalAddCardButtonAdd {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "SF-pro-Regular";
  font-size: 14px;
}

.en_ModalAddCardButtonAdd:focus {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "SF-pro-Regular";
  font-size: 14px;
}

.en_ModalAddCardButtonAdd:hover {
  height: 40px;
  border-radius: 2px;
  background-color: #27aad8;
  color: white;
  width: 295px;
  border-radius: 4px;
  font-family: "SF-pro-Regular";
  font-size: 14px;
}

.en_ModalAddCardButtonCancel {
  height: 9.5px;
  font-family: "SF-pro-Regular";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  cursor: pointer;
  color: #666666;
}

.en_RowStyleUikitModalPayment {
  margin-left: 20px;
}

.en_styleButtonGlobalUikitModalPayment {
  padding-top: 25px;
}

.en_imgStyleModalUikitPayment {
  text-align: right;
  bottom: 2px;
}

.en_RowStyleUikitModalPayment2 {
  margin-left: 20px;
}

.input_password_cnx {
  height: 40px;
}

.en_connection_tenant_card {
  width: 445px;
  height: 402px;
  margin: auto;
  margin-top: calc((100vh - 445px) / 3);
  background-color: white !important;
  z-index: 10;
}

.en_connection_tenant_fpsd_card {
  width: 445px;
  min-height: 435px;
  /*margin-top: calc((100vh - 445px) / 3);*/
  z-index: 10;
  background-color: white !important;
}

.en_connection_tenant_verif_card {
  width: 445px;
  min-height: 555px;
  margin-top: calc((100vh - 600px) / 3);
  z-index: 10;
  background-color: white;
  padding: 25px 20px;
}

.InfosProfilstyle {
  width: 100%;
}

.en_tab-line-propUnit div {
  padding-top: 1.5em;
}

.en_transfers-table td,
.en_transfers-table th {
  padding: 10px;
}

@media (max-width: 396px) {
  .en_Hroom-rental {
    width: 100%;
    flex-wrap: wrap;
  }
}

/******************Spinner**********/

.en_dashboard_spinContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 40vh;
  -moz-animation: cssAnimation 0s ease-in 10s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 10s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 10s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 10s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.en_dashboard_spinContainerHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 65vh;
  -moz-animation: cssAnimation 0s ease-in 10s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 10s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 10s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 10s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.en_dashboard_spinText {
  font-size: 16px;
  font-weight: 500;
  color: #27aad8;
  margin-top: 5px;
}

.stepLoading {
  position: absolute;
  height: 55px;
  width: 0px;
  background-color: #27aad8;
  animation-name: mymoveloding;
  animation-duration: 3s;
  left: 0px;
}

@keyframes mymoveloding {
  from {
    width: 0px;
  }

  to {
    width: 200px;
  }
}

.spinTenantContainer_history {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinTenantContainer {
  flex-direction: column;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_spinContainerNone {
  display: none;
}

#notificationBank2 {
  -moz-animation: cssAnimation 0s ease-in 10s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 10s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 10s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 10s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  from {
    width: 0px;
    overflow: hidden;
    height: 0;
  }

  to {
    width: 100px;
  }
}

@-webkit-keyframes cssAnimation {
  from {
    width: 0px;
    overflow: hidden;
    height: 0;
  }

  to {
    width: 100px;
  }
}

/*******************AdvancedPay Modal*******************/

.en_label-inputPaym {
  color: #666666;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium" !important;
}

.ant-select-selector {
  border-radius: 4px !important;
  height: 38px !important;
}

.ant-select-selection-placeholder {
  align-items: center;
  align-content: center;
  display: flex;
  font-size: 14px;
  font-family: "SF-Pro-Display-Regular";
}

button:focus {
  outline: none;
}

/*************************************statistic*****************/

.en_staticAllCard {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding-top: 40px;
}

.en_outstandingCercle {
  width: 168px;
  height: 168px;
  background-color: #f5d8d8;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_spin_big_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

.dashboard_spin_big_Container_fullHeight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.en_cardStatic {
  min-width: 350px;
  min-height: 355px;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.en_cardStaticBottom {
  /* margin-top: 30px; */
  /* margin-top: 5px; */
  margin-bottom: 5px;
  min-width: 350px;
  min-height: 355px;
}

.en_outstandingValue {
  color: #cb3d3d;
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
}

#en_payNowStaticOutstanding {
  width: 135px;
  color: white;
  background-color: #5fb563;
  border-color: #5fb563;
  border-radius: 4px;
  height: 40px;
  /* font-weight: 500; */
  margin-bottom: 30px;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
}

.en_statisticOutstanding {
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 25px;
  color: #212121;
}

.en_payNowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}

.en_outstandingCardCercle {
  display: flex;
  justify-content: center;
}

.en_outstandingValueKWD {
  color: #808080;
  font-size: 16px;
  font-family: "SF-Pro-Display-Bold";
  text-align: center;
}

.en_outstandingStatisticAllValue {
  text-align: center;
}

.en_statisticCercleMaintenance {
  height: 56px;
  width: 56px;
  background-color: #f5d8d8;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dff0e0;
}

.en_statisticCercleMaintenanceResolved {
  height: 56px;
  width: 56px;
  background-color: #dff0e0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en_statisticCercleMaintenceValue {
  color: #cb3d3d;
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
}

.en_statisticCercleMaintenceValueResolved {
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
  color: #5fb563;
}

.en_statisticMaintenanceAllCercle {
  padding: 20px;
}

.en_statisticMaintenanceResolved {
  display: flex;
  justify-content: center;
}

.en_cardStatistic {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.en_statisticMaintenanceSmallCercleLabel {
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  color: #b3b3b3;
  margin-bottom: 8px;
}

.en_statisticSeeAllMaintenance {
  display: flex;
  align-items: center;
  height: 135px;
  justify-content: center;
  padding-top: 24px;
  position: relative;
}

#en_seeAllMaintenanceStatistic {
  width: 135px;
  color: #4d4d4d;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 40px;
  /* font-weight: 500; */
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  margin-bottom: 30px;
  position: absolute;
  bottom: 0px;
}

.en_outstandingValueDays {
  color: #808080;
  font-size: 18px;
  font-family: "SF-Pro-Display-Medium";
  text-align: center;
}

.en_statisticCardCercleGrey {
  display: flex;
  justify-content: center;
}

.en_statisticCercleGrey {
  width: 168px;
  height: 168px;
  background-color: #e6e6e6;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en_statisticValueGreyCercle {
  color: #212121;
  font-size: 32px;
  font-family: "SF-Pro-Display-Bold";
}

.en_labelSelectUnitStatistic {
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  margin-right: 32px;
}

.en_selectUnitStatistic {
  padding-top: 30px;
}

.en_optionUnitStatistic {
  font-size: 14px;
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
}

/*************************Modal view contract **********************/

.en_viewContract_title {
  font-size: 24px;
  color: #212121;
  font-family: "SF-Pro-Display-Bold";
}

.en_viewContract_title_container {
  display: flex;
}

.en_viewContract_logo {
  right: 0px;
  position: absolute;
  width: 154px;
  height: 40, 29px;
}

.en_viewContract_contract_info {
  margin-top: 55px;
  display: flex;
}

.en_viewContract_date_logo {
  width: 18px;
  margin-right: 11px;
}

.en_viewContract_date {
  color: #808080;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  margin-right: 24px;
}

.en_viewContract_dateValue {
  color: #212121;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
}

.en_viewContract_to {
  font-size: 16px;
  margin-left: 22px;
  margin-right: 16px;
  font-family: "SF-Pro-Display-Medium";
  color: #808080;
}

.en_viewContract_contract_amount_info {
  margin-top: 24px;
  display: flex;
}

.en_viewContract_money_logo {
  margin-right: 16px;
  width: 11px;
}

.en_viewContract_amount_value {
  color: #5fb563;
  font-size: 16px;
  font-family: "SF-Pro-Display-Semibold";
  margin-right: 29px;
}

.en_viewContract_btn {
  margin-top: 58px;
  color: white;
  background-color: #36d1e0;
  height: 56px;
  width: 334px;
  border-radius: 4px;
  border: 1px solid #36d1e0;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
  cursor: pointer;
}

.en_accept_contract_modal .ant-modal-body {
  padding: 0px !important;
}

.en_accept_contract_modal.ant-modal {
  width: 414px !important;
  padding: 0px !important;
}

.en_accept_contract_modal .ant-modal-content {
  height: 471px !important;
  padding: 40px 0px 40px 40px;
}

.en_accept_contract_msg {
  font-family: "Avenir-Next-Demi-Bold";
  color: #212121;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 9px;
}

.en_accept_contract_msg_access {
  font-family: "Avenir-Next-Medium";
  color: #808080;
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.en_acceptContract_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-right: 40px;
  text-align: center;
}

/*---- pagination ----*/

.historyPagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/*----pagintation----*/

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 50%;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #212121;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #212121;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #e6e6e6;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: white;
  background: #27aad8;
  border-color: #27aad8;
}

.ant-pagination-item-active a {
  color: white;
  background: #27aad8;
  border-color: #27aad8;
}

.ant-pagination-item-active {
  background: #27aad8;
  border-color: #27aad8;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/******************Maintenance**************************/

.DashboardOpenTicketBtn {
  border-radius: 4px;
  background-color: #1d456d;
  height: 40px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}

.en_DashboardNavBarBtnTxt {
  font-family: "SF-Pro-Display-Semibold";
  color: #ffffff;
  font-size: 14px;
}

.en_maintenance-btn-container {
  margin-bottom: 30px;
  padding-top: 45px;
  padding-left: 12%;
}

.en_maintenance-container {
  background-color: #f7f7f7;
  padding-bottom: 30px;
  min-height: 100vh;
}

.en_maintenance-container-block {
  margin-left: 12%;
  padding-right: 12%;
}

.en_maintenance-btn-list {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #212121;
  width: 150px;
  text-align: center;
  height: 40px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
}

.en_maintenance-btn-list-start {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #212121;
  width: 20%;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
}

.en_maintenance-btn-list-e {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  background-color: #27aad8;
  width: 150px;
  text-align: center;
  height: 40px;
  padding-top: 3px;
  border: 1px solid #27aad8;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
}

.en_maintenance-btn-list-e-start {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: white;
  background-color: #27aad8;
  width: 20%;
  text-align: center;
  height: 40px;
  border: 1px solid #27aad8;
  cursor: pointer;
  font-family: "SF-Pro-Display-Medium";
}

.ant-dropdown-menu-item-selected p {
  color: #212121;
}

.ant-dropdown-menu-item-selected a {
  color: #212121 !important;
}



@media (max-width: 465px) {
  .en_PayAdvance {
    background-color: #e6e6e6;
    border-radius: 5px;
    height: 40px;
    width: 130px;
    font-family: "SF-Pro-Display-Semibold";
    color: #4d4d4d;
    font-size: 14px;
    margin-bottom: 10px;
    float: left;
    margin-right: 3%;
  }

  .en_invoices_select_filter {
    width: 80% !important;
    margin-top: 20px;
    margin-left: 0;
  }

  .en_acount-invoices-img-select {
    position: absolute !important;
    right: 80px !important;
    top: 45% !important;
  }

  .en_maintenance-btn-list-e {
    width: 30% !important;
  }

  .en_maintenance-btn-list {
    width: 100px;
  }

  .en_maintenance-btn-list-e-start {
    width: 30% !important;
  }

  .en_maintenance-btn-list-start {
    width: 30% !important;
  }
}

.en_maintenanceEditIcon {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  cursor: pointer;
}

.en_resolved_Container {
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  height: 372px;
}

.en_resolved_Container_block {
  height: 60px;
  background-color: #dff0e0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.en_resolved_Container_txt {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #5fb563;
}

.en_resolved_Container_picture {
  width: 100%;
  height: auto;
}

.en_resolved_Container_info {
  padding: 24px;
}

.en_unresolved_Container_info {
  padding: 24px;
  height: 188px;
}

.en_resolved_Container_description {
  display: flex;
  justify-content: space-between;
}

.en_resolved_Container_descriptionTxt {
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
  color: #000000;
  width: 80%;
  overflow: auto;
}

.en_resolved_Container_descriptionDate {
  font-family: "SF-Pro-Display-Medium";
  font-size: 12px;
  color: #b3b3b3;
}

.en_resolved_Container_descriptionUnit {
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
  color: #808080;
  margin-bottom: 10px;
  width: 100%;
  overflow: auto;
}

.en_resolved_Container_descriptionUnitScroll {
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
  color: #808080;
  margin-bottom: 16px;
  width: 100%;
}

.en_unresolved_Container_block {
  height: 60px;
  background-color: #f4d4d4;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.en_unresolved_Container_txt {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #cb3d3d;
}

.en_maintenance-Modal-select {
  width: 100%;
}

.en_maintenance-Modal-container {
  margin: 12px 30px;
}

.en_maintenance-Modal-txt {
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
  color: #666666;
  margin-top: 18px;
}

.en_maintenance-btn-Link {
  margin-top: 40px;
}

.en_maintenance-Modal_modal_body>* {
  margin: 0px 20px;
}

.en_maintenance-Modal-Input {
  height: 60px;
  font-family: "SF-Pro-Display-Regular" !important;
  font-size: 14px !important;
}

.en_maintenance-Modal-Image {
  width: 100%;
  height: auto;
}

.en_maintenance-Modal-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.en_maintenance-Modal-uploadImage {
  width: 50px;
  height: 50px;
}

.en_maintenance-Modal-uploadImageTxt {
  color: #27aad8;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  margin-top: 8px;
}

.en_maintenanceModalText {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #212121;
  text-align: center;
}

.en_maintenanceDeleteBtn {
  width: 104px;
  color: white;
  background-color: #cb3d3d;
  border-radius: 4px;
  height: 32px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.en_maintenanceDeleteBtn:hover {
  width: 104px;
  color: white;
  background-color: #cb3d3d;
  border-radius: 4px;
  height: 32px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.en_maintenanceCancelbtn {
  width: 100px;
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 32px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.en_maintenanceCancelbtn:hover {
  width: 100px;
  color: #212121;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: 32px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.en_maintenanceCancelBtns {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .Rectangle-687 {
    width: auto;
  }

  .en_styleButtonCancelUikitModalPayment {
    margin-left: 0px;
    margin-bottom: 12px;
  }
}

.Rectangle-687 {
  padding: 20px;
}

@media only screen and (max-width: 992px) {
  .en_table {
    width: 1024px;
  }

  .en_tenantPaymentHistoryContainer {
    overflow: scroll;
    padding-right: 5px;
  }

  .en_Total {
    margin-right: 20px !important;
  }

  .en_TotalEmpty {
    margin-right: 20px !important;
  }

  .en_paymentTotal {
    margin-left: 20px !important;
    padding-right: 10px;
  }

  .Rectangle-687 {
    margin-top: 10px;
  }

  .en_transfers-table {
    padding-left: 20px;
  }

  .en_styleButtonCancelUikitModalPayment {
    width: -webkit-fill-available !important;
    margin-right: 0px !important;
  }

  .en_styleButtonPayNowUikitModalPayment {
    width: -webkit-fill-available !important;
  }
}

.en_smsVerification--resendCodeMessage {
  width: 100%;
  text-align: center;
  color: #4ead53;
  display: block;
  margin-top: 10px;
}

.en_alertPaymentModal--alertIcon--container {
  display: flex;
  margin-bottom: 15px;
}

.en_alertPaymentModal--alertIcon {
  margin: auto;
}

.en_alertPaymentModal--monthToPay {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  margin-bottom: 24px;
  font-family: 'SF-Pro-Display-Medium';
}

.en_alertPaymentModal--monthToPayDuePayement {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  margin-bottom: 5px;
  font-family: 'SF-Pro-Display-Medium';
}

.en_alertPaymentModal--monthToPayDate {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #212121;
  font-style: normal;
  font-weight: bold;
}

.en_alertPaymentModal--cancelButton button {
  font-family: 'SF-Pro-Display-Medium';
}

.en_alertPaymentModal--cancelButton {
  display: flex;
  justify-content: center;
}

.en_advancedPay--calandarContainer {
  width: 57%;
  justify-content: flex-end;
}

.en_calandarContainer {
  margin-left: 10px;
}

.en_textColor__unit {
  color: #b3b3b3;
}

.phoneInputUIKIT {
  height: 0.5px;
  direction: ltr;
  background-color: transparent;
  width: 100%;
  color: #000;
}

.phoneInputUIKITEN {
  height: 0.5px;
  background-color: transparent;
  width: 100%;
  color: #000;
}

.PhoneInput__typographyStyle {
  right: 12px;
  color: #cccccc;
  position: absolute;
}

.tenantViewRecipt__NotCaptured {
  color: red;
  text-align: end;
}

.tenantViewRecipt__container {
  margin-left: 40px;
  margin-right: 40px;
}

.en_dashboard_spinContainerImg {
  width: 200px;
  position: relative;
}

.en_OpenTicketModal_submitBtn {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "SF-Pro-Display-Semibold";
}

.en_OpenTicketModal_submitBtn:hover {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "SF-Pro-Display-Semibold";
}

.en_OpenTicketModal_submitBtn:focus {
  width: 100%;
  height: 48px;
  background-color: #27aad8;
  border-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: "SF-Pro-Display-Semibold";
}

.en_OpenTicketModal_cancelBtn {
  width: 100%;
  height: 48px;
  background-color: transparent;
  border-color: transparent;
  color: #666666;
  font-size: 16px;
  cursor: pointer;
  font-family: "SF-Pro-Display-Semibold";
}

.en_advancedPay--calandarContainer--calandar {
  display: -webkit-box;
  overflow-x: scroll;
}

.accessDashbord_img {
  width: 200px;
  position: relative;
}

.maintenance_openTicket_error {
  color: #cb3d3d;
}

.en_cancelPictureMaintenance {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  right: 50px;
}

.en_openTicket_upload_container {
  position: relative;
  margin-top: 24px;
}

.en_delete_img_openTicket {
  position: absolute;
  top: 0px;
  right: 10px;
}


@media (max-width: 1046px) {
  .en_container_profiletop {
    margin-left: 0px;
    padding: 15px;
    margin-right: 0px;
    overflow: auto;
  }

  .en_container_profilebottom {
    margin-left: 0px;
    padding: 15px;
    margin-right: 0px;
    overflow: auto;
  }

  .editPsw_input_container {
    flex-direction: column;
  }

  .en_confirm_editPswd {
    margin-left: 0px;
  }
}

.en_openTicket_upload_btn .ant-upload.ant-upload-select-picture-card>.ant-upload {
  width: 287px;
  height: 88px;
}
.en_openTicket_upload_btn .ant-upload.ant-upload-select-picture-card {
  margin-right: 0;
}

.mobileMenuDropdownContainer {
  justify-content: space-between;
  display: flex;
  margin: 0px 20px;
}

.dropDownMenu--container {
  width: 100%;
  padding-top: 21px;
}

.en_navBar--menuIcon--margin {
  margin-right: 41px;
}

.mobileMenuDropdownContainer {
  justify-content: space-between;
  display: flex;
  margin: 0px 20px;
}

.ant-dropdown-menu-item.en_setting-menuItem-logout.ant-dropdown-menu-item-only-child {
  border-top: 1px solid #b3b3b3;
}

.en_navBar--menuIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 26px;
}

@media (max-width: 500px) {
  .en_cardStaticBottom {
    /* margin-top: 30px; */
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 250px;
    min-height: 355px;
  }

  .en_cardStatic {
    min-width: 250px;
    min-height: 355px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .en_navBar--menuIcon {
    margin-top: 5px;
  }

  .ant-menu-item .en_setting-menuItem-logout .ant-menu-item-only-child {
    padding-bottom: 0px !important;
  }

  .en_cancelEditButton {
    width: 100%;
    text-align: left;
  }
}

/******************************Messaging**********************/

.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: calc(100% - 465px);
  bottom: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.en_compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px !important;
  background: none;
  font-family: inherit;
  padding-right: 50px;
  resize: none;
  border: none;
  padding-top: 11px;
}

.en_compose-input:focus {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
  outline: none;
  padding-top: 11px;
}

.en_compose-input.ant-input:focus {
  -webkit-box-shadow: none
}

.en_compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.conversation-list {
  display: flex;
  flex-direction: column;
}

.en_conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.en_conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.en_conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.en_conversation-list-item-msg {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #d3eef7;
  cursor: pointer;
}

.en_conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.en_conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.conversation-search {
  padding: 10px;
  display: flex;
}

.en_conversation-search-input {
  background: #f8f8f8;
  padding: 8px 10px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  margin: 32px 24px;
}

.en_conversation-search-input .ant-input-clear-icon {
  font-size: 17px;
}

.en_conversation-search-input .ant-input:placeholder-shown {
  font-size: 12px;
  color: #b3b3b3;
  background-color: transparent;
  font-family: "SF-Pro-Display-Regular";
}

.en_conversation-search-input:focus::placeholder {
  text-align: left;
}

/* 
.en_messenger {
  display: grid;
  width: 100%;
  height: 80vh;
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
} */

.container {
  padding: 10px;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

.upload_btn_messenger {
  display: flex;
}

.btnUploadMessenger {
  border: none !important;
  background-color: transparent !important;
}

#uploadImageMessenger {
  transform: rotate(270deg);
}

.en_sendMessenger {
  position: absolute;
  right: 20px;
  bottom: 17px;
}

#_sendMessengerContainer :hover {
  cursor: pointer;
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
  width: 100%;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.mine.start .bubble-container .en_bubble {
  margin-top: 10px;
}

.message.mine.end .bubble-container .en_bubble {
  margin-bottom: 10px;
}

.message .bubble-container .en_bubble {
  margin: 1px 0px;
  background: #e6e6e6;
  border-radius: 4px;
  padding: 30px 25px 16px;
  min-width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  color: #212121;
  max-width: 52%;
  line-break: anywhere;
}

.message.mine .bubble-container .en_bubble {
  margin-right: 32px;
  background: #1d456d;
  color: white;
  border-radius: 4px;
  padding: 30px 25px 16px;
  min-width: 300px;
  min-height: 81px;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  align-items: center;
  max-width: 55%;
  overflow-wrap: anywhere;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .compose {
    width: calc(100% - 315px);
  }

  .message.mine .bubble-container .en_bubble {
    max-width: 61%;
  }
}

.en_message_date_messenger_mine {
  font-family: "Avenir-Next-Medium";
  font-size: 12px;
  color: #ffffff;
  text-align: right;
  min-width: 150px;
  margin-bottom: 0px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.en_message_date_messenger {
  font-family: "Avenir-Next-Medium";
  font-size: 12px;
  color: #808080;
  text-align: right;
  min-width: 150px;
  margin-bottom: 0px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.message-list-container {
  padding: 10px;
  padding-bottom: 70px;
}

.en_messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 102px);
  background: #eeeef1;
  grid-template-columns: 464px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: #f7f7f7;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

.toolbar {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  z-index: 2222;
  position: sticky;
  margin-bottom: 20px;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.left-items,
.right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}

.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}

.scrollable .content {
  background-color: #f7f7f7;
}

input:focus {
  outline: none;
}

.end {
  margin-bottom: 70px;
}

.discussion_container_mine {
  display: inline;
}

.discussion_container {
  display: flex;
}

.discussion_noLogo_container {
  display: none;
}

.en_discussion_logo_container {
  margin-left: 32px;
  display: flex;
  min-height: 81px;
  align-items: center;
  margin-right: 14px;
}

.en_conversation-photo-history {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}

.en_conversation-fleche-bubble {
  position: absolute;
  left: 121px;
}

.toolbar-title {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
  color: #212121;
}

.en_conversation-time-info {
  position: absolute;
  right: 24px;
  color: #b3b3b3;
  font-size: 12px;
  font-family: "SF-Pro-Display-Medium";
}

.toolbar-view-unit {
  font-size: 16px;
  color: #36d1e0;
  font-family: "Avenir-Next-Demi-Bold";
}

.toolbar-delete-conversation {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
  color: #212121;
  cursor: pointer;
}

.btn-delete-modal-confirmation-delete-conversation {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
  margin-left: 50px;
}

.btn-delete-modal-confirmation-delete-conversation:hover {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
}

.btn-delete-modal-confirmation-delete-conversation:focus {
  background-color: #cb3d3d;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
}

.btn-cancel-modal-confirmation-delete-conversation {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
  float: right;
  margin-right: 50px;
}

.btn-cancel-modal-confirmation-delete-conversation:hover {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
  float: right;
}

.btn-cancel-modal-confirmation-delete-conversation:focus {
  background-color: #e6e6e6;
  color: #4d4d4d;
  font-family: "Avenir-Next-Demi-Bold";
  height: 32px;
  width: 100px;
  float: right;
}

.title-modal-delete-conversation {
  font-size: 16px;
  font-family: "Avenir-Next-Medium";
  color: #212121;
  text-align: center;
  margin-bottom: 28px;
}

.image-message-conversation {
  width: 90%;
  height: 90%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.file-message-container {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: none;
}

@media only screen and (max-width: 767px) {

  .tenantViewRecipt__container,
  .en_TeantReceipView_title,
  .en_TeantReceipView_body2 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .conversation-message-option {
    display: none;
  }

  .message-list-container {
    padding-bottom: 0px;
  }

  .en_conversation-list-item {
    padding: 20px 5px;
  }

  .compose {
    width: 90%;
  }
}

.en_retunIconBack {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-left: 24px;
  cursor: pointer;
}

.en_MessagesText {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #808080;
}

.en_globalStyleModalUikitPayment {
  width: 100%;
}

.en_notifMessageNotRead {
  width: 20px;
  height: 20px;
  background-color: red;
  border-color: red;
  border-radius: 10px;
  float: right;
  position: relative;
  right: 10px;
}

.en_numberMessageNotRead {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  bottom: 13px;
  color: white;
}

.none {
  display: none !important;
}

.cnx_tenant-err {
  position: absolute;
  left: 40px;
  color: #cb3d3d;
  top: 53%;
  font-family: "SF-Pro-Display-Medium";
}

@media (max-width: 413px) {
  .cnx_tenant-err {
    position: absolute;
    left: 40px;
    color: #cb3d3d;
    top: 60%;
    font-family: "SF-Pro-Display-Medium";
  }
}

.en_contract_modal .ant-modal-body {
  padding: 37px 77px 53px 77px;
}

.en_history_load {
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
  position: absolute;
  top: 105px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .message-list {
    overflow: auto;
    max-height: calc(100vh - 280px);
  }

  .message_logo_not {
    left: 80px;
  }

  .discussion_logo_container {
    display: none;
  }

  .message .bubble-container {
    background-color: white;
  }

  .conversation-list-item-msg {
    padding: 0px;
    display: inherit;
    z-index: 1;
    display: flex;
  }

  .en_conversation-list-item {
    padding: 0px;
    display: inherit;
    z-index: 1;
    display: flex;
  }

  .en_messenger {
    display: flex;
    height: 100%;
  }

  .content {
    display: none;
  }

  .compose {
    width: 100%;
    z-index: 2;
  }

  .sidebar {
    width: 100%;
  }

  .en_history_load {
    position: initial;
  }

  .toolbar {
    display: none;
  }

  .en_conversation-fleche-bubble {
    display: none;
  }

  .en_discussion_logo_container {
    display: none;
  }

  .en_conversation-info {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  .en_conversation-time-info {
    /* top: 10px; */
    right: 15px;
  }

  .message .bubble-container .en_bubble {
    width: 90%;
    padding: 30px 16px;
  }

  .message.mine .bubble-container .en_bubble {
    width: 90%;
    margin-right: 0px;
  }
}

.reponsiveMessageList.ant-collapse>.ant-collapse-item {
  display: block;
}

.reponsiveMessageList.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  width: 100%;
}

.reponsiveMessageList .ant-collapse-content>.ant-collapse-content-box {
  height: calc(100vh - 288px);
  padding: 0px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .trasform_arrow.ant-collapse-arrow {
  transform: rotate(180deg);
}

.reponsiveMessageList.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  top: 52px;
}

.pannelHeader {
  z-index: 1;
}

.listItem_responsiveContainer {
  display: flex;
}

.en_ProfilEdit {
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  color: #27aad8;
  cursor: pointer;
}

.en_ProfilEditDisplay {
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  color: #27aad8;
  cursor: pointer;
}

.en_ProfilEditCancel {
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}

.en_updateBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 45px;
}

.en_TeantPhoneNumber_ModalPhone .ant-modal-content {
  height: 377px;
  border-radius: 4px;
  margin-top: 19px;
}

.en_TeantPhoneNumber_ModalPhone .ant-modal-body {
  padding: 48px 104px;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

@media only screen and (max-width: 567px) {
  .en_TeantPhoneNumber_ModalPhone .ant-modal-body {
    padding: 35px 30px;
  }

  .en_contract_modal .ant-modal-body {
    padding: 37px 30px 53px 30px;
  }
}

.en_modalTenant_txt_Phone {
  font-size: 24px;
  font-family: "Avenir-Next-Demi-Bold";
  color: #212121;
  text-align: center;
  margin-bottom: 24px;
}

.en_verifNew_txt {
  color: #808080;
  text-align: center;
  font-size: 16px;
  font-family: "Avenir-Next-Medium";
}

.en_modalTenant_btn:hover {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
}

.en_modalTenant_btn:focus {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
}

.en_modalTenant_btn {
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  color: white;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
}

.en_cancel_newNumber {
  color: #666666;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
  cursor: pointer;
}

.en_errStyle {
  color: #cb3d3d;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
}

.en_forgetPswVerification--inputContainer {
  display: flex;
  justify-content: center;
}

.en_connection_tenant_verif_card_phone {
  max-width: 445px;
  min-height: 555px;
  z-index: 10;
  background-color: white;
  padding: 25px 20px;
}

.en_smsVerificationTenantGrey__reset {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-left: 35px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
}

.en_cancel_validNewNumber {
  display: inline-block;
  padding-top: 16px;
  color: #666666;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
  cursor: pointer;
}

.en_smsVerificationTenantBlue_phone {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  margin-left: 35px;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_language_container {
  margin-right: 27px;
  text-align: center;
  padding: 4% 6%;
  border-radius: 10px;
  background-color: white;
  margin-left: 5%;
  color: #212121;
}

.en_selectLanguage {
  font-size: 18px;
  font-family: "SF-Pro-Display-Medium";
  margin-top: 15px;
  margin-bottom: 25px;
}

.en_language-switch {
  display: flex;
  justify-content: center;
}

.en_empty-style {
  font-size: 24px;
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
}

.en_empty2-style {
  font-size: 12px;
  color: #808080;
  font-family: "SF-Pro-Display-Regular";
}

.ant-carousel .slick-dots li button {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0;
}

.ant-carousel .slick-dots li.slick-active {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0;
}

/********************************Tenant Ajarati ***************************/

.userNumber_btn:hover {
  color: white;
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
}

.userNumber_btn:focus {
  color: white;
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
}

.userNumber_btn {
  color: white;
  background-color: #36d1e0;
  border: 1px solid #36d1e0;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-family: "Avenir-Next-Demi-Bold";
}

.userNumberError {
  color: #cb3d3d;
  padding-left: 40px;
  font-family: "SF-Pro-Display-Medium";
}

.no_userNumberError {
  display: none;
}

.react-tel-input .form-control {
  width: 100% !important;
}

/*--- user verif ----*/

.en_phoneVerification-inputPhoneNumber {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
}

.en_phoneVerification-logo {
  margin-left: 8%;
  margin-top: 2%;
}

.en_connection_tenant_verif_card {
  max-width: 445px;
  min-height: 555px;
  margin-top: calc((100vh - 600px) / 3);
  z-index: 10;
  background-color: white;
  padding: 25px 20px;
}

.en_forgotPasswordThankyou {
  font-size: 24px;
  font-family: "Avenir-Next-Demi-Bold";
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 15px;
}

.en_forgotPasswordMessage {
  color: #808080;
  font-family: "Avenir-Next-Medium";
  font-size: 16px;
}

.en_userSmsCode {
  display: flex;
  justify-content: left;
  padding-top: 7%;
}

.en_smsVerificationTenantBlue {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_smsVerificationTenantBlue_ {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_smsVerificationTenantGrey_ {
  width: 56px;
  margin-left: 35px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_smsVerificationTenantGrey {
  width: 56px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_userResendCode {
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #36d1e0;
  margin-top: 16px;
}

.userVerif_code_container {
  display: flex;
  justify-content: center;
}

.userVerif_card_header {
  text-align: center;
  padding: 8px 10% 0px;
}

.userVerif_card_checkLogo {
  width: 92px;
  height: 92px;
}

.userVerif_btn_container {
  padding: 9% 10% 0px;
  text-align: center;
}

.en_userVerig_propertyLogo {
  position: fixed;
  bottom: 0px;
  padding-left: 15%;
  padding-right: 15%;
}

/*-------User Register-----------*/

.en_pswTenantGreat {
  margin-left: 15%;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.en_pswTenantLogoT {
  text-align: right;
}

.en_pswTenantCreatepsw {
  margin-left: 9%;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
  padding-top: 10px;
}

.en_pswTenant_label {
  font-family: "Avenir-Next-Medium";
  font-size: 14px;
  margin-bottom: 8px;
  color: #808080;
}

.en_updatePasswordButton {
  width: 140px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #27aad8;
  border-color: #27aad8;
  font-family: "SF-Pro-Display-Medium";
  color: white;
  margin-right: 24px;
}

.userRegister_card {
  max-width: 445px;
  height: 453px;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc((100vh - 521px) / 3);
  z-index: 10;
  padding-top: 8%;
}

.userRegister_pswContainer {
  text-align: -webkit-center;
}

.userRegister_btn_Container {
  margin-top: 40px;
}

.userRegister_Passwordcontent {
  font-size: 12px;
}

.userRegister_PasswordGuidance {
  color: #212121;
}

.conversation-list-item-msg {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #d3eef7;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .en_RowStyleUikitModalPayment {
    margin-left: 0px;
  }

  .conversation-list-item-msg {
    padding: 0px;
  }

  .en_conversation-list-item-msg {
    padding: 0px;
  }
}

/*-----Scroll bar----*/

/* width */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 10px;
}

.message_logo_container {
  position: relative;
}

.message_logo_not {
  font-family: "SF-Pro-Display-Bold";
  font-size: 10px;
  position: absolute;
  right: 0px;
  background-color: #cb3d3d;
  border: 1px solid #cb3d3d;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pswd_btn_container {
  padding: 0px 40px;
  text-align: center;
  width: 100%;
}

.en_cnxTenantForgotPassword {
  width: 100%;
  margin-top: 10px;
  color: #27aad8;
}

.en_cnxTenantForgotPassword:hover {
  color: #27aad8;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}



.en_acount-invoices-img-select-suffixIcon {
  position: absolute !important;
  top: -7px !important;
  right: -8px !important;
}

.en_acount-invoices-img-select-suffixIcon-statics {
  position: absolute !important;
  top: -8px !important;
  right: -8px !important;
}

.en_invoices_select_filter .ant-select-selection-placeholder {
  font-family: "SF-Pro-Display-Regular";
}

.en_EditPhoneNumber {
  margin-right: 8px;
  color: #212121;
  font-family: 'SF-Pro-Display-Medium';
  width: 92%;
  overflow-x: auto;
}

.en_EditPhoneNumber::-webkit-scrollbar {
  height: 4px;
}

.delete_logo {
  cursor: pointer;
  margin: 0px 5px;
}

.en_delete_upload_civilId {
  cursor: pointer;
  margin: 0px 0px 0px 10px;
}

.en_delete_upload_civilIdMinWidth {
  position: absolute;
  left: 148px;
}

.en_receipt_invoice_details {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #212121;
  margin-left: 10px;
  direction: rtl;
}

.en_specific_receipt {
  margin-left: 0px;
}

.en_receipt_date_direction {
  direction: rtl;
}

.en_TeantReceipView_receip_ {
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  margin-top: 20px;
}

.en_TeantReceipView_receip_Down {
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
}

.en_receipt_money {
  margin-left: 10px;
  color: #b3b3b3;
  font-size: 16px;
  font-family: "SF-Pro-Display-Regular";
}

@media screen and (max-width: 520px) {
  .en_TeantReceipView_receip_Down {
    color: #808080;
    font-family: "SF-Pro-Display-Medium";
  }

  .en_TeantReceipView .ant-modal-body {
    width: 100%;
    max-height: 567px;
  }

  .en_TeantReceipViewFailed .ant-modal-body {
    width: 100%;
    max-height: 660px;
  }
}

.en_maintenance-Modal-Input::placeholder {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
}

.en_maintenance-Input-Modal::placeholder {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
}

.en_maintenance-Input-Modal {
  font-family: "SF-Pro-Display-Regular" !important;
  font-size: 14px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.en_react-switch-labelTxt {
  font-size: 18px;
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
  margin: 0px 10px 0px 10px;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 52px;
  height: 24px;
  background: #68CEDD;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 3px 0 #E2E2E2;
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.en_noData {
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  color: #cccccc;
  margin-top: -30px;
}

#noData_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.en_smsVerificationTenantBlue_phone_ {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #36d1e0;
  background-color: #ffffff;
  text-align: center;
  margin-left: 0px;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
  outline: 0;
  background: transparent;
}

.en_smsVerificationTenantGrey__reset_ {
  width: 56px !important;
  height: 48px !important;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-left: 0px;
  text-align: center;
  border-radius: 4px;
  color: #212121;
  font-size: 16px;
}

.en_logoutFooter {
  display: none;
}

@media screen and (max-width: 500px) {
  .en_setting-menuItem-logout {
    display: none;
  }

  .en_logoutFooter {
    display: flex;
    margin-left: 16px;
  }

  .en_container_profiletop {
    padding-bottom: 24px;
  }

  .en_info {
    margin-bottom: 20px;
  }
}

.en_fixed_logout {
  font-size: 16px;
  font-family: "SF-Pro-Display-Bold";
  color: white;
  margin-left: 20px;
}

.toolbar-land-name {
  margin: 0px;
}

.en_changePasswordProfile_false {
  color: #b3b3b3;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
}

.en_CPUpper_successful_true {
  color: #5fb563;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
}

.en_uploadtext {
  color: #27aad8;
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
}

.en_orBtn {
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
}

.en_dnd_btn {
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
}

.en_tenantProfile_editBtn[disabled],
.en_tenantProfile_editBtn[disabled]:hover {
  background-color: white;
}

.en_term_condition {
  color: #666666;
  margin-top: 16px;
  font-size: 12px;
  font-family: "SF-Pro-Display-Medium";
}

.temrs_color {
  color: #27aad8;
}

.temrs_color:hover {
  color: #27aad8;
}

#terms_card {
  height: 460px;
  width: auto;
}

#en_empty_responsive {
  position: absolute;
  left: 40%;
  top: 40%;
}

#en_empty_responsive_ {
  position: absolute;
  left: 40%;
  top: 20%;
}

#en_empty_responsive_history {
  position: absolute;
  left: 40%;
  top: 20%;
  height: 70vh;
}

.en_tenant_loader_container {
  position: absolute;
  left: 45%;
  top: 30%;
}

@media screen and (max-width: 500px) {
  #empty_responsive_nav {
    margin-top: 590px;
  }

  #en_empty_responsive {
    position: absolute;
    left: 11%;
  }

  .en_tenant_loader_container {
    left: 30%;
  }

  .ant-select-selector {
    font-size: 50px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px !important;
  }

  .ant-select-selection-item {
    font-size: 14px !important;
  }

  #en_empty_responsive_ {
    position: absolute;
    left: 11%;
    top: 25%;
  }

  #en_empty_responsive_history {
    position: absolute;
    left: 5%;
    top: 140px;
    height: 100%;
  }

  .main_div {
    overflow-x: hidden;
  }
}

#en_time_style {
  font-family: "SF-Pro-Display-Medium";
}

.en_navbar_item_font {
  font-family: 'SF-Pro-Display-Semibold';
  margin: 0;
  line-height: 38px;
}

.en_modal_zoom {
  padding: 0px;
  overflow-y: auto;
}

.en_navItem {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.en_navItem>a {
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Avenir-Next-Medium";
}

.en_navItem>.bt-btn {
  text-align: unset;
}

.en_navItem>.en_BtnNavBarHomePage,
.en_navItem>.en_BtnNavBarHomePage:hover,
.en_navItem>.en_BtnNavBarHomePage:focus {
  color: #36d1e0;
  background-color: white;
  border-color: white;
  border-radius: 4px;
  height: 48px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  cursor: pointer;
  line-height: 2;
}

.en_navBarLanding .en_navItem>.en_RequestBookBtn,
.en_navBarLanding .en_navItem>.en_RequestBookBtn:hover,
.en_navBarLanding .en_navItem>.en_RequestBookBtn:focus {
  width: 228px;
  color: white;
  background-color: #36d1e0;
  border-color: #36d1e0;
  border-radius: 4px;
  height: 48px;
  font-family: "Avenir-Next-Demi-Bold";
  font-size: 16px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.en_navBarLanding .en_navItem>a {
  height: 48px;
  color: #b3b3b3;
  font-size: 18px;
  font-family: "Avenir-Next-Medium";
}

.active {
  color: #212121;
}

.en-float-left {
  position: relative;
  float: left;
  margin-right: 20px;
  margin-top: 7px;
}

@media screen and (max-width: 1200px) {
  .en_row-setting-navbar {
    border-bottom: solid 1px #cccccc;
    padding-top: 19px;
    padding-bottom: 8px;
  }

  .en_row-setting-navbar-shadow {
    border-bottom: none;
    box-shadow: 0 10px 6px -6px #cccc;
    z-index: 9999;
  }

  .en-float-left {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .en-float-left {
    margin-top: 0px;
  }
}

.en-float-right {
  position: relative;
  float: right;
  margin-right: 20px;
}

.main-navigation {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.en_Burger {
  display: none;
}

.en_Burger_Image {
  width: 30px;
  height: 30px;
}

.en_Burger_Image_close {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.menu-ul>li {
  top: 1px;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  height: 43px;
  padding-top: 10px;
}

.menu-ul {
  width: 100%;
  display: flex;
  white-space: nowrap;
  border: 0;
  border-bottom: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  list-style: none;
  background: #fff;
  outline: none;
  -webkit-transition: "background 0.3s, width 0.2s";
  transition: "background 0.3s, width 0.2s";
}

.en_menu-messenger {
  margin-left: auto !important;
}

@media screen and (max-width: 1200px) {
  .active {
    color: #36D1E0 !important;
  }

  .en_menu-messenger {
    margin-left: 0;
  }

  .menu-ul {
    margin-top: 20px;
    display: block;
    white-space: nowrap;
    border: 0;
    border-bottom: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    font-size: 14px;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.65);
    list-style: none;
    background: #fff;
    outline: none;
    -webkit-transition: "background 0.3s, width 0.2s";
    transition: "background 0.3s, width 0.2s";
  }

  .en-menu-float-right {
    position: relative;
    float: right;
    width: 100%;
  }

  .en_Burger {
    height: 48px;
    margin-left: 40px;
    color: #b3b3b3;
    font-size: 18px;
    font-family: "Avenir-Next-Medium";
    display: block;
    position: relative;
    float: right;
  }

  .en_navItem {
    display: block;
    height: 0px;
    width: 100%;
    position: relative;
    float: right;
    transition: height 0.5s;
  }

  .en_navItem.show {
    display: block;
    height: 300px;
    transition: height 0.5s;
  }

  .en_navItem>a {
    display: block;
  }

  .en_navBarLanding .en_navItem>.en_RequestBookBtn,
  .en_navBarLanding .en_navItem>.en_RequestBookBtn:hover,
  .en_navBarLanding .en_navItem>.en_RequestBookBtn:focus {
    width: auto;
  }
}

.en_terms_container {
  margin-top: 100px;
  justify-content: center;
  font-size: 16px;
  direction: rtl;
  text-align: justify;
}

.en_terms_Row {
  margin-top: 10px;
  justify-content: center;
  font-size: 16px;
  font-family: "SF-Pro-Display-Regular";
  direction: ltr;
  text-align: justify;
  color: #000;
}

.en_text_Bold {
  font-family: "SF-Pro-Display-Bold";
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}

.en_text_Bold_word {
  font-family: "SF-Pro-Display-Bold";
  font-size: 16px;
}

.en_terms_lang_style {
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: "tajwal-medium";
}

.en_terms_languge {
  margin-bottom: 20px;
  direction: rtl;
}

.en_modalFailedMsg {
  margin-left: 40px;
}

.inputPsw_Suffix_container {
  display: flex;
  align-items: center;
}

.en_UIKIT_password_input_suffixe {
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
  height: 24px;
  border: none;
  width: 24px;
}

.en_editEmailAddressPlaceholder::placeholder {
  font-family: 'SF-Pro-Display-Regular';
}

.en_message_logo_not {
  font-family: "SF-Pro-Display-Bold";
  font-size: 10px;
  position: absolute;
  right: 0px;
  left: 18px;
  bottom: 18px;
  background-color: #cb3d3d;
  border: 1px solid #cb3d3d;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en_tableScroll {
  overflow: auto;
}

.en_errorMsg {
  color: #c62828;
  font-size: 14px;
  margin-top: 10px;
  font-family: "SF-Pro-Display-Medium";
}

.payment_cancel_container {
  margin-top: 10px;
  text-align: center;
}

.payment_fullName_col {
  margin-bottom: 3px;
}

.en_property_pending_notif {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F1B55F;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  background-color: #FCF0DF;
  text-align: center;
}

.en_property_pending_notif span span {
  font-family: "SF-Pro-Display-Bold";
}

.en_property_pending_notif img,
.en_property_success_notif img {
  margin-right: 8px;
}

.en_property_success_notif {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5FB563;
  font-size: 16px;
  font-family: "SF-Pro-Display-Medium";
  background-color: #DFF0E0;
  text-align: center;
}

.en_property_success_notif .close_property_notif {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

@media (max-width: 768px) {

  .en_property_pending_notif,
  .en_property_success_notif {
    padding: 10px;
  }

  .en_property_success_notif .close_property_notif {
    right: 0px;
    top: 25%;
  }
}

@media only screen and (max-width: 1200px) {
  .en_Burger_Image {
    width: 23px;
    height: 17px;
    margin-top: 15px;
  }

  .en-float-left .tenantive_logo_image {
    width: 114px;
    height: 32px;
  }

  .en_message_logo_not {
    left: 80px;
  }
}

.en_edit_phone_number {
  margin-top: 5px;
}

@media (max-width: 567px) {
  .en_Burger_Image {
    width: 23px;
    height: 17px;
    margin-top: 10px;
  }

  .en-float-left img {
    width: 114px;
    height: 32px;
  }

  .en-float-left .beta_logo_image {
    width: 56px;
    height: 12px;
  }

  .en_row-setting-navbar {
    height: 73px;
    padding-top: 15px;
  }

  .en_edit_phone_number {
    display: flex;
    flex-direction: column;
    order: 2 !important;
    margin-top: 0px;
  }

  .en_phoneVerification-title {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .en_message_logo_not {
    left: 80px;
  }

  .en_dashboard_spinContainerHistory {
    height: 40vh;
  }

  .en_infonameResponsive {
    display: flex;
    font-family: "SF-Pro-Display-Medium";
    color: #212121;
    font-size: 18px;
  }

  .en_infoname {
    display: none;
  }

  .en_infoEmailResponsive {
    display: flex;
    color: #808080;
    font-size: 16px;
    font-family: "SF-Pro-Display-Regular";
  }

  .en_infoEmail {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .en_payment-btn-container {
    margin-left: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .en_payment-btn-container {
    margin-left: 15px;
  }

  .en_containerDashboard {
    width: 100%;
  }

  .en_table {
    margin-left: 15px;
    margin-right: 15px;
  }

  .en_PaymentContainer {
    margin-left: 9%;
    width: 95%;
  }

  .en_transfers-table {
    width: 100% !important;
  }

  .en_rentalsStatus-container {
    margin-right: 9%;
  }

  .en_maintenance-btn-container {
    padding-left: 9%;
  }

  .en_maintenance-container-block {
    margin-left: 9%;
    padding-right: 9%;
  }
}

@media (min-width: 1200px) {
  .en_containerDashboard {
    max-width: 80%;
  }
}

.en_resolved_Container .ant-carousel .slick-dots li.slick-active {
  padding-right: 7px;
  padding-left: 3px;
}

.en_resolved_Container .ant-carousel .slick-dots {
  height: 10px;
}

.en_text_blue {
  font-family: "SF-Pro-Display-Bold";
  font-size: 18px;
  margin-bottom: 10px;
  color: #27aad8;
}

.en_text_black {
  font-family: "SF-Pro-Display-Bold";
  font-size: 18px;
  margin-bottom: 10px;
  color: #212121;
}

.en_contract_info_lease {
  display: flex;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  color: #808080;
}

.en_monthly_rentAmount {
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
}

.en_black_color {
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
}

.en_image_space {
  margin-right: 8px;
}

.en_alert_toast_message {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
  text-align: center;
  margin-top: 14px;
  color: #CB3D3D;
}

.en_claims_container {
  background-color: white;
  min-height: 340px;
  border-radius: 10px;
  margin: 0 27px 0 40px;
  padding: 48px 41px 50px;
}

.en_claims_title {
  font-size: 24px;
  color: #212121;
  font-family: "SF-Pro-Display-Bold";
}

.en_claims_select_label {
  color: #666666;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  margin-bottom: 8px;
}

.en_claims_select.ant-select {
  width: 280px;
  height: 38px;
}

.en_claims_property.ant-select,
.en_claims_property.ant-select .ant-select-selector {
  width: 200px;
  height: 30px !important;
  border-radius: 4px;
}

.en_claims_select .ant-select-selection-item,
.en_claims_property .ant-select-selection-item {
  font-family: "SF-Pro-Display-Regular" !important;
  font-size: 14px !important;
  color: #212121;
}

.en_claim_btn,
.en_claim_btn:hover,
.en_claim_btn:focus {
  width: 280px;
  height: 48px;
  color: white;
  font-size: 16px;
  font-family: "SF-Pro-Display-Semibold";
  background: #27AAD8;
  border: 1px solid #27AAD8;
  border-radius: 4px;
  margin-top: 73px;
}

.en_claim_btn.ant-btn[disabled] {
  background: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  opacity: 0.2;
}

.en_claims_logo {
  position: absolute;
  top: 43px;
  right: 0px;
}

.en_customer_info_title {
  font-size: 18px;
  font-family: "SF-Pro-Display-Medium";
  color: #212121;
  border-bottom: 1px solid #CCCCCC;
}

.en_claim_save_mail {
  background: transparent;
  border: none;
  color: #27aad8;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  padding-right: 0;
}

.en_claim_modal_label {
  font-size: 14px;
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  margin-top: 9px;
}

.en_claim_modal_label.paddin-rl {
  padding-right: 15px;
}

.en_claim_modal_value {
  font-size: 14px;
  color: #212121;
  font-family: "SF-Pro-Display-Medium";
  margin-top: 9px;
}

.en_claim_modal_value input {
  height: 38px;
}

textarea.ant-input.en_claim_area_txt {
  margin-top: 10px;
  border: 1px solid #CCCCCC;
  height: 95px !important;
  font-family: "SF-Pro-Display-Medium";
  font-size: 14px;
  padding: 12px 9px 12px 14px;
  line-height: 17px;
  resize: none;
}

.en_drag_and_dropFiles {
  color: #B3B3B3;
  font-size: 14px;
  font-family: "SF-Pro-Display-Regular";
}

.en_claims_duration {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 0px;
  font-family: "SF-Pro-Display-Medium";
  color: #666666;
  margin-left: 40px;
  max-width: 852px;
}

.en_Bold_txt {
  font-family: "SF-Pro-Display-Bold";
}

.ant-upload.ant-upload-drag.en_claims_drag {
  width: 185px;
  height: 82px;
  margin-top: 6px;
}

.en_claims_txt_count {
  position: absolute;
  right: 6px;
  bottom: 3px;
  font-size: 8px;
  font-family: "SF-Pro-Display-Medium";
  color: #B3B3B3;
}

.ant-upload.ant-upload-drag.en_claims_drag .ant-upload-btn,
.ant-upload.ant-upload-drag.en_claims_drag .ant-upload {
  padding: 0;
}

.en_claims_check .ant-checkbox+span {
  color: #212121;
  border-radius: 4px;
  font-size: 12px;
  font-family: "SF-Pro-Display-Regular";
  padding-left: 16px;
  display: inline-block;
}

.en_claims_dragText {
  color: #212121;
  border-bottom: 1px solid #CCCCCC;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
  margin-bottom: 5px;
}

.en_claims_dragText img {
  padding-bottom: 6px;
}

.en_claims_drag_name {
  width: 100%;
  padding-left: 56px;
  height: 90px;
  overflow: auto;
}

.en_claimsDoc_dragText {
  color: #212121;
  text-decoration: underline;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
}

.en_sendClaim_button,
.en_sendClaim_button:hover,
.en_sendClaim_button:focus {
  width: 130px;
  height: 40px;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  color: white;
  background: #27aad8;
  border: 1px solid #27aad8;
  border-radius: 4px;
  margin-right: 20px;
}

.en_sendClaim_button.ant-btn[disabled] {
  background: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  opacity: 0.2;
}

.en_alertModalBtn,
.en_alertModalBtn:hover,
.en_alertModalBtn:focus {
  width: 200px;
  height: 48px;
  margin-top: 47px;
  background-color: #27AAD8;
  border: 1px solid #27AAD8;
  color: white;
  font-size: 16px;
  font-family: 'SF-Pro-Display-Medium';
  border-radius: 4px;
}

.en_confirmlAlertBtn,
.en_confirmlAlertBtn:hover,
.en_confirmlAlertBtn:focus {
  width: 134px;
  height: 32px;
  background-color: #27AAD8;
  border-radius: 4px;
  border: 1px solid #27AAD8;
  color: white;
  font-size: 12px;
  font-family: "SF-Pro-Display-Semibold";
  margin-top: 39px;
}

.en_cancelAlertBtn,
.en_cancelAlertBtn:hover,
.en_cancelAlertBtn:focus {
  width: 134px;
  height: 32px;
  background-color: #E6E6E6;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  color: #4D4D4D;
  font-size: 12px;
  font-family: "SF-Pro-Display-Semibold";
  margin-top: 39px;
}

.en_claims_alert_title {
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
  color: #212121;
  margin-top: 10px;
  margin-bottom: 34px;
  text-align: center;
}

.en_claims_alert_txt {
  font-size: 18px;
  text-align: center;
  color: #212121;
  font-family: "SF-Pro-Display-Regular";
  margin-bottom: 0;
}

.en_history_claims_container {
  margin: 48px 27px 0 40px;
}

.en_claims_history_title {
  font-size: 24px;
  font-family: "SF-Pro-Display-Bold";
  margin-bottom: 30px;
  color: #212121;
  margin-left: 41px;
}

@media (max-width: 1200px) {
  .en_claims_history_title {
    margin-left: 0px;
  }

  .en_history_claims_container {
    margin: 48px 0 0 40px;
  }
}

.en_claims_tab_view {
  width: 100%;
  border: 1px solid #E2E2E3;
  min-width: 900px;
  border-radius: 4px;
  overflow: auto;
}

.en_claims_tab_label.description {
  width: 400px;
}

.en_claims_tab_view .ant-table-container table>thead>tr:first-child th:first-child {
  font-size: 14px;
  font-family: "SF-Pro-Display-Bold";
  background: #E6E6E6;
  color: #808080;
  height: 47px;
  padding: 0 27px;
  text-align: left;
}

.en_claims_tab_view .ant-table-container table>thead>tr:first-child th {
  font-size: 14px;
  font-family: "SF-Pro-Display-Bold";
  background: #E6E6E6;
  color: #808080;
  height: 47px;
  padding: 0 27px;
  text-align: center;
}

.en_claims_tab_view tbody tr:nth-child(even)>td {
  background-color: #F7F7F7;
  border-bottom: 1px solid #E2E2E3;
  height: 92px;
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
  color: #212121;
  text-align: center;
}

.en_claims_tab_view tbody tr:nth-child(odd)>td {
  background-color: white;
  border-bottom: 1px solid #E2E2E3;
  height: 92px;
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
  color: #212121;
  text-align: center;
}

.en_claims_tab_view tbody tr td:first-child {
  text-align: left;
}

.en_claims_tab_view tbody tr:last-child>td {
  border-bottom: 0;
}

.ant-table-cell.en_claims_tab_label {
  padding: 15px 26px;
}

.en_claims_tab_view .ant-table-tbody {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.en_medium_font {
  font-family: "SF-Pro-Display-Medium";
}

.en_inProgress_txt {
  color: #808080;
  font-size: 10px;
  font-family: "SF-Pro-Display-Medium";
  margin-top: 6px;
}

.en_progress_claim {
  width: 102px;
  height: 40px;
  background: #FCF0DF;
  border-radius: 20px;
  color: #F1B55F;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  text-align: center;
  align-content: center;
  display: inline-block;
  padding: 9px 0;
}

.en_resolved_claim {
  width: 102px;
  height: 40px;
  background: #DFF0E0;
  border-radius: 20px;
  color: #5FB563;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  text-align: center;
  align-content: center;
  display: inline-block;
  padding: 9px 0;
}

.en_btn-action-rental,
.en_btn-action-rental:hover,
.en_btn-action-rental:focus {
  width: 130px;
  height: 40px;
  font-family: "SF-Pro-Display-Semibold";
  font-size: 14px;
  color: #1d456d;
  border-radius: 4px;
  background-color: #dde3e9;
  border: none;
}

.en_acount-rentl-img-select-suffixIcon-createInvoice {
  cursor: pointer;
  top: -7px;
  position: absolute;
  right: 0px;
}

.en_searchInput.ant-input-affix-wrapper {
  height: 40px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
}

#en_date-btn-transfers {
  width: 39px;
  height: 40px;
  background-color: #d9d9d9;
  border-color: #cccccc;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#en_date-btn-transfers:hover {
  background-color: #d9d9d9;
  border-color: #cccccc;
}

.en_transfers-date-picker ::placeholder {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
}

.en_transfers-date-picker {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding-right: 0;
}

.en_transfers-date-picker .ant-picker-suffix,
.en_reports-picker-container .ant-picker-suffix {
  border-left: 1px solid #d9d9d9;
  position: absolute;
  right: 0;
}

.en_transfers-date-picker .ant-picker-clear {
  right: 41px;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split,
.error_msg {
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Regular";
}

.en_addUnit_selectType-optionStyles {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
  color: #212121;
}

.en_addUnit_selectType-optionStyles .ant-select-item-option-content {
  font-family: "SF-Pro-Display-Regular";
  font-size: 14px;
  color: #212121;
}

.en_type_claim_tab {
  color: #212121;
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  text-transform: capitalize;
  margin-bottom: 11px;
}

.en_claims_select.filter.ant-select {
  width: 100%;
}

.en_transfers-date-picker.filter .ant-picker-suffix {
  border-left: 0;
  top: -0.5px;
}

.en_transfers-date-picker.filter .suffix_date {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.en_transfers-picker-container {
  display: flex;
  margin-bottom: 12px;
}

.en_paddingLR_16 {
  padding-left: 16px;
}

.en_clear_invoiceDatePicker {
  position: absolute;
  right: 52px;
  top: 14px;
  cursor: pointer;
}

@media (max-width: 769px) {
  .en-float-right {
    margin-right: 0;
  }

  .en-float-left {
    margin-left: 0;
  }

  .en_setting-menu-text a {
    padding-left: 0;
  }

  .en_claims_container {
    margin: 0 10px 0 0;
  }

  .en_claims_duration {
    margin-left: 0px;
  }

  .en_history_claims_container {
    margin: 48px 0 60px;
  }
}

@media (max-width: 567px) {
  .en_claims_container {
    margin: 0px;
    padding: 20px;
  }

  .en_claims_history_title {
    margin-left: 20px;
  }

  .en_claim_btn,
  .en_claim_btn:hover,
  .en_claim_btn:focus,
  .en_claims_select.ant-select,
  .en_claims_property.ant-select {
    width: 100%;
  }

  .en_claims_property.ant-select,
  .en_claims_property.ant-select .ant-select-selector {
    width: 100%;
  }

  .en_claims_tab_label.description {
    width: 242px;
  }
}

.en_languageSelectRadiosButtom span.ant-radio+* {
  font-family: "SF-Pro-Display-Medium";
  font-size: 16px;
}

.en_languageSelectRadiosButtom .ant-radio-wrapper {
  margin: 0 30px;
}

@media (max-width: 361px) {
  .en_claims_title {
    font-size: 20px;
  }
}

@media (max-width: 571px) {
  .en_language_container {
    margin-left: 0;
    margin-right: 0;
  }
}

.en_PaymentSuccess-Block {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.en_PaymentSuccess-img {
  width: 114px;
  height: 114px;
}

.en_PaymentSuccess-successTxt {
  font-size: 32px;
  font-family: "SF-Pro-Display-Bold";
  text-align: center;
  margin-bottom: 20px;
}

.en_PaymentSuccess-enjoyTxt {
  font-size: 24px;
  color: #808080;
  font-family: "SF-Pro-Display-Medium";
  text-align: center;
}

.en_PaymentSuccess-ButtonDashboard {
  width: 255px;
  height: 48px;
  background-color: #27aad8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  margin-top: 48px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
}

.en_record_payment_view {
  color: #212121;
  text-decoration: underline;
  font-size: 14px;
  font-family: "SF-Pro-Display-Semibold";
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
}

.en_basicMaintenance_title {
  font-size: 24px;
  color: #818181;
  margin-bottom: 54px;
  font-family: "SF-Pro-Display-Regular";
  text-align: center;
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.en_beta_logo_navbar_container {
  display: flex;
  justify-content: flex-start;
  height: 16px;
  width: 143px;
  margin-top: 5px;
}

.en_discount_label {
  font-size: 14px;
  font-family: "SF-Pro-Display-Medium";
  color: #B3B3B3;
}

.font-14 {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .en_beta_logo_navbar_container {
    margin-top: 4px;
  }

  .en-float-right {
    margin-right: 0;
  }

  .en_PaymentContainer {
    margin-left: 15px;
    width: auto;
    margin-right: 15px;
  }

  .en_table {
    margin-left: 15px;
  }

  .en_pay_in_advance_col {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  }

  .en_PayAdvance {
    margin-bottom: 16px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .en_discussion_logo_container {
    margin-left: 0px;
  }

  .en_conversation-fleche-bubble {
    left: 90px;
  }

  .en_messenger {
    height: calc(100vh - 80px);
    grid-template-columns: 315px auto;
  }

  .en_compose {
    width: calc(100% - 315px);
  }

  .en_maintenance-btn-container {
    padding-left: 15px;
  }

  .en_setting-profile-editProfile-avatar {
    display: flex;
  }

  .en_setting-profile-editProfile-upload {
    display: flex;
  }
}

.en_isOnline {
  position: absolute;
  left: 0;
}

.en_isOnlineResp {
  display: block;
  margin-left: 8px;
}

@media screen and (max-width: 576px) {
  .en_connection_tenant_fpsd_card {
    width: 360px;
  }

  .en_connection_tenant_verif_card {
    width: 360px;
    height: 560px !important;
  }

  .en_beta_logo_navbar_container {
    height: 12px;
    width: 112px;
  }

  .en_setting-profile-editProfile-avatar {
    display: inline-block;
  }

  .en_setting-profile-editProfile-upload {
    display: inline-block;
  }

  .en_pay_in_advance_col {
    display: flex;
    justify-content: flex-start;
    padding-right: 15px;
  }

  .en_payment-btn-container {
    margin-left: 15px;
  }
}

.en_CPUpper_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPUpper_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPLower_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPLower_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPNumber_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPNumber_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPMinCar_successful_true {
  font-size: 12px;
  margin-bottom: 0px;
}

.en_CPMinCar_successful_false {
  font-size: 12px;
  margin-bottom: 0px;
}

.en_CPSpecialChar_successful_true {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_CPSpecialChar_successful_false {
  font-size: 12px;
  margin-bottom: 8px;
}

.en_PayAdvance.ant-tooltip-disabled-compatible-wrapper button {
  width: 100%;
  height: 100%;
}

.en_claims_property.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  font-size: 14px;
}

.en_contractRenewText {
  font-size: 14px;
  font-family: 'SF-Pro-Display-Medium';
  color: #B3B3B3;
}

.en_contractRenewTextBold {
  font-size: 14px;
  font-family: 'SF-Pro-Display-Bold';
  color: #B3B3B3;
}

@media (max-width: 576px) {
  .updateInputContainer {
    width: 256px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.en_msgNo_readt {
  margin-top: 9px;
  margin-left: auto;
  display: block;
}

.en_settings_icon {
  padding-right: 0px !important;
}

.en_navbar-padding {
  padding-top: 5px;
}

.en_reciept-info {
  display: flex;
  justify-content: flex-end;
}

.en_lang_style {
  margin-left: 10px;
  cursor: pointer;
  font-family: "tajwal-medium";
  color: #ffffff;
}

.en_bt-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: fixed;
  right: 5%;
  top: 2%;
}
.en_navbar_logo_container {
  display: flex;
  flex-direction: column;
}
.en_phone-number-error {
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Medium";
  padding: 0px 40px;
}

.en_phone_number_error {
  color: #cb3d3d;
  font-family: "SF-Pro-Display-Medium";
  padding: 0px 40px;
}